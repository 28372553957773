import React, { useState } from "react";
import { Form } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import filtercircle from "./../assets/dashboard/filter_cross.svg";
import dotImage from './../assets/dashboard/three_dot.svg'
import deleteImage from './../assets/dashboard/delete.svg'
import { addPhaseService, updatePhaseService } from "../services";
import { addPhases } from "../features/phase/phaseSlice";
import { useDispatch } from "react-redux";
import useToast from "../utils/customHooks/useToast";
import { availableColors } from "../utils/constant";

export default function CustomizePipeline({ handleCustomizeClose, handleCustomizePiplelineSubmit, initialState ,phase, id, businessId}) {
  // const [items, setItems] = useState([
  //   { id: '1', name: 'Initial Contact', checked: initialState.intialContactChecked },
  //   { id: '2', name: 'Consultation', checked: initialState.consultationChecked },
  //   { id: '3', name: 'Proposal Sent', checked: initialState.proposalSentChecked },
  //   { id: '4', name: 'Contract Sent', checked: initialState.contractSentChecked },
  //   { id: '5', name: 'Contract Signed', checked: initialState.contractSignedChecked },
  //   { id: '6', name: 'Downpayment', checked: initialState.downPaymentChecked },
  //   { id: '7', name: 'Execution', checked: initialState.executionChcec },
  //   { id: '8', name: 'Done', checked: initialState.projectManagerChecked }
  // ]);

  // const [items, setItems] = useState(
  //   phase.map((item, index) => ({
  //       id: (index + 1).toString(),
  //       name: item.name,
  //       checked: initialState[`${item.name.replace(/\s+/g, '').toLowerCase()}Checked`] || false
  //   }))
  // );

  const [items, setItems] = useState(initialState)
  const [newItem, setNewItem] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const showToast = useToast()
  const dispatch = useDispatch()
  
  const getRandomColor = (existingColors) => {
    const unusedColors = availableColors.filter(color => !existingColors.includes(color));
    if (unusedColors.length > 0) {
      const randomIndex = Math.floor(Math.random() * unusedColors.length);
      return unusedColors[randomIndex];
    } else {
      // Fallback color if all available colors are used
      return '#000';
    }
  };

const handleAddNewItem = () => {
  if (newItem.trim()) {
    // Convert new item to lowercase for comparison
    const newItemLowerCase = newItem.trim().toLowerCase();
    
    // Check for uniqueness
    const isUnique = !phase.some(item => item.name.toLowerCase() === newItemLowerCase);

    if (isUnique) {
      let temp = [...phase];
      const newPosition = items.length + 1;

      const currentColors = phase.map((item => item.color));
      const newColor = getRandomColor(currentColors)
      // Create the new item
      const _newItem = {
        name: newItem.trim(),
        color: newColor,
        position: newPosition,
        isSelected: true
      };
      
      // Add the new item to the end of the phase array
      temp.push(_newItem);
      
      let data = {
        id: id,
        businessId: businessId,
        phase: temp
      };

      try {
        addPhaseService(data).then(res => {
          if (res.statusCode === 200) {
            if (res.data.status === 1) {
              dispatch(addPhases(res.data.responseData));
            }
          }
        });
      } catch (error) {
        // Handle error
      }

      setItems([...items, { id: `${items.length + 1}`, name: newItem, checked: true, color: newColor }]);
      setNewItem('');
      setIsAdding(false);
    } else {
      // Handle the case where the item is not unique (optional)
      showToast('"Item already exists in the phase."', "warning",800);
    }
  }
};


  // const handleDeleteItem = (id) => {
  //   setItems(items.filter(item => item.id !== id));
  //   try {
  //     updatePhaseService().then(res => {
        
  //     })
  //   } catch (error) {
      
  //   }
  // };

  const handleDeleteItem = (id) => {
    console.log(items,'itemssssssssssssssssssssss')
    // Convert id to number to match the position property in phase
    const positionToDelete = parseInt(id);

    let temp = [...phase]


    let updatedItems = temp.filter(item => item.id !== id);

    // Reassign ids to the remaining items to ensure they are sequential
    updatedItems = updatedItems.map((item, index) => ({
      ...item,
      id: (index + 1)
    }));

    setItems(updatedItems)
    
    // Filter out the item to delete from items
    temp = temp.filter((e, index) => e.position !== positionToDelete);

    let data = {
      id : id,
      businessId: businessId,
      phase : updatedItems
    }

    // Filter out the phase to delete based on the position
    // const updatedPhase = p.filter(phase => phase.position !== positionToDelete);

    // // Update state with the new items and phase arrays
    // setItems(updatedItems);
    // setPhaseState(updatedPhase);

    // Call the updatePhaseService API with the updated phase array
    try {
      updatePhaseService(data).then(res => {
        if(res.statusCode === 200){
          if(res.data.status === 1){
            dispatch(addPhases(res.data.responseData))
          }
        }
      });
    } catch (error) {
      console.error('Error updating phase:', error);
    }
  };

  const handleToggleChecked = (id) => {
    let newItem = items.map(item => item.id === id ? { ...item, checked: !item.checked } : item)
    let data = {
      id : id,
      businessId: businessId,
      phase : newItem
    }
    try {
      updatePhaseService(data).then(res => {
        if(res.statusCode === 200){
          if(res.data.status === 1){
            dispatch(addPhases(res.data.responseData))
          }
        }
      });
    } catch (error) {
      console.error('Error updating phase:', error);
    }
    setItems(items.map(item => item.id === id ? { ...item, checked: !item.checked } : item));
  };

  const handleDragEnd = (result) => {
    // if (!result.destination) return;
    // const newItems = [...items];
    // const [reorderedItem] = newItems.splice(result.source.index, 1);
    // newItems.splice(result.destination.index, 0, reorderedItem);
    // setItems(newItems);

    if (!result.destination) return;

    const newItems = [...items];
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    // Reassign ids and positions to the reordered items
    const updatedItems = newItems.map((item, index) => ({
      ...item,
      id: (index + 1).toString(),
      position: index + 1
    }));

    let data = {
      id : id,
      businessId: businessId,
      phase : updatedItems
    }
    console.log(updatedItems, 'updatedItems updatedItems')
    setItems(updatedItems);

    try {
      updatePhaseService(data).then(res => {
        if(res.statusCode === 200){
          if(res.data.status === 1){
            dispatch(addPhases(res.data.responseData))
          }
        }
      });
    } catch (error) {
      console.error('Error updating phase:', error);
    }
  };

  const handleSubmit = () => {
    console.log(items,'temssssssssssssssss')
    handleCustomizePiplelineSubmit(items);
  };

  return (
    <div className="project_filter_main customize_pipeline">
      <div className="project_filter_upper">
        <h4 className="filter_popup_heading">Customize</h4>
        <img src={filtercircle} onClick={handleCustomizeClose} />
      </div>
      <div className="project_filter_accor">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="items">
            {(provided) => (
              <ul className="customize_pop_ul" {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li className="customize_pop_li" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div className="customize_pipeline_sub">
                          <img src={dotImage} alt="three dot" />
                          <p className="font_14" style={{color: item.color}}>{item.name}</p>
                        </div>
                        <div className="customize_pipeline_sub">
                          <Form.Switch checked={item.checked} onChange={() => handleToggleChecked(item.id)} />
                          <img src={deleteImage} onClick={() => handleDeleteItem(item.id)}/>
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {isAdding && (
                  <li className="customize_pop_li">
                    <div className="customize_pipeline_sub customize_pipeline_add">
                      <input 
                        type="text" 
                        value={newItem} 
                        onChange={(e) => setNewItem(e.target.value)} 
                        placeholder="Enter item name"
                        maxLength={15}
                      />
                      <button onClick={handleAddNewItem}>Add</button>
                    </div>
                  </li>
                )}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="modal_btn_div project_filter_btn">
        <button className="_btn_transparent_ modal_div_button" onClick={() => setIsAdding(true)}>
          <span>Add</span>
        </button>
        <button className="_btn_normal_ modal_div_button" onClick={handleSubmit}>
          <span>Apply</span>
        </button>
      </div>
    </div>
  );
}