import React, { useCallback, useState } from "react";
import DeleteIcon from "./../../../assets/dashboard/delete.svg";
import CustomToggle from "../../../components/common/CustomToggle";
import Nav from "react-bootstrap/Nav";
import TableDateComponent from "../projectManagement/TableDateComponent";
import CommonButton from "../../../components/common/CommonButton";
import SchedulePreviewInvoice from "./schedulingPreview";
import useToast from "../../../utils/customHooks/useToast";

const DepositSchedule = ({ formValue, totalAmount,enableRecurring, onCancel, invoiceMainClose }) => {
  const preventScroll = useCallback((e) => {
    e.preventDefault();
  }, []);
  const [initialDepositPercent, setInitialDepositPercent] = useState("");
  const [initialDepositAmount, setInitialDepositAmount] = useState("");
  const [balanceAmount, setBalanceAmount] = useState(totalAmount);
  const [depositSchedule, setDepositSchedule] = useState([
    { amount: 0, details: '', calculatedAmount: 0, dueDate: '' },
    { amount: balanceAmount, details: 'Balance', calculatedAmount: balanceAmount, dueDate: '' },
  ]);
  const [milestoneSchedule, setMilestoneSchedule] = useState([
    { amount: 0, details: '', percentage: 0, dueDate: '' },
  ]);
  const [milestones, setMilestones] = useState(false);
  const [remainingBalance, setRemainingBalance] = useState(totalAmount);
  const [isPercentageView, setIsPercentageView] = useState(true); 
  const [isMilestonesPercentageView, setIsMilestonesPercentageView] = useState(true); 
  const [openPreview, setOpenPreview] = useState(false);
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openMilestone, setOpenMilestone] = useState(false);
  const showToast = useToast();

  const handleInitialDepositChange = (value) => {
    if (value) {
      setOpenDeposit(true);
      clearMilestoneFields(); // Clear milestone fields if deposit section is enabled
    } else {
      setOpenDeposit(false);
      clearInitialDepositFields();
    }
  };


  const handleInputInitialDepositChange = (value) => {
    const percent = parseFloat(value) || 0;
    if (percent < 0 || percent > 100) {
      return; // Or show an error message
    }
    const amount = (totalAmount * percent) / 100;
    setInitialDepositPercent(percent);
    // setInitialDepositAmount(amount);
    setBalanceAmount(totalAmount - amount);
    updateDepositSchedule(amount, totalAmount - amount);
  };

  const handleInitialDepositAmountChange = (value) => {
    const amount = parseFloat(value) || 0;
    if (amount < 0 || amount > totalAmount) {
      return; // Or show an error message
    }
    const percent = (amount / totalAmount) * 100;
    setInitialDepositAmount(amount);
    // setInitialDepositPercent(percent);
    setBalanceAmount(totalAmount - amount);
    updateDepositSchedule(amount, totalAmount - amount);
  };

  const handleMilestonesToggle = (value) => {
    if (value) {
      setOpenMilestone(true);
      clearInitialDepositFields(); // Clear deposit fields if milestone section is enabled
    } else {
      setOpenMilestone(false);
      clearMilestoneFields();
    }
  };

  const updateDepositSchedule = (initialDepositAmount, remainingAmount) => {
    setDepositSchedule([
      { amount: initialDepositAmount, details: '', calculatedAmount: initialDepositAmount, dueDate: depositSchedule[0]?.dueDate || '' },
      { amount: remainingAmount, details: 'Balance', calculatedAmount: remainingAmount, dueDate: depositSchedule[1]?.dueDate || '' },
    ]);
  };

  const clearInitialDepositFields = () => {
    setInitialDepositPercent("");
    setInitialDepositAmount("");
    setBalanceAmount(totalAmount);
    updateDepositSchedule(0, totalAmount);
  };

  const clearMilestoneFields = () => {
    setMilestoneSchedule([]);
    setRemainingBalance(balanceAmount);
  };

  const handleMilestoneAmountChange = (index, value) => {
    const amount = parseFloat(value) || 0;
    if (amount < 0 || amount > remainingBalance) {
      return; // Or show an error message
    }
    const milestonePercent = (amount / balanceAmount) * 100;
    updateMilestoneSchedule(index, amount, milestonePercent);
  };

  const handleMilestonePercentageChange = (index, value) => {
    const percent = parseFloat(value) || 0;
    if (percent < 0 || percent > 100) {
      return; // Or show an error message
    }
    const milestoneAmount = (balanceAmount * percent) / 100;
    updateMilestoneSchedule(index, milestoneAmount, percent);
  };

  const updateMilestoneSchedule = (index, milestoneAmount, milestonePercent) => {
    setMilestoneSchedule(
      milestoneSchedule.map((item, i) =>
        i === index ? { ...item, amount: milestoneAmount, percentage: milestonePercent, dueDate: item.dueDate || '' } : item
      )
    );
  };

  const handleMilestoneDetailsChange = (index, value) => {
    setMilestoneSchedule(
      milestoneSchedule.map((item, i) =>
        i === index ? { ...item, details: value } : item
      )
    );
  };

  const deleteMilestone = (index) => {
    const updatedMilestones = milestoneSchedule.filter((_, i) => i !== index);
    setMilestoneSchedule(updatedMilestones);
  };

  const handleDetailsChange = (index, value) => {
    setDepositSchedule(
      depositSchedule.map((item, i) =>
        i === index ? { ...item, details: value } : item
      )
    );
  };

  // const handleDueDateChange = (index, value) => {
  //   setDepositSchedule(
  //     depositSchedule.map((item, i) =>
  //       i === index ? { ...item, dueDate: value } : item
  //     )
  //   );
  // };

  const isDateGreaterThan = (date1, date2) => {
    return new Date(date1) <= new Date(date2);
  };
  

  const handleDueDateChange = (index, value) => {
    const newDate = new Date(value);
    
    if (index > 0) {
      const previousDueDate = depositSchedule[index - 1]?.dueDate;
      if (previousDueDate && isDateGreaterThan(newDate, previousDueDate)) {
        showToast("Due Date cannot be less than the previous due date.", "error");
        return;
      }
    }

    setDepositSchedule(
      depositSchedule.map((item, i) =>
        i === index ? { ...item, dueDate: newDate } : item
      )
    );
  };

  const handleMilestoneDueDateChange = (index, value) => {
    const newDate = new Date(value);
    
    if (index > 0) {
      const previousDueDate = milestoneSchedule[index - 1]?.dueDate;
      if (previousDueDate && isDateGreaterThan(newDate, previousDueDate)) {
        showToast("Due Date cannot be less than the previous due date.", "error");
        return;
      }
    }
    setMilestoneSchedule(milestoneSchedule.map((item, i) =>
      i === index ? { ...item, dueDate: newDate } : item
    ));
  };
  

  

  // const handleMilestoneDueDateChange = (index, value) => {
  //   const newDate = new Date(value);
  //   setMilestoneSchedule(milestoneSchedule.map((item, i) =>
  //     i === index ? { ...item, dueDate: newDate } : item
  //   ));
  // };

  const addMilestone = () => {
    const totalPercent = milestoneSchedule.reduce((acc, milestone) => acc + milestone.percentage, 0);
    if (totalPercent >= 100) {
      return;
    }
    const restPercent = 100 - totalPercent;
    const resAmount = (balanceAmount * restPercent) / 100;
    setMilestoneSchedule([
      ...milestoneSchedule,
      { amount: resAmount, details: '', percentage: restPercent, dueDate: '' },
    ]);
  };

  const toggleDepositView = (view) => {
    if (view === "percentage") {
      setInitialDepositAmount("");
      setDepositSchedule([
        { amount: 0, details: depositSchedule[0].details, calculatedAmount: 0, dueDate: depositSchedule[0]?.dueDate || '' },
        { amount: 0, details: depositSchedule[1].details, calculatedAmount: 0, dueDate: depositSchedule[1]?.dueDate || '' },
      ]);
    } else {
      setInitialDepositPercent(""); 
      setDepositSchedule([
        { amount:  0, details: depositSchedule[0].details, calculatedAmount:  0, dueDate: depositSchedule[0]?.dueDate || '' },
        { amount: 0, details: depositSchedule[1].details, calculatedAmount: 0, dueDate: depositSchedule[1]?.dueDate || '' },
      ]);
    }
    setIsPercentageView(view === "percentage");
  };

  const toggleMilestoneView = (view) => {
    if (view === "percentage") {
      setMilestoneSchedule(milestoneSchedule.map((item) => ({
        ...item,
        amount: 0,
        percentage: 0
      })));
    } else {
      setMilestoneSchedule(milestoneSchedule.map((item) => ({
        ...item,
        percentage: 0,
        amount: 0,
      })));
    }
    setIsMilestonesPercentageView(view === "percentage");
  };

  // const Preview = () => {
  //   setOpenPreview(true);
  // };

  const previewData = {
    depositSchedule,
    milestoneSchedule,
    totalAmount,
    initialDepositPercent,
    balanceAmount,
    milestones
  };

  const handleCancel = () =>{
    setOpenPreview(false);
  };

  const validateFields = () => {
    // Validate Initial Deposit if the section is open
    if (openDeposit) {
      if (initialDepositPercent === "" || depositSchedule[0].dueDate === "") {
        // showToast("Initial deposit percentage and due date cannot be null.", "error");
        return false;
      }
    }
  
    // Validate Deposit Schedule if the section is open
    if (openDeposit && depositSchedule.some(schedule => schedule.dueDate === "")) {
      // showToast("Deposit schedule due date cannot be null.", "error");
      return false;
    }
  
    // Validate Milestones if the section is open
    if (openMilestone) {
      if (milestoneSchedule.some(milestone => milestone.dueDate === "" || milestone.details === "")) {
        // showToast("Milestone due date and details cannot be null.", "error");
        return false;
      }
    }
  
    return true;
  };
  

  const handlePreview = () => {
    if (validateFields()) {
      setOpenPreview(true);
    } else {
      showToast("Please fill all required fields before previewing.", "error");
    }
  };

  return (
    <div>
      {openPreview ? (
        <SchedulePreviewInvoice  openMilestone={openMilestone} openDeposit={openDeposit}  onCancel={handleCancel} enableRecurring={enableRecurring} invoiceMainClose={invoiceMainClose} formValue={formValue} previewData={previewData} />
      ) : (
      <div className="scheduling_div">
        <h3 className="scheduling_div_heading">Scheduling Options</h3>
        <div className="_label_mb_ scheduling_div_toogle">
          <CustomToggle
            label="Request initial deposit:"
            onChange={(e) => handleInitialDepositChange(e.target.checked)}
          />
        </div>
        <div className="_label_mb_ scheduling_div_toogle scheduling_div_toogle2">
          <CustomToggle
            label="Split balance payments into milestones:"
            onChange={(e) => handleMilestonesToggle(e.target.checked)}
          />
        </div>

        {openDeposit && (
          <div>
            <h3 className="scheduling_div_heading mb-5">Deposit Schedule</h3>
            <p className="font_14">
              Use deposit schedule to split payment into an initial deposit & balance
            </p>
            <div className="nav_tab_scheuling">
              <Nav variant="pills" className="flex" defaultActiveKey="first">
                <Nav.Item>
                  <Nav.Link eventKey="first" onClick={() => toggleDepositView("percentage")}>
                    Percentage ( % )
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" onClick={() => toggleDepositView("amount")}>
                    Amount ( ₹ )
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div
              style={{ maxHeight: "450px", overflowY: "auto", margin: "27px 0 43px" }}
            >
              <table
                className="project_table dealroom_table scheduling_table"
                style={{ marginTop: 0 }}
              >
                <thead>
                  <tr>
                    <th style={{width:"20%",textAlign:"start"}}>Amount {isPercentageView ? '%' : '₹'}</th>
                    <th style={{width:"40%",textAlign:"start"}}>Amount ( ₹ )</th>
                    <th style={{width:"20%",textAlign:"start"}}>Details</th>
                    <th style={{width:"20%",textAlign:"start"}}>Due Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {isPercentageView ? (
                        <input
                          type="number"
                          style={{ border: 'none' }}
                          value={initialDepositPercent || ""}
                          onFocus={(e) => e.target.addEventListener('wheel', preventScroll, { passive: false })}
                          onBlur={(e) => e.target.removeEventListener('wheel', preventScroll)}
                          onChange={(e) => handleInputInitialDepositChange(e.target.value)}
                          placeholder="Initial Deposit Percentage"
                          min="0"
                          max="100"
                        />
                      ) : (
                        <input
                          type="number"
                          style={{ border: 'none' }}
                          value={initialDepositAmount || ""}
                          onFocus={(e) => e.target.addEventListener('wheel', preventScroll, { passive: false })}
                          onBlur={(e) => e.target.removeEventListener('wheel', preventScroll)}
                          onChange={(e) => handleInitialDepositAmountChange(e.target.value)}
                          placeholder="Initial Deposit Amount"
                          min="0"
                          max={totalAmount}
                        />
                      )}
                    </td>
                    <td>{depositSchedule[0].amount.toFixed(2)}</td>
                    <td>
                      <input
                        type="text"
                        style={{ border: 'none' }}
                        value={depositSchedule[0].details}
                        placeholder="Add Details"
                        onChange={(e) => handleDetailsChange(0, e.target.value)}
                      />
                    </td>
                    <td>
                      <TableDateComponent
                        date={depositSchedule[0].dueDate}
                        onChange={(e) => handleDueDateChange(0, e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {isPercentageView ? (
                        <input
                          type="number"
                          onFocus={(e) => e.target.addEventListener('wheel', preventScroll, { passive: false })}
                          onBlur={(e) => e.target.removeEventListener('wheel', preventScroll)}
                          style={{ border: 'none' }}
                          value={100 - initialDepositPercent || ""}
                          readOnly
                          placeholder="Balance"
                        />
                      ) : (
                        <input
                          type="number"
                          style={{ border: 'none' }}
                          value={depositSchedule[1].amount || ""}
                          readOnly
                          placeholder="Balance"
                        />
                      )}
                    </td>
                    <td>{depositSchedule[1].calculatedAmount.toFixed(2)}</td>
                    <td>
                      <input
                        type="text"
                        style={{ border: 'none' }}
                        value={depositSchedule[1].details}
                        readOnly
                      />
                    </td>
                    <td>
                      <TableDateComponent
                        date={depositSchedule[1].dueDate}
                        onChange={(e) => handleDueDateChange(1, e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}

        {openMilestone && (
          <div>
            <h3 className="scheduling_div_heading mb-5">Balance Milestone Split</h3>
            <p className="font_14">
              Split the balance of Rs. {balanceAmount} into milestones
            </p>
            <div className="nav_tab_scheuling">
              <Nav variant="pills" className="flex" defaultActiveKey="first">
                <Nav.Item>
                  <Nav.Link eventKey="first" onClick={() => toggleMilestoneView("percentage")}>
                    Percentage ( % )
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" onClick={() => toggleMilestoneView("amount")}>
                    Amount ( ₹ )
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div
              style={{ maxHeight: "450px", overflowY: "auto", margin: "27px 0 43px" }}
            >
              <table
                className="project_table dealroom_table scheduling_table"
                style={{ marginTop: 0 }}
              >
                <thead>
                  <tr>
                    <th>Amount {isMilestonesPercentageView ? '%' : '₹'}</th>
                    <th>Amount ( ₹ )</th>
                    <th>Details</th>
                    <th>Due Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {milestoneSchedule.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {isMilestonesPercentageView ? (
                          <input
                            type="number"
                            style={{ border: 'none' }}
                            value={item.percentage || ""}
                            placeholder="Percent"
                            onFocus={(e) => e.target.addEventListener('wheel', preventScroll, { passive: false })}
                            onBlur={(e) => e.target.removeEventListener('wheel', preventScroll)}
                            onChange={(e) => handleMilestonePercentageChange(index, e.target.value)}
                            min="0"
                            max="100"
                          />
                        ) : (
                          <input
                            type="number"
                            style={{ border: 'none' }}
                            placeholder="Amount"
                            value={item.amount || ""}
                            onFocus={(e) => e.target.addEventListener('wheel', preventScroll, { passive: false })}
                            onBlur={(e) => e.target.removeEventListener('wheel', preventScroll)}
                            onChange={(e) => handleMilestoneAmountChange(index, e.target.value)}
                            min="0"
                            max={remainingBalance}
                          />
                        )}
                      </td>
                      <td>{item.amount.toFixed(2)}</td>
                      <td>
                        <input
                          type="text"
                          style={{ border: 'none' }}
                          value={item.details}
                          placeholder="Add Details"
                          onChange={(e) => handleMilestoneDetailsChange(index, e.target.value)}
                        />
                      </td>
                      <td>
                        <TableDateComponent
                          date={item.dueDate}
                          onChange={(e) => handleMilestoneDueDateChange(index, e.target.value)}
                        />
                      </td>
                      <td>
                        <img
                          src={DeleteIcon}
                          style={{ width: '22px', height: '24px' }}
                          alt="Delete"
                          onClick={() => deleteMilestone(index)}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={5}>
                    <p className="another_mile" onClick={addMilestone}>+ Add Another Milestone</p>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div className="contract_main_button">
          <CommonButton
            label="Cancel"
            fontSize="14px"
            fontWeight="700"
            className="dash_common_btn white_bg_btn"
            icon={false}
            onClick={onCancel}
          />
          <CommonButton
            label="Preview"
            fontSize="14px"
            fontWeight="700"
            className="dash_common_btn"
            icon=""
            onClick={handlePreview}
          />
        </div>
      </div>
      )}
    </div>
  );
};

export default DepositSchedule;
