// SignatureModal.js
import React, { useEffect, useState } from "react";
import crossIcon from "../assets/dashboard/filter_cross.svg";
import CommonButton from "../components/common/CommonButton";

const ActionModal = ({
  isOpen,
  title,
  onClose,
  onSave,
  dealRoomID,
  pdf,
  isCRView,
  buttons = "",
  isInvoice,
}) => {
  const [actionType, setActionType] = useState(null);

  console.log(dealRoomID, 'dealRoomIDdealRoomIDdealRoomID')

  useEffect(() => {
    if (actionType === 3 || actionType === 4 || actionType === 5) {
      if(isCRView){
        window.open(`/view-template?createRequestId=${dealRoomID}`, "_blank");
      }else{
        window.open(`/view-template?dealroomId=${dealRoomID}`, "_blank");
      }

    }
  }, [actionType]);

  const handleSave = () => {
    console.log("yes i saved");
    onSave(dealRoomID, actionType);
    setActionType(null);
    onClose();
  };

  if (!isOpen) return null;

  const handleActionType = (type) => {
    setActionType(type);
  };

  const dynamicTitleHandler = (type) => {
    if (buttons === "reviewExpense") {
      switch (type) {
        case 6:
          return "Are you sure you want to mark as paid the expense?";
        default:
          return `${title}`;
      }
    } else if (buttons === "ownerExpense") {
      switch (type) {
        case 1:
          return "Are you sure you want to cancel the expense?";
        case 2:
          return "Are you sure you want to delete the expense?";
        case 6:
          return "Are you sure you want to mark as paid the expense?";
        default:
          return `${title}`;
      }
    } else if (buttons === "userExpense") {
      switch (type) {
        case 1:
          return "Are you sure you want to cancel the expense?";
        case 2:
          return "Are you sure you want to delete the expense?";
        default:
          return `${title}`;
      }
    } else {
      switch (type) {
        case 1:
          return "Are you sure you want to cancel the file?";
        case 2:
          return "Are you sure you want to delete the file?";
        default:
          return `${title}`;
      }
    }
  };

  return (
    <div className="signature-modal">
      <div className="modal-content">
        <div className="action-modal-wrapper">
          {
            !isInvoice ?  
              (
                <div className="action-buttons">
                <p>{dynamicTitleHandler(actionType)}</p>
                {actionType !== 1 && actionType !== 2 && actionType !== 6 ? (
                  <>
                    {buttons === "reviewExpense" ? (
                      <CommonButton
                        label="Mark as Paid"
                        fontSize="14px"
                        fontWeight="700"
                        className="dash_common_btn white_bg_btn"
                        icon={""}
                        onClick={() => handleActionType(6)}
                      />
                    ) : (
                      <>
                        <CommonButton
                          label="Cancel"
                          fontSize="14px"
                          fontWeight="700"
                          className="dash_common_btn white_bg_btn"
                          icon={""}
                          onClick={() => handleActionType(1)}
                        />
                        <CommonButton
                          label="Delete"
                          fontSize="14px"
                          fontWeight="700"
                          className="dash_common_btn white_bg_btn"
                          icon={""}
                          onClick={() => handleActionType(2)}
                        />
                      </>
                    )}
                    {buttons === "ownerExpense" ? (
                      <CommonButton
                        label="Mark as Paid"
                        fontSize="14px"
                        fontWeight="700"
                        className="dash_common_btn white_bg_btn"
                        icon={""}
                        onClick={() => handleActionType(6)}
                      />
                    ) : buttons ? null : (
                      <>
                        <CommonButton
                          label="View"
                          fontSize="14px"
                          fontWeight="700"
                          className="dash_common_btn white_bg_btn"
                          icon={""}
                          onClick={() => handleActionType(3)}
                        />
                        <CommonButton
                          label="Download"
                          fontSize="14px"
                          fontWeight="700"
                          className="dash_common_btn white_bg_btn"
                          icon={""}
                          onClick={() => handleActionType(4)}
                        />
                        <CommonButton
                          label="Print"
                          fontSize="14px"
                          fontWeight="700"
                          className="dash_common_btn white_bg_btn"
                          icon={""}
                          onClick={() => handleActionType(5)}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <div className="action-approval-btn-wrappers">
                    <CommonButton
                      label="No"
                      fontSize="14px"
                      fontWeight="700"
                      className={`dash_common_btn`}
                      icon=""
                      onClick={() => setActionType(null)}
                    />
                    <CommonButton
                      label="Yes"
                      fontSize="14px"
                      fontWeight="700"
                      icon=""
                      className={`dash_common_btn`}
                      onClick={handleSave}
                    />
                  </div>
                )}
                <div className="action-cross-icon" onClick={onClose}>
                  <img src={crossIcon} alt="crossIcon" />
                </div>
              </div>
              )
            : (
              <div className="action-buttons">
              <p>{"Invoice"}</p>
              {actionType !== 1 && actionType !== 2 && actionType !== 6 ? (
                <>
                  {buttons === "reviewExpense" ? (
                   ''
                  ) : (
                    <>
                      <CommonButton
                        label="Cancel Invoice"
                        fontSize="14px"
                        fontWeight="700"
                        className="dash_common_btn white_bg_btn"
                        icon={""}
                        onClick={() => handleActionType(1)}
                      />
                      <CommonButton
                        label="Delete Invoice"
                        fontSize="14px"
                        fontWeight="700"
                        className="dash_common_btn white_bg_btn"
                        icon={""}
                        onClick={() => handleActionType(2)}
                      />

                      <CommonButton
                        label="Download Invoice"
                        fontSize="14px"
                        fontWeight="700"
                        className="dash_common_btn white_bg_btn"
                        icon={""}
                        // onClick={() => handleActionType(4)}
                        onClick={() => window.open(pdf, "_blank")}
                      />
                      <CommonButton
                        label="Print Invoice"
                        fontSize="14px"
                        fontWeight="700"
                        className="dash_common_btn white_bg_btn"
                        icon={""}
                        onClick={() => window.open(pdf, "_blank")}
                      />
                       <CommonButton
                        label="Mark as Paid"
                        fontSize="14px"
                        fontWeight="700"
                        className="dash_common_btn white_bg_btn"
                        icon={""}
                        onClick={() => handleActionType(6)}
                      />
                       <CommonButton
                        label="Offline Record"
                        fontSize="14px"
                        fontWeight="700"
                        className="dash_common_btn white_bg_btn"
                        icon={""}
                        onClick={() => handleActionType(3)}
                      />
                    </>
                  )}
                  {buttons === "ownerExpense" ? (
                    <CommonButton
                      label="Mark as Paid"
                      fontSize="14px"
                      fontWeight="700"
                      className="dash_common_btn white_bg_btn"
                      icon={""}
                      onClick={() => handleActionType(6)}
                    />
                  ) : buttons ? null : (
                    <>
                     
                      
                    </>
                  )}
                </>
              ) : (
                <div className="action-approval-btn-wrappers">
                  <CommonButton
                    label="No"
                    fontSize="14px"
                    fontWeight="700"
                    className={`dash_common_btn`}
                    icon=""
                    onClick={() => setActionType(null)}
                  />
                  <CommonButton
                    label="Yes"
                    fontSize="14px"
                    fontWeight="700"
                    icon=""
                    className={`dash_common_btn`}
                    onClick={handleSave}
                  />
                </div>
              )}
              <div className="action-cross-icon" onClick={onClose}>
                <img src={crossIcon} alt="crossIcon" />
              </div>
            </div>
            )
          }


         


        </div>
      </div>
    </div>
  );
};

export default ActionModal;
