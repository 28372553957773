import {  Navigate } from "react-router-dom";
const PublicRoutes = (props) => {
        console.log('I AM IN PUBLIC_ROUTE : ')
        const { Component } = props
        let auth = localStorage.getItem('isLogin')
        return (
        !auth ? <Component/>  : <Navigate to="/dashboard" />
        )
}
export default PublicRoutes

