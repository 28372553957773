// // src/HtmlToPdf.js
// import React, { useEffect, useState } from "react";
// import { PDFViewer, Document, Page } from "@react-pdf/renderer";
// import { Html } from "react-pdf-html";
// import ReactDOMServer from "react-dom/server";
// import { convertHtmltoPdfService } from "../services";
// const htmlcontent = ` 
// <div id="tinymce" class="tinymce mce-content-body" font-size: 22px;" contenteditable="true" spellcheck="false">
//     <h1 style="margin-top: 0.42cm;margin-bottom: 0.42cm;font-size:27px;line-height: 100%;text-align: center;">Catering Services Contract Agreement</h1>
// </div>

// <div id="tinymce" class="tinymce mce-content-body" contenteditable="true" spellcheck="false">
//     <p style="line-height: 115%;margin-bottom: 0cm;text-align: left;">Created for</p>
//     <p style="line-height: 115%;margin-bottom: 0cm;text-align: left;">[Client.FirstName] [Client.LastName]</p>
//     <p style="line-height: 115%;margin-bottom: 0cm;text-align: left;">[Client.Company]</p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">Created by</p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">[Sender.FirstName][Sender.LastName]</p>
// </div>      

// <div id="tinymce" class="tinymce mce-content-body" contenteditable="true" spellcheck="false">
//     <h2 style="margin-top: 0.64cm;margin-bottom: 0.14cm;line-height: 100%;text-align: left;font-size:21px;font-weight: normal;">[Sender.Company]</h2>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">This Catering Services Agreement (&quot;Agreement&quot;) is made and entered into as of [<strong>Effective Date</strong>] by and between:</p>
// </div>

// <div id="tinymce" class="tinymce mce-content-body" contenteditable="true" spellcheck="false">
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>1. Caterer</strong></p>
//     <ul>
//         <li>
//             <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>[Your Catering Company Name]</strong>, a company incorporated under the Companies Act, 2013, with its registered office at [<strong>Your Company Address</strong>] (&quot;Caterer&quot;).</p>
//         </li>
//     </ul>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>2. Client</strong></p>
//     <ul>
//         <li>
//             <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>[Client Name]</strong>, residing at [<strong>Client Address</strong>] (&quot;Client&quot;).</p>
//         </li>
//     </ul>
// </div>

// <div id="tinymce" class="tinymce mce-content-body" contenteditable="true" spellcheck="false">
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>WHEREAS,</strong> the Caterer is a business engaged in providing catering services for events, and the Client desires to engage the Caterer to provide catering services for a specific event.</p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>NOW, THEREFORE,</strong> in consideration of the mutual covenants contained herein, the parties agree as follows:</p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>1. Event Details</strong></p>
//     <ul>
//         <li>
//             <p style="margin-top: 0.42cm;margin-bottom: 0cm;line-height: 100%;text-align: left;"><strong>Event Name:</strong> [<strong>Name of the event</strong>]</p>
//         </li>
//         <li>
//             <p style="margin-bottom: 0cm;line-height: 100%;text-align: left;"><strong>Event Type: [Wedding, Birthday, Party, Corporate, Private, Theme]</strong></p>
//         </li>
//         <li>
//             <p style="margin-bottom: 0cm;line-height: 100%;text-align: left;"><strong>Event Date:</strong> [<strong>Date of the event</strong>]</p>
//         </li>
//         <li>
//             <p style="margin-bottom: 0cm;line-height: 100%;text-align: left;"><strong>Event Time:</strong> [<strong>Start and end time of the event</strong>]</p>
//         </li>
//         <li>
//             <p style="margin-bottom: 0cm;line-height: 100%;text-align: left;"><strong>Event Location:</strong> [<strong>Location of the event</strong>]</p>
//         </li>
//         <li>
//             <p style="margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>Estimated Number of Guests:</strong> [<strong>Number of people attending the event</strong>]</p>
//         </li>
//     </ul>
// </div>

// <div id="tinymce" class="tinymce mce-content-body" contenteditable="true" spellcheck="false">
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>2. Services Provided</strong></p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">The Caterer agrees to provide the following catering services for the Event:</p>
//     <ul>
//         <li>
//             <p style="margin-top: 0.42cm;margin-bottom: 0cm;line-height: 100%;text-align: left;"><strong>Food:</strong> [<strong>Detailed list of menu items, including quantities</strong>]</p>
//         </li>
//         <li>
//             <p style="margin-bottom: 0cm;line-height: 100%;text-align: left;"><strong>Beverages:</strong> [<strong>List of beverages to be served, including alcoholic and non-alcoholic options</strong>] (if applicable)</p>
//         </li>
//         <li>
//             <p style="margin-bottom: 0cm;line-height: 100%;text-align: left;"><strong>Staff:</strong> [<strong>Number and type of staff to be provided, e.g., servers, bartenders, chefs</strong>] (if applicable)</p>
//         </li>
//         <li>
//             <p style="margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>Equipment:</strong> [<strong>List of any equipment to be provided by the Caterer, e.g., tables, chairs, linens</strong>] (if applicable)</p>
//         </li>
//     </ul>
// </div>

// <div id="tinymce" class="tinymce mce-content-body" contenteditable="true" spellcheck="false">
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>3. Client Responsibilities</strong></p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">The Client agrees to:</p>
//     <ul>
//         <li>
//             <p style="margin-top: 0.42cm;margin-bottom: 0cm;line-height: 100%;text-align: left;">Provide the Caterer with a final guest count at least [<strong>Number</strong>] days before the Event Date.</p>
//         </li>
//         <li>
//             <p style="margin-bottom: 0cm;line-height: 100%;text-align: left;">Secure all necessary permits and licenses required for the Event.</p>
//         </li>
//         <li>
//             <p style="margin-bottom: 0cm;line-height: 100%;text-align: left;">Ensure that the Event Location is ready for setup and that the Caterer has access to the Event Location at least [<strong>Number</strong>] hours before the Event start time.</p>
//         </li>
//         <li>
//             <p style="margin-bottom: 0.42cm;line-height: 100%;text-align: left;">Make timely payments as specified in this Agreement.</p>
//         </li>
//     </ul>
// </div>

// <div id="tinymce" class="tinymce mce-content-body" contenteditable="true" spellcheck="false">
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>4. Payment Terms</strong></p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">The Client agrees to pay the Caterer the total amount of [<strong>Total Fee</strong>] for the Services provided under this Agreement. The payment schedule is as follows:</p>
//     <ul>
//         <li>
//             <p style="margin-top: 0.42cm;margin-bottom: 0cm;line-height: 100%;text-align: left;">A deposit of [<strong>Deposit Amount</strong>] due upon signing this Agreement.</p>
//         </li>
//         <li>
//             <p style="margin-bottom: 0cm;line-height: 100%;text-align: left;">The remaining balance of [<strong>Remaining Balance</strong>] due [<strong>Number</strong>] days before the Event Date.</p>
//         </li>
//         <li>
//             <p style="margin-bottom: 0.42cm;line-height: 100%;text-align: left;">Any additional charges incurred during the Event will be invoiced to the Client and are payable within [<strong>Number</strong>] days of receipt.</p>
//         </li>
//     </ul>
// </div>

// <div id="tinymce" class="tinymce mce-content-body" contenteditable="true" spellcheck="false">
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>5. Cancellation Policy</strong></p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">The Client may cancel this Agreement by providing written notice to the Caterer at least [<strong>Number</strong>] days before the Event Date. If the Client cancels this Agreement within [<strong>Number</strong>] days of the Event Date, the Client will forfeit the deposit.</p>
// </div>

// <div id="tinymce" class="tinymce mce-content-body" contenteditable="true" spellcheck="false">
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>6. Liability</strong></p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">The Caterer will take reasonable measures to ensure the safety and quality of the food and beverages served. However, the Caterer's liability is limited to the total amount paid by the Client under this Agreement. The Caterer is not responsible for any damages or injuries resulting from the actions or negligence of the Client or Event guests.</p>
// </div>

// <div id="tinymce" class="tinymce mce-content-body" contenteditable="true" spellcheck="false">
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>7. Governing Law</strong></p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">This Agreement shall be governed by and construed in accordance with the laws of the state of [<strong>State/Country</strong>].</p>
// </div>

// <div id="tinymce" class="tinymce mce-content-body" contenteditable="true" spellcheck="false">
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>8. Entire Agreement</strong></p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">This Agreement constitutes the entire agreement between the parties and supersedes all prior agreements, representations, and understandings. No amendment or modification of this Agreement shall be valid unless in writing and signed by both parties.</p>
// </div>

// <div id="tinymce" class="tinymce mce-content-body" contenteditable="true" spellcheck="false">
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>IN WITNESS WHEREOF,</strong> the parties hereto have executed this Catering Services Contract Agreement as of the Effective Date.</p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>Client:</strong></p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">[<strong>Client Name</strong>]</p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">Signature: _______________________________</p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">Date: [<strong>Date</strong>]</p>
// </div>

// <div id="tinymce" class="tinymce mce-content-body" contenteditable="true" spellcheck="false">
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;"><strong>Caterer:</strong></p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">[<strong>Your Catering Company Name</strong>]</p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">Signature: _______________________________</p>
//     <p style="margin-top: 0.42cm;margin-bottom: 0.42cm;line-height: 100%;text-align: left;">Date: [<strong>Date</strong>]</p>
// </div>
// `;

// const HtmlPage = () => {
//   const [showPdf, setShowPdf] = useState(false);
//   const [showHtml, setShowHtml] = useState(true);
//   const [fileUrl, setFileUrl] = useState("");
//   useEffect(() => {
//     console.log(showPdf,'showPdf showPdf')
//     if (fileUrl !== "") {
//         console.log('inside file url')
//         setShowPdf(true);
//     }
//   }, [fileUrl]);

//   const handlePreviewClick = () => {
//     // setShowPdf(true);
//     // setShowHtml(false);
//     const token = localStorage.getItem('token')
//     const formData = new FormData();
//     formData.append('text', htmlcontent);
//     try { 
//         convertHtmltoPdfService(formData, token).then(res => {
//                 console.log(res,'res inside html to pdf')
//                 if(res.status === 200){
//                     if(res.data.status === 1){
//                         const pdfURL = res?.data?.responseData?.url
//                         // setShowPdf(true);
//                         setFileUrl(pdfURL)
//                     }
//                 }
//         })
        
//     } catch (error) {
        
//     }
//   };

//   const pdfContent = ReactDOMServer.renderToStaticMarkup(
//     <Document>
//       <Page size="A4">
//         <Html>{htmlcontent}</Html>
//       </Page>
//     </Document>
//   );

//   return (
//     <div>
//       {!showPdf && <button onClick={handlePreviewClick}>Preview</button>}

//       {!showPdf ? (
//         <div dangerouslySetInnerHTML={{ __html: htmlcontent }} />
//       ) : (
//         // <PDFViewer width="100%" height="600">
//         //     {pdfContent}
//         // </PDFViewer>
//         <embed
//           src={fileUrl}
//           type="application/pdf"
//           width="100%"
//           height="700px"
//         />
//       )}
//     </div>
//   );
// };

// export default HtmlPage;

import React from 'react'

const HtmlPage = () => {
  return (
    <div>HtmlPage</div>
  )
}

export default HtmlPage

