import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import docIcon from "../../../assets/logos/doc-svgrepo-com.svg";
import pdfIcon from "../../../assets/logos/pdf-svgrepo-com.svg";
import rupeesIcon from "../../../assets/logos/Rupee-Symbol-Black.svg";
import uploadIcon from "../../../assets/logos/upload.svg";
import CommonButton from "../../../components/common/CommonButton";
import CustomSelect from "../../../components/common/CustomSelect";
import { getExpenseCategory, updateExpense } from "../../../services";
import { _contractStatus } from "../../../utils/constant";
import useToast from "../../../utils/customHooks/useToast";

const formatDate = (comingDate) => {
  const date = new Date(comingDate);

  // Format the Date object to the desired ISO 8601 string
  const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss") + "Z";
  return formattedDate;
};

const ResubmitExpense = ({ handleCancel, resubmitExpenseData }) => {
  const [dropdownList, setDropdownList] = useState([]);
  const [expenseDate, setExpenseDate] = useState(null);
  const [expenseName, setExpenseName] = useState("");
  const [expenseAmount, setExpenseAmount] = useState("");
  const [expenseCategory, setExpenseCategory] = useState({});
  const [expenseSubCategory, setExpenseSubCategory] = useState({});
  const [expenseAttachments, setExpenseAttachments] = useState([]);
  const [expenseDescription, setExpenseDescription] = useState("");
  const [submitExpense, setSubmitExpense] = useState(false);
  const fileInputRef = useRef(null);
  const showToast = useToast();

  const handleFileSelect = (e) => {
    const files = e.target.files;
    if (!files) return;

    const allowedTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    // Filter the files based on allowed types
    const newFiles = Array.from(files).filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (newFiles.length === 0) {
      showToast(
        "Please select valid files: images, PDFs, DOC, or DOCX",
        "error"
      );
      return;
    }

    // Combine existing attachments with new files, limiting the total to 5
    setExpenseAttachments((prevAttachments) => {
      const combinedAttachments = [...prevAttachments, ...newFiles].slice(0, 5);
      return combinedAttachments;
    });

    // Reset the file input value to allow re-selecting the same file
    fileInputRef.current.value = null;
  };

  const removeAttachment = (index) => {
    setExpenseAttachments((prevAttachments) =>
      prevAttachments.filter((_, i) => i !== index)
    );
  };

  const renderPreview = (file, index) => {
    const fileURL = URL.createObjectURL(file);
    const fileType = file.type.split("/")[0];
    const fileExtension = file.name.split(".").pop();

    let previewContent;

    if (fileType === "image") {
      previewContent = <img className="thumb" src={fileURL} alt={file.name} />;
    } else if (fileExtension === "pdf") {
      previewContent = <img className="thumb" src={pdfIcon} alt="PDF Icon" />;
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      previewContent = <img className="thumb" src={docIcon} alt="DOC Icon" />;
    } else {
      previewContent = <div className="thumb">File</div>;
    }

    return (
      <li key={index}>
        <div className="img-wrap">
          <span
            className="close"
            style={{
              left:
                fileExtension === "pdf" ||
                fileExtension === "doc" ||
                fileExtension === "docx"
                  ? "10px"
                  : "5px",
            }}
            onClick={() => removeAttachment(index)}
          >
            &times;
          </span>
          {previewContent}
        </div>
      </li>
    );
  };

  const handleSubmitExpense = async (key) => {
    const formData = new FormData();
    const token = localStorage.getItem("token");

    setSubmitExpense(true);
    if (!expenseDate) {
      showToast("Expense Date is required!", "error");
      setSubmitExpense(false);
      return;
    }
    if (!expenseName) {
      showToast("Expense Name is required!", "error");
      setSubmitExpense(false);
      return;
    }
    if (!expenseAmount) {
      showToast("Expense Amount is required!", "error");
      setSubmitExpense(false);
      return;
    }
    if (expenseCategory && !expenseCategory.value) {
      showToast("Expense Category is required!", "error");
      setSubmitExpense(false);
      return;
    }
    if (expenseSubCategory && !expenseSubCategory.value) {
      showToast("Expense Sub Category is required!", "error");
      setSubmitExpense(false);
      return;
    }
    if (expenseAttachments.length === 0) {
      showToast("Expense attachments are required!", "error");
      setSubmitExpense(false);
      return;
    }
    if (!expenseDescription) {
      showToast("Expense Description is required!", "error");
      setSubmitExpense(false);
      return;
    }
    formData.append("createBy", resubmitExpenseData?.createBy);
    formData.append("role", resubmitExpenseData?.role);
    formData.append("userId", resubmitExpenseData?.userId);
    formData.append("projectId", resubmitExpenseData?.projectId);
    formData.append("projectName", resubmitExpenseData?.projectName);
    formData.append("title", expenseName);
    formData.append("expenseDate", formatDate(expenseDate));
    formData.append("category", expenseCategory?.value);
    formData.append("subCategory", expenseSubCategory?.value);
    formData.append("amount", +expenseAmount);
    formData.append("description", expenseDescription);
    formData.append("reviewStatus", key);
    expenseAttachments.forEach((file) => {
      formData.append("documents", file);
    });
    const resubmitExpense = await updateExpense(
      resubmitExpenseData?.id,
      formData,
      token
    );
    if (resubmitExpense.data?.status === 1) {
      showToast("Expense Re-Submitted Successfully!", "success");
      setSubmitExpense(false);
    } else {
      showToast("Expense Re-Submission Failed!", "error");
      setSubmitExpense(false);
    }
    handleCancel();
  };

  const handleChange = (e, key) => {
    const validateAmount = (amount) => {
      // Allow empty string and numbers with up to two decimal places
      const regex = /^\d*(\.\d{0,2})?$/;
      return amount === "" || regex.test(amount);
    };
    let value;
    if (key === "ExpenseDate") {
      value = e;
    } else {
      value = e.target.value;
    }
    switch (key) {
      case "ExpenseDate":
        setExpenseDate(value);
        break;
      case "ExpenseName":
        setExpenseName(value);
        break;
      case "ExpenseAmount":
        if (validateAmount(value)) setExpenseAmount(value);
        break;
      case "ExpenseDescription":
        setExpenseDescription(value);
        break;
      default:
        break;
    }
  };

  const handleSelect = (option, name) => {
    if (name === "category") {
      setExpenseCategory(option);
    } else {
      setExpenseSubCategory(option);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await getExpenseCategory();
      const transformedData = response.data?.responseData?.categories.map(
        (category) => ({
          label: category?.name,
          value: category?.name,
          subCategory: category?.subcategories?.map((sub) => ({
            label: sub?.name,
            value: sub?.name,
          })),
        })
      );
      setDropdownList(transformedData);
    })();
  }, []);

  useEffect(() => {
    if (resubmitExpenseData && resubmitExpenseData.title) {
      setExpenseName(resubmitExpenseData.title);
    }
    if (resubmitExpenseData && resubmitExpenseData.expenseDate) {
      setExpenseDate(resubmitExpenseData.expenseDate);
    }
    if (resubmitExpenseData && resubmitExpenseData.amount) {
      setExpenseAmount(resubmitExpenseData.amount);
    }
    if (resubmitExpenseData && resubmitExpenseData.category) {
      setExpenseCategory({
        label: resubmitExpenseData.category,
        value: resubmitExpenseData.category,
      });
    }
    if (resubmitExpenseData && resubmitExpenseData.subCategory) {
      setExpenseSubCategory({
        label: resubmitExpenseData.subCategory,
        value: resubmitExpenseData.subCategory,
      });
    }
    if (resubmitExpenseData && resubmitExpenseData.description) {
      setExpenseDescription(resubmitExpenseData.description);
    }
  }, [resubmitExpenseData]);

  return (
    <>
      <div className="contract_main">
        <div className="contract_left" style={{ width: "100%" }}>
          <div
            className="contract_left_template"
            style={{ width: "100%", maxHeight: "75vh", overflowY: "auto" }}
          >
            <h2 className="dashboard_heading">
              Re-Submitted Expense
              <span
                style={{
                  backgroundColor:
                    _contractStatus[resubmitExpenseData?.reviewStatus]?.color,
                  marginLeft: "10px",
                  padding: "6px",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#ffffff",
                  fontWeight: 400,
                  borderRadius: "4px",
                  maxWidth: "120px",
                }}
              >
                {_contractStatus[resubmitExpenseData?.reviewStatus]?.value}
              </span>
            </h2>
            <ul className="modal_main_ul">
              <li className="modal_main_li" style={{ width: "43%" }}>
                <label className="font_14">Project</label>
                <div className="_editable_">
                  {resubmitExpenseData && resubmitExpenseData.projectName ? (
                    <input
                      type="text"
                      placeholder="Project"
                      value={resubmitExpenseData.projectName}
                      disabled
                    />
                  ) : null}
                </div>
              </li>
              <li className="modal_main_li" style={{ width: "43%" }}>
                <label className="font_14">Expense Date</label>
                <DatePicker
                  selected={expenseDate}
                  placeholderText="MMMM DD, YYYY"
                  dateFormat="MMMM dd, yyyy"
                  onChange={(date) => handleChange(date, "ExpenseDate")}
                />
              </li>
            </ul>
            <ul className="modal_main_ul" style={{ marginTop: "0" }}>
              <li className="modal_main_li" style={{ width: "43%" }}>
                <label className="font_14">Expense Name</label>
                <div className="_editable_">
                  <input
                    type="text"
                    placeholder="Expense Name"
                    value={expenseName}
                    onChange={(e) => handleChange(e, "ExpenseName")}
                  />
                </div>
              </li>
              <li className="modal_main_li" style={{ width: "43%" }}>
                <label className="font_14">Amount</label>
                <div className="dealroom_detail_upper_left">
                  <div className="dashboard_upper_input">
                    <input
                      type="text"
                      placeholder="Enter Amount"
                      style={{
                        fontSize: "14px",
                        outline: "none !important",
                        width: "100%",
                        padding: "15px 15px 15px 30px",
                        border: "1px solid #e7e8ea",
                        borderRadius: "8px",
                        color: "black",
                        backgroundColor: "white",
                      }}
                      value={expenseAmount}
                      onChange={(e) => handleChange(e, "ExpenseAmount")}
                    />
                    <img
                      src={rupeesIcon}
                      alt="rupeesIcon"
                      width={10}
                      height={10}
                      style={{ top: "21px" }}
                    />
                  </div>
                </div>
              </li>
            </ul>
            <ul className="modal_main_ul" style={{ marginTop: "0" }}>
              <li className="modal_main_li" style={{ width: "43%" }}>
                <label className="font_14">Category</label>
                <CustomSelect
                  options={dropdownList}
                  name="category"
                  placeholder="Select Category"
                  initialValue={expenseCategory}
                  onSelect={handleSelect}
                />
              </li>
              <li className="modal_main_li" style={{ width: "43%" }}>
                <label className="font_14">Subcategory Name</label>
                <CustomSelect
                  options={
                    dropdownList?.find(
                      (list) => list.value === expenseCategory?.value
                    )?.subCategory || []
                  }
                  name="subCategory"
                  placeholder="Select Sub Category"
                  initialValue={expenseSubCategory}
                  onSelect={handleSelect}
                />
              </li>
            </ul>
            <ul className="modal_main_ul" style={{ marginTop: "0" }}>
              <li className="modal_main_li" style={{ width: "43%" }}>
                <label className="font_14">Receipt Image</label>
                <div className="_editable_">
                  <div
                    className="upload-logo-wrapper"
                    style={{ width: "100%", justifyContent: "flex-start" }}
                  >
                    <div className="uploaded-logo" style={{ width: "100%" }}>
                      {expenseAttachments.length === 0
                        ? ""
                        : expenseAttachments.length === 1
                        ? "1 file Uploaded"
                        : `${expenseAttachments.length} files Uploaded`}
                    </div>
                    <div
                      className="upload-btn"
                      onClick={() => fileInputRef.current.click()}
                    >
                      <label style={{ color: "white", marginBottom: 0 }}>
                        <div className="upload-btn-inner _pointer_">
                          <div className="_pointer_">Upload</div>
                          <div className="_pointer_">
                            <img src={uploadIcon} alt="upload" />
                          </div>
                        </div>
                      </label>
                      <input
                        tabIndex={6}
                        id="logo-upload-input"
                        type="file"
                        accept="image/*,.pdf,.doc,.docx"
                        onChange={handleFileSelect}
                        multiple
                        ref={fileInputRef}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
                {expenseAttachments.length > 0 ? (
                  <ul
                    className="thumb-Images"
                    id="imgList"
                    style={{ marginTop: 10 }}
                  >
                    {expenseAttachments.map((file, index) =>
                      renderPreview(file, index)
                    )}
                  </ul>
                ) : null}
              </li>
            </ul>
            <ul className="modal_main_ul" style={{ marginTop: 0 }}>
              <li className="modal_main_li" style={{ width: "100%" }}>
                <label className="font_14">Description (in 200 words)</label>
                <div className="_editable_">
                  <textarea
                    rows="4"
                    cols="50"
                    className="_text_area_"
                    placeholder="Write the details of the Expense"
                    value={expenseDescription}
                    maxLength={200}
                    style={{ height: "135px" }}
                    onChange={(e) => handleChange(e, "ExpenseDescription")}
                    // onKeyDown={handleKeyDown}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="contract_main_button">
        <CommonButton
          label="Cancel"
          fontSize="14px"
          fontWeight="700"
          className="dash_common_btn white_bg_btn"
          icon={false}
          onClick={handleCancel} // to go back
        />
        <CommonButton
          label="Re-Submitted Expense"
          fontSize="14px"
          fontWeight="700"
          className="dash_common_btn"
          icon={false}
          isLoad={submitExpense}
          onClick={() => handleSubmitExpense(2)} // to submit new expense
        />
      </div>
    </>
  );
};

export default ResubmitExpense;
