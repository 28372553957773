import React, { useState } from 'react'
import plusblue from "../assets/dashboard/plus_blue.svg";
import CommonButton from './common/CommonButton';
import AddNewProject from '../modals/AddNewProject';
import ClientDetails from '../modals/ClientDetails';
const QuickActions = ({ refreshPipline }) => {
    const [showProjectForm, setShowProjectForm] = useState(false);
    const [isClientDetailsModalOpen, setClientDetailsModalOpen] = useState(false);
    const handleShow = () => setShowProjectForm(true);
  return (
    <div className="dash_action">
        <div className="heading_symbol">
        <h3 className="dashboard_heading">Quick Actions</h3>
        </div>
        <ul className="dash_action_ul">
        <li className="dash_action_li">
            <CommonButton
            label="Projects"
            fontSize="14px"
            width="100%"
            fontWeight="700"
            className="dash_common_btn white_bg_btn"
            icon={plusblue}
            onClick={handleShow}
            />
        </li>
        <li className="dash_action_li">
            <CommonButton
            label="Invoice"
            fontSize="14px"
            width="100%"
            fontWeight="700"
            className="dash_common_btn white_bg_btn"
            icon={plusblue}
            />
        </li>
        <li className="dash_action_li">
            <CommonButton
            label="Contract"
            fontSize="14px"
            width="100%"
            fontWeight="700"
            className="dash_common_btn white_bg_btn"
            icon={plusblue}
            />
        </li>
        <li className="dash_action_li">
            <CommonButton
            label="Proposal"
            fontSize="14px"
            width="100%"
            fontWeight="700"
            className="dash_common_btn white_bg_btn"
            icon={plusblue}
            />
        </li>
        </ul>

        {showProjectForm && 
        <AddNewProject 
        onHide={() => setShowProjectForm(false)}
        isNext={isClientDetailsModalOpen}
        openClientDetailModal={() => setClientDetailsModalOpen(true)}
        isOpen={showProjectForm}/>}

        {isClientDetailsModalOpen && 
        <ClientDetails
        _refreshPipeLine={refreshPipline}
        openProject={() => setShowProjectForm(true)} 
        onHide={() => setClientDetailsModalOpen(false)} 
        isOpen={showProjectForm}/>}
    </div>
  )
}

export default QuickActions
