import React, { useEffect, useState } from "react";
import CommonButton from "../../../components/common/CommonButton";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { createInvoice, sendInvoiceToPDF } from "../../../services";
import useToast from "../../../utils/customHooks/useToast";
import { Button, Modal, Spinner } from "react-bootstrap";

const SchedulePreviewInvoice = ({ openDeposit, openMilestone, formValue,enableRecurring, previewData, onCancel, invoiceMainClose }) => {
  const showToast = useToast();
    const [buttonLoader, setbuttonLoader] = useState()
    const ProjectDetails = JSON.parse(localStorage.getItem('projectDetail'))  
    const [cancelbuttonLoader, setcancelbuttonLoader] = useState(false);

    const authUserData = JSON.parse(localStorage.getItem('authUserData'))  
    
    
  const generatePDF = async () => {
    try {
    setbuttonLoader(true)
      const input = document.getElementById('invoice-preview');
      if (!input) {
        setbuttonLoader(false)
        throw new Error('Invoice preview element not found.');
      }
  
      // Generate PDF from HTML
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgWidth = 210; 
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
  
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
  
      const pdfBlob = pdf.output('blob');      
  
      // Prepare payload
      const projectDetails = JSON.parse(localStorage.getItem('projectDetail')) || {};
      const projectId = projectDetails?.id
  
      const payload = {
        ...(enableRecurring && {
            recurrenceData: {
              isRecurrence: formValue?.recurrenceData?.isRecurrence ?? true,
              sendDay: formValue?.recurrenceData?.sendDay ?? 1,
              sendPeriod: formValue?.recurrenceData?.sendPeriod ?? 1,
              startDate: formValue?.recurrenceData?.startDate ?? "string",
              endDate: formValue?.recurrenceData?.endDate ?? "string",
              isNeverEnd: formValue?.recurrenceData?.isNeverEnd ?? true,
            },
          }),
        invoiceItems: formValue?.invoiceItems,
        invoiceAmount: {
          subTotalAmount: formValue?.invoiceAmount?.subTotalAmount ?? 0,
          discount: formValue?.invoiceAmount?.discount ?? 0,
          tax: formValue?.invoiceAmount?.tax ?? 0,
          discountData: formValue?.invoiceAmount?.discountData?.[0]?.value ?? "string",
          taxData: formValue?.invoiceAmount?.taxData?.[0]?.value ?? "string",
          totalAmount: formValue?.invoiceAmount?.totalAmount ?? 0
        },
        depositSchedule: {
          initialType: 2147483647,
          initialValue: previewData?.depositSchedule[0]?.calculatedAmount,
          initialAmount: previewData?.depositSchedule[0]?.amount,
          initialDueDate: previewData?.depositSchedule[0]?.dueDate,
          balanceValue: previewData?.depositSchedule[1]?.calculatedAmount,
          balanceAmount: previewData?.depositSchedule[1]?.amount,
          balanceDueDate:previewData?.depositSchedule[1]?.dueDate
        },
        balanceSchedule: {
          initialType: 2147483647,
          initialValue: previewData?.milestoneSchedule[0]?.percentage,
          initialAmount: previewData?.milestoneSchedule[0]?.amount,
          initialDueDate: previewData?.milestoneSchedule[0]?.dueDate,
          balanceValue: previewData?.milestoneSchedule[1]?.percentage,
          balanceAmount: previewData?.milestoneSchedule[1]?.amount,
          balanceDueDate: previewData?.milestoneSchedule[1]?.dueDate
        },
        createBy: authUserData?.firstName + " " + authUserData?.lastName ?? "string",
        status: 20,
        projectName: formValue?.projectName ?? "string",
        title: formValue?.title ?? "string",
        dueDate: formValue?.dueDate ?? "string",
        invoiceHash: formValue.invoiceHash ?? "string",
        notes: formValue?.notes ?? "string",
        invoiceType: 2,
        projectId: projectId
      };
  
      const token = localStorage.getItem("token");
  
      // Make API call
      const response = await createInvoice(payload, token);
      const responseData = response.data;
      const invoiceId = responseData?.responseData?.id;
        if (responseData.status === 1){
            const formData = new FormData();
            formData.append('projectId', projectId);
            formData.append('invoiceId', invoiceId);
            formData.append('pdfFile', pdfBlob, "invoice.pdf");
            const res = await sendInvoiceToPDF(formData, token)
            console.log(res, "aljsdbjkasdbljasd");
            if (res?.data?.status === 1){
                setbuttonLoader(false)
                showToast("Invoice sent and pdf generated", "success")
                invoiceMainClose()
            }else{
                setbuttonLoader(false)
                showToast("Error while generating pdf", "error")
            }
        }else{
            setbuttonLoader(false)
            showToast("Error while creating invoice", "error")
        }
      
    } catch (error) {
     setbuttonLoader(false)
      console.error('Error generating PDF or creating invoice:', error);
    }
  };



  const SaveAsDraft = async () => {
    try {
        
    setcancelbuttonLoader(true)
      const input = document.getElementById('invoice-preview');
      if (!input) {
        setcancelbuttonLoader(false)
        throw new Error('Invoice preview element not found.');
      }
  
      // Generate PDF from HTML
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgWidth = 210; 
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
  
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
  
      const pdfBlob = pdf.output('blob');      
  
      // Prepare payload
      const projectDetails = JSON.parse(localStorage.getItem('projectDetail')) || {};
      const projectId = projectDetails?.id
  
      const payload = {
        ...(enableRecurring && {
            recurrenceData: {
              isRecurrence: formValue?.recurrenceData?.isRecurrence ?? true,
              sendDay: formValue?.recurrenceData?.sendDay ?? 1,
              sendPeriod: formValue?.recurrenceData?.sendPeriod ?? 1,
              startDate: formValue?.recurrenceData?.startDate ?? "string",
              endDate: formValue?.recurrenceData?.endDate ?? "string",
              isNeverEnd: formValue?.recurrenceData?.isNeverEnd ?? true,
            },
          }),
        invoiceItems: formValue?.invoiceItems,
        invoiceAmount: {
          subTotalAmount: formValue?.invoiceAmount?.subTotalAmount ?? 0,
          discount: formValue?.invoiceAmount?.discount ?? 0,
          tax: formValue?.invoiceAmount?.tax ?? 0,
          discountData: formValue?.invoiceAmount?.discountData?.[0]?.value ?? "string",
          taxData: formValue?.invoiceAmount?.taxData?.[0]?.value ?? "string",
          totalAmount: formValue?.invoiceAmount?.totalAmount ?? 0
        },
        depositSchedule: {
          initialType: 2147483647,
          initialValue: previewData?.depositSchedule[0]?.calculatedAmount,
          initialAmount: previewData?.depositSchedule[0]?.amount,
          initialDueDate: previewData?.depositSchedule[0]?.dueDate,
          balanceValue: previewData?.depositSchedule[1]?.calculatedAmount,
          balanceAmount: previewData?.depositSchedule[1]?.amount,
          balanceDueDate:previewData?.depositSchedule[1]?.dueDate
        },
        balanceSchedule: {
          initialType: 2147483647,
          initialValue: previewData?.milestoneSchedule[0]?.percentage,
          initialAmount: previewData?.milestoneSchedule[0]?.amount,
          initialDueDate: previewData?.milestoneSchedule[0]?.dueDate,
          balanceValue: previewData?.milestoneSchedule[1]?.percentage,
          balanceAmount: previewData?.milestoneSchedule[1]?.amount,
          balanceDueDate: previewData?.milestoneSchedule[1]?.dueDate
        },
        createBy: authUserData?.firstName + " " + authUserData?.lastName ?? "string",
        status: 1,
        projectName: formValue?.projectName ?? "string",
        title: formValue?.title ?? "string",
        dueDate: formValue?.dueDate ?? "string",
        invoiceHash: formValue.invoiceHash ?? "string",
        notes: formValue?.notes ?? "string",
        invoiceType: 2,
        projectId: projectId
      };
  
      const token = localStorage.getItem("token");
  
      // Make API call
      const response = await createInvoice(payload, token);
      const responseData = response.data;
      const invoiceId = responseData?.responseData?.id;
        if (responseData.status === 1){
            const formData = new FormData();
            formData.append('projectId', projectId);
            formData.append('invoiceId', invoiceId);
            formData.append('pdfFile', pdfBlob, "invoice.pdf");
            const res = await sendInvoiceToPDF(formData, token)
            console.log(res, "aljsdbjkasdbljasd");
            if (res?.data?.status === 1){
                setcancelbuttonLoader(false)
                showToast("Your invoice has been drafted successfully.", "success")
                invoiceMainClose()
            }else{
                setcancelbuttonLoader(false)
                showToast("Error while generating pdf", "error")
            }
        }else{
            setcancelbuttonLoader(false)
            showToast("Error while creating invoice", "error")
        }
      
    } catch (error) {
     setcancelbuttonLoader(false)
      console.error('Error generating PDF or creating invoice:', error);
    }
  };

  const [openModal, setopenModal] = useState(false)

  const openDraftModal = () =>{
    setopenModal(true)
  }


  const [businessdetails, setBusinessdetails] = useState([])
  useEffect(()=>{
   const getBusinessDetail = localStorage.getItem("authUserData") 
    if (getBusinessDetail){
      setBusinessdetails(JSON.parse(getBusinessDetail))
    }
  },[])


  function formatDate(dateStr) {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(dateStr).toLocaleDateString('en-US', options);
  }

  return (
    <>

        <Modal
            show={openModal}
            onHide={() => setopenModal(false)}
            className="dealroom_initial_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Do you want to save the invoice as draft?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={invoiceMainClose}>
                Discard
              </Button>
              <Button variant="primary" disabled={cancelbuttonLoader}  onClick={SaveAsDraft}>
                {cancelbuttonLoader ? (
                    <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    {' '}
                    Loading...
                    </>
                ) : (
                    'Save Draft'
                )}
              </Button>
            </Modal.Footer>
          </Modal>
    <div id="invoice-preview">
        {enableRecurring ? (
              <div>
                <h2>Recurring Invoice</h2>
                <p>
                  Send Every: {formValue?.recurrenceData?.sendDay}{" "}
                  {formValue?.recurrenceData?.sendPeriod}
                </p>
                <p>
                  Starts On:{" "}
                  {formatDate(new Date(
                    formValue?.recurrenceData?.startDate
                  ).toLocaleDateString())}
                </p>
                <p>
                  Ends On:{" "}
                  {formatDate(formValue?.recurrenceData?.isNeverEnd
                    ? "Never Ends"
                    : new Date(
                        formValue.recurrenceData?.endDate
                      ).toLocaleDateString())}
                </p>
              </div>
        ) : (
            ""
        )}
        <div className="billing_container billing_container1 invoice_main_list_1">
            <div className="billing_column">
            <img src={businessdetails?.businessDetail?.logo} alt="Logo" style={{ width: '100px', height: 'auto' }} />

            </div>
            <div className="billing_column billing_column1">
                <p className="font_14">
                Invoice Number : {formValue?.invoiceHash}
                </p>
                {/* <p className="font_14">
                Invoice Date :{" "}
                {new Date(formValue?.dueDate).toLocaleDateString()}
                </p> */}
                <p className="font_14">
                Due On : {formatDate(new Date(formValue?.dueDate).toLocaleDateString())}
                </p>
            </div>
        </div>
        
        <div className="billing_container invoice_main_list_1">
              <div className="billing_column">
                <h3 className="font_16 font_600">Billed To</h3>
                <div className="billing_details">
                  <p>{ProjectDetails?.clients[0]?.clientName}</p>
                  <p>{ProjectDetails?.clients[0]?.address}</p>
                  <p>{ProjectDetails?.clients[0]?.email}</p>
                  <p>{ProjectDetails?.clients[0]?.phone_number}</p>
                </div>
              </div>
              <div className="billing_column billing_column1">
                <h3 className="font_16 font_600">Billed From</h3>
                <div className="billing_details">
                  <p>{businessdetails?.businessDetail?.businessName}</p>
                  <p>{businessdetails?.businessDetail?.businessAddress}</p>
                  <p>{businessdetails?.businessDetail?.email}</p>
                  <p>{businessdetails?.businessDetail?.phone_number}</p>
                </div>
              </div>
        </div>

        <h2 className="dashboard_heading">Items</h2>
        <div style={{ maxHeight: "450px", overflowY: "auto", margin: "30px 0 50px" }}>
        <table className="project_table dealroom_table">
            <thead>
            <tr>
                <th>S.No.</th>
                <th>Item</th>
                <th>Item Description</th>
                <th>Rate</th>
                <th>Quantity</th>
                <th>Total Cost</th>
            </tr>
            </thead>
            <tbody>
            {formValue?.invoiceItems?.map((item, index) => (
                <tr key={index}>
                <td>{index + 1}</td>
                <td>{item?.item}</td>
                <td>{item?.description}</td>
                <td>{item?.rate}</td>
                <td>{item?.quantity}</td>
                <td>{item?.totalCost}</td>
                </tr>
            ))}
            </tbody>
        </table>
        </div>

        <div className="invoice_main_lower dflex">

            <div className="invoice_main_lower_left" >
                <p className="font_14">Additional Note</p>
                <textarea
                style={{outline: 'none', border: '2px solid #eeeeee', height: '90%'}}
                className="invoice_main_lower_left_main"
                placeholder="Add a note that your clients can see when viewing the invoice."
                value={formValue.additionalNote}
                readOnly
                />
            </div>

            <div className="invoice_main_lower_right">
            <div className="invoice_total_main _flex_row_spacebtw_ ">
              <div className="invoice_total_main_left">
                <h2 className="dashboard_heading" >
                  Sub Total
                </h2>
              </div>
              <div className="invoice_total_main_right modal_main_li">
                <input
                  type="text"
                  placeholder="Rs. 25000"
                  value={'₹ '+formValue.invoiceAmount.subTotalAmount}
                  readOnly
                />
              </div>
            </div> 
                  {console.log(formValue?.invoiceAmount?.discountData,"saljkdbalkdjas")}
                <div className="invoice_main_discount_tax">
                  {
                    formValue?.invoiceAmount?.discountData[0]?.value ? (
                      <div className="invoice_total_main _flex_row_spacebtw_">
                        <div className=" invoice_total_main_left1 _flex_row_spacebtw_">
                        <h2 className="invoice_total_main_left_heading" style={{ fontWeight: "400", fontSize: '18px' }}>Discount (-)</h2>
                        <ul>
                        {formValue?.invoiceAmount?.discountData.map((discount, index) => (
                            <li key={index} className="modal_main_li invoice_total_dropdown1">
                              <div className="_editable_ preview_edit" style={{ display: "flex", gap: "10px" }}>
                                <div>
                                <input
                                  type="text"
                                  placeholder="discount Name"
                                  value={discount?.name}
                                  maxLength={30}
                                  readOnly
                                  style={{ width: "30%", marginRight:"30px"}}
                                />
                                <input
                                  type="text"
                                  placeholder="discount Value"
                                  value={discount?.value}
                                  readOnly
                                  style={{ width: "30%", marginRight:"10px" }}
                                />
                                <input
                                  type="text"
                                  placeholder="discount Type"
                                  value={discount?.type?.value}
                                  readOnly
                                  style={{ width: "12%" }}
                                />

                                </div>
                                  <input
                                    type="text"
                                    placeholder="Rs. 25000"
                                    value={'₹ '+discount?.discountedValue}
                                    readOnly
                                    className="total_value_input"
                                    style={{ width: "40%" }}
                                  />
                              </div>
                            </li>
                        ))}
                        </ul>
                        </div>
                      </div>
                      ) : ''
                  }
                  <h2 className="dashboard_heading"></h2>
                { 
                  formValue?.invoiceAmount?.taxData[0]?.value ? ( 
                    <div>
                      <div className="invoice_total_main _flex_row_spacebtw_">
                        <div className=" invoice_total_main_left1 _flex_row_spacebtw_">
                        <h2 className="invoice_total_main_left_heading" style={{ fontWeight: "400", fontSize: '18px' }}>Tax</h2>
                        <ul>
                        {formValue?.invoiceAmount?.taxData.map((tax, index) => (
                          <li key={index} className="modal_main_li invoice_total_dropdown1">
                            <div className="_editable_ preview_edit" style={{ display: "flex", gap: "10px" }}>
                              <div>
                              <input
                                type="text"
                                placeholder="Tax Name"
                                value={tax?.name}
                                maxLength={30}
                                readOnly
                                style={{ width: "30%", marginRight:"30px"}}
                              />
                              <input
                                type="text"
                                placeholder="Tax Value"
                                value={tax?.value}
                                readOnly
                                style={{ width: "30%", marginRight:"10px" }}
                              />
                              <input
                                type="text"
                                placeholder="Tax Type"
                                value={tax?.type?.value}
                                readOnly
                                style={{ width: "12%" }}
                              />
                            </div>
                              <input
                                type="text"
                                placeholder="Rs. 25000"
                                value={'₹ '+tax?.taxedValue}
                                readOnly
                                style={{ width: "40%" }}
                                className="total_value_input"
                                />    
                            </div>
                          </li>
                        ))}
                        </ul>
    
                        </div>
                          {/* <div className="invoice_total_main_right modal_main_li invoice_total_main_right2">
                            <input
                              type="text"
                              placeholder="Rs. 25000"
                              value={12}
                              readOnly
                            />
                        </div> */}
                      
                      </div>
                    </div>
                    )
                    : ''
                }
               
              </div>
            
        
            <div className="invoice_total">
              <div className="invoice_total_main _flex_row_spacebtw_ ">
                <div className="invoice_total_main_left">
                  <h2 className="dashboard_heading">Total</h2>
                </div>
                <div className="invoice_total_main_right modal_main_li">
                  <input
                    type="text"
                    placeholder="Rs. 25000"
                    value={'₹ '+formValue.invoiceAmount.totalAmount}
                    readOnly
                  />
                </div>
              </div>
            </div>
            </div>
        </div>

        {/* Deposit Schedule Table */}

        {openDeposit ? (
            <>
            <h2>Deposit Schedule</h2>
            <table
              className="project_table dealroom_table"
              style={{ marginTop: 0 }}
            >
                <thead>
                <tr>
                    <th>Amount %</th>
                    <th>Amount</th>
                    <th>Details</th>
                    <th>Due Date</th>
                </tr>
                </thead>
                <tbody>
                {previewData?.depositSchedule.map((item, index) => (
                    <tr key={index}>
                    <td>{item.amount}</td>
                    <td>{item.calculatedAmount}</td>
                    <td>{item.details}</td>
                        {/* Display due date if available */}
                        <td>{formatDate(item?.dueDate)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            </>
        ) :''}
        
        {/* Milestone Schedule Table */}
        {openMilestone ? (
        <>
        <h2>Milestone Schedule</h2>
            <table
            className="project_table dealroom_table"
            style={{ marginTop: 0 }}
            >
                <thead>
                <tr>
                    <th>Amount %</th>
                    <th>Amount</th>
                    <th>Details</th>
                    <th>Due Date</th>
                </tr>
                </thead>
                <tbody>
                {previewData?.milestoneSchedule.map((item, index) => (
                    <tr key={index}>
                    <td>{item?.percentage || ''}</td>
                    <td>{item?.amount}</td>
                    <td>{item?.details}</td>
                    <td>{formatDate(item?.dueDate)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
        ) : ''}
    </div>
    <div className="contract_main_button">
        <CommonButton
            label="Cancel"
            fontSize="14px"
            fontWeight="700"
            className="dash_common_btn white_bg_btn"
            icon={false}
            onClick={openDraftModal}
        />
        <CommonButton
            label="Send Invoice"
            fontSize="14px"
            fontWeight="700"
            className="dash_common_btn"
            icon=""
            isLoad={buttonLoader}
            onClick={generatePDF}
        />
    </div>
   </>
  );
}
export default SchedulePreviewInvoice;
