import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import Projects from "./pages/projects/Projects";
import ProjectDetails from './pages/projects/ProjectDetails'

const _nav = [
  {
    path: '/dashboard',
    component: Dashboard,
    roles: ['superadmin', 'admin', 'user']
  },
  {
    path: '/projects',
    component: Projects,
    roles: ['superadmin', 'admin']
  },
  {
    path: '/projectdetails',
    component: ProjectDetails,
    roles: ['superadmin', 'admin']
  },
  {
    path: '*',
    component: NotFound,
    roles: ['superadmin', 'admin']
  },
];

export default _nav;