export function separateCountryCodeAndNumber(value) {
  // Split the input string by space
  const parts = value.split(" ");

  // Extract country code and number
  // const countryCode = parts[0].substring(1); // Remove the '+' sign
  const countryCode = parts[0];
  const number = parts.slice(1).join(" ").trim().replace(/\s/g, ""); // Join the remaining parts to form the number, remove leading/trailing whitespace, and remove all spaces

  return {
    cc: countryCode,
    number: number,
  };
}

export const validateEmail = (email) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();

  const isToday = date.toDateString() === now.toDateString();
  const isYesterday =
    date.toDateString() ===
    new Date(now.setDate(now.getDate() - 1)).toDateString();

  const time = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  if (isToday) {
    return `Today, ${time}`;
  } else if (isYesterday) {
    return `Yesterday, ${time}`;
  } else {
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}, ${time}`;
  }
};

export function replaceNewlinesWithCommas(str) {
  // Trim any extra newline characters at the end of the string
  str = str.replace(/\n+$/, "");
  // Replace remaining newline characters with commas
  return str.replace(/\n/g, ",");
}

// Function to capitalize the first letter of a string
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const hexToRgb = (hex) => {
  // Remove the leading '#' if present
  hex = hex.replace(/^#/, "");

  // Parse the r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  // Return the RGB color in the desired format
  return `rgb(${r / 255}, ${g / 255}, ${b / 255})`;
};

export const hexToRgba = (hex, opacity) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r},${g},${b},${opacity})`;
};

export const hexToRgbValues = (hex) => {
  hex = hex.replace(/^#/, "");
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return [r / 255, g / 255, b / 255];
};

export const createSchema = (signers) => {
  const defaultValues = {
    clientFirstName: "",
    clientLastName: "",
    clientAddress: "",
    clientEmail: "",
    clientInitials: "",
    clientSignature: "",
    companyName: "",
    companyAddress: "",
    companyPhoneNumber: "",
    projectName: "",
    projectAddress: "",
    projectDueDate: "",
  };

  const schema = { ...defaultValues };

  // signers.forEach(signer => {
  //   schema[signer.email] = { ...defaultValues, clientEmail: signer.email };
  // });

  // signers.forEach(signer => {

  //   const email = signer.email;
  //   const userSchema = { ...defaultValues };

  //   if (signer.userType === 1) {
  //     const firstName = localStorage.getItem('firstName') || '';
  //     const lastName = localStorage.getItem('lastName') || '';
  //     userSchema.clientFirstName = firstName;
  //     userSchema.clientLastName = lastName;
  //   }

  //   schema[email] = userSchema;
  // });

  return schema;
};

// export async function pdfBlobUrlToFile(blobUrl, fileName) {
//   console.log(blobUrl, fileName, '{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}')
//   // Fetch the Blob from the Blob URL
//   const response = await fetch(blobUrl);
//   const blob = await response.blob();

//   // Create a PDF File from the Blob
//   const file = new File([blob], fileName, { type: 'application/pdf' });

//   return file;
// }

export async function pdfBlobUrlToFile(blobUrl, fileName) {
  try {
    console.log(blobUrl, fileName, "Fetching Blob URL...");

    // Fetch the Blob from the Blob URL
    const response = await fetch(blobUrl);

    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.statusText}`);
    }

    const blob = await response.blob();

    // Create a PDF File from the Blob
    const file = new File([blob], fileName, { type: "application/pdf" });

    return file;
  } catch (error) {
    console.error("Error fetching blob URL:", error);
    throw error;
  }
}

export async function base64ImageToFile(base64String, fileName) {
  try {
    const response = await fetch(base64String);
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  } catch (error) {
    console.error("Error converting Base64 to File:", error);
    throw error;
  }
}

export function isBase64Image(value) {
  // Check if the value starts with "data:image/"
  return typeof value === "string" && value.startsWith("data:image/");
}

export const clientformatDate = (comingDate) => {
  // Create a new Date object from the string
  const date = new Date(comingDate);

  // Define options for formatting the date
  const options = { day: "2-digit", month: "long", year: "numeric" };

  // Format the date
  const formattedDate = date.toLocaleDateString("en-GB", options);

  // Split the formatted date
  const [day, month, year] = formattedDate.split(" ");

  // Format the desired output
  const finalFormattedDate = `${month} ${day}, ${year}`;

  return finalFormattedDate;
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + +days);
  return result;
};

export const subtractDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - +days);
  return result;
};


export const formatDate2 = (date) => {
  if (!date) return ''; // Handle empty date input
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit'
  });
};