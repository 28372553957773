import React, { useEffect, useState } from "react";
import PhoneInput, 
{ formatPhoneNumber, 
formatPhoneNumberIntl,
isValidPhoneNumber} 
from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import { useFetcher } from "react-router-dom";

const PhoneNumber = ({value, setValue, isError, setError, isSubmitTouch}) => {
  
  const [touched, setTouched] = useState(false);
  const [focus, setFocus] = useState(false)
  const [val, setVal] = useState()

  useEffect(() => {
    if(isSubmitTouch === true) setTouched(true)
  },[isSubmitTouch])

  const handleOnChange = (phoneNumber) => {
    console.log(phoneNumber,'phoneNumber')
    console.log(formatPhoneNumberIntl(phoneNumber),'format number')
    setValue(formatPhoneNumberIntl(phoneNumber));
    setVal(phoneNumber)
    if (touched) {
      setError(isValidPhoneNumber(phoneNumber || "") ? "" : "Enter valid mobile number");
    }
  }

  const handleOnBlur = () => {
    setFocus(false)
    setTouched(true);
    if (!value) {
      setError("Mobile number is required");
    } else if (isValidPhoneNumber(value)) {
      setError("");
    } else {
      setError("Enter valid mobile number");
    }
  };

  const handleOnfocus = () => {
    setFocus(true)
  }

  return (
    <div className={`${(touched && isError) ? "phone-input-error" : ""} _PhoneInput_ ${focus ? !isError ? 'phone-focused' : 'phone-input-error' : 'phone-not-focused'}`}>
      <PhoneInput
        international={(val || value) ? true : false}
        placeholder="Mobile Number"
        countryCallingCodeEditable={false}
        defaultCountry="IN"
        value={val ? val : value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={handleOnfocus}
        limitMaxLength={true}
        // maxLength="15"
        error={
          value ? isValidPhoneNumber(value) 
          ? setError("") 
          : setError("Enter valid mobile number") 
          : touched ? setError('Mobile number is required') : setError('')
        }
      />
    </div>
  );
};

export default PhoneNumber;
