import { Navigate } from "react-router-dom";
const ProtectedRoutes = (props) => {
        console.log('I AM IN PROTECTED_ROUTE : ')
        const { Component } = props
        let auth = localStorage.getItem('isLogin')
        return (
        auth ? <Component/>  : <Navigate to="/" />
        )
}
export default ProtectedRoutes

