import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import filtercircle from "./../assets/dashboard/filter_cross.svg"; // Ensure the correct path to your image asset

export default function ProjectFilter({ handleFilterClose,filterBy, setFilterBy }) {

 
  const [_filter, _setFilter] = useState({
    status: {},
  });

  

  useEffect(() => {
    if (filterBy) {
      _setFilter(filterBy);
    }
  }, [filterBy]);


  const handleCheckboxChange = (category, value, e) => {
    let temp = { ..._filter };
    temp[category][value] = e.target.checked;
    _setFilter(temp);
  };

  const handleSubmit = () => {
    localStorage.setItem("dealRoomfilterCheks", JSON.stringify(_filter));
    console.log(_filter,'_filter _filter')
    
    setFilterBy(_filter);
    handleFilterClose();
  };


  return (
    <div className="project_filter_main">
      <div className="project_filter_upper">
        <h4 className="filter_popup_heading">Filter</h4>
        <img
          src={filtercircle}
          onClick={handleFilterClose}
          alt="Close filter"
        />
      </div>
      <div className="project_filter_accor">
        <Accordion>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Status</Accordion.Header>
            <Accordion.Body>
              {/* <ul className="filter_dropdown_ul">
                {_filter.map((filterItem) => (
                  <li key={filterItem.id} className="filter_dropdown_li">
                    <input
                      type="checkbox"
                      checked={filterItem.isChecked}
                      onChange={(e) => handleCheckboxChange(e, filterItem.id)}
                    />
                    <label>{filterItem.value}</label>
                  </li>
                ))}
              </ul>  */}
              <ul className="filter_dropdown_ul">
                {Object.keys(_filter.status).map((statusName) => (
                  <li key={statusName} className="filter_dropdown_li">
                    <input
                      type="checkbox"
                      checked={_filter.status[statusName]}
                      onChange={(e) => handleCheckboxChange("status", statusName, e)}
                    />
                    <label>{statusName}</label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          {/* <Accordion.Item eventKey="1">
            <Accordion.Header>Create By</Accordion.Header>
            <Accordion.Body>
              <ul className="filter_dropdown_ul">
                {Object.keys(_filter.status).map((statusName) => (
                  <li key={statusName} className="filter_dropdown_li">
                    <input
                      type="checkbox"
                      checked={_filter.status[statusName]}
                      onChange={(e) => handleCheckboxChange("status", statusName, e)}
                    />
                    <label>{statusName}</label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
      </div>
      <div className="modal_btn_div project_filter_btn">
        <button
          className="_btn_transparent_ modal_div_button"
          onClick={handleFilterClose}
        >
          <span>Cancel</span>
        </button>
        <button
          className="_btn_normal_ modal_div_button"
          onClick={handleSubmit}
        >
          <span>Submit</span>
        </button>
      </div>
    </div>
  );
}
