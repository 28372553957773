import React, { useEffect, useState } from 'react'
import RightArrowIcon from '../../assets/logos/right_arrow.svg'
import LeftArrowIcon from '../../assets/logos/left_arrow.svg'
import AddIcon from '../../assets/logos/add_icon.svg'
import DeleteIcon from '../../assets/logos/del.svg'
import CustomSelect from '../../components/common/CustomSelect'
import { useStep } from '../../utils/context/OnBoardingSteps'
import { _role, projectStage, projectStatus, role } from '../../utils/constant'
import useValidation from '../../utils/customHooks/useValidations'
import { capitalizeFirstLetter, validateEmail } from '../../utils/helper'
import { onboardingBusinessService } from '../../services'
import useToast from '../../utils/customHooks/useToast'

const ProjectInfo = () => {
  const [isRoleAdded, setIsRoleAdded] = useState(false)
  const [passedPhases, setPassedPhases] = useState([])
  const {currentStep,nextStep,prevStep} = useStep();
  const [isDropDownError, setIsDropDownError] = useState({
      role:false,
      projectStage:false
  })
  const [projectInfo, setProjectInfo] = useState({
    projectName: "",
    projectStatus: {
      name : '',
      color: ''
    },
    clientName: "",
    projectStage: {
      name : '',
      color: ''
    },
    members: []
  })
  const [selectedOption, setSelectedOption] = useState({
    status:null,
    stage:null,
    role:null
  });
  const [teamMember, setTeamMember] = useState({
    email:'',
    role:'',
  })
  const [addTeamError, setAddTeamError] = useState({
    emailError: '',
    roleError: ''
  })

  const [teamMembers, setTeamMembers] = useState([]);
  const [projectStatusInitialVal, setProjectStatusInitialVal] = useState(null)
  const [projectStageInitialVal, setProjectStageInitialVal] = useState(null)

  const showToast = useToast()

  const {
    errors,
    setErrors,
    validateForm,
    values,
    runTimeErrors,
    setRunTimeErrors,
    validateRunTimeError
  } = useValidation()

  useEffect(() => {
    const _peojectInfo = JSON.parse(localStorage.getItem('peojectInfo')) || null
    // const _projectPhases = JSON.parse(localStorage.getItem('projectPhases')) || null
    // const _phases = JSON.parse(localStorage.getItem('phases')) || null
    // if(_phases && _phases.phases && _phases.phases.length > 0){
    //   let userPhases = _phases.phases.map((item,index) => {
    //     return {'label': item, 'value': item, color:'#000'}
    //   })

    //   // Filter projectStage to include only items not in _phases.phases, case-insensitive
    //   const phasesLowercase = _phases.phases.map(phase => phase.toLowerCase());
    //   const filteredProjectStage = projectStage.filter(
    //     (stage) => !phasesLowercase.includes(stage.value.toLowerCase())
    //   );

    //   userPhases = [
    //     ...userPhases,
    //     ...filteredProjectStage.map((stage) => ({ label: stage.label, value: stage.value, color: stage.color})),
    //   ];

    //   setPassedPhases(userPhases)
    // }

    // setProjectStageInitialVal({ label: _projectPhases.phases, value: _projectPhases.phases4 })
    // setPassedPhase(_projectPhases.phases)
    // setProjectInfo({
    //   ...projectInfo,
    //   projectStage:_projectPhases.phases
    // })

    // let phase = _projectPhases.phases

    // Helper function to find the color based on value
    const findColor = (value, options) => {
      const option = options.find(opt => opt.value === value);
      return option ? option.color : null;
    };

    if(_peojectInfo){
      setProjectInfo({..._peojectInfo})
      setTeamMembers([..._peojectInfo.members])
      if(_peojectInfo.projectStatus !== ''){
        // const color = findColor(_peojectInfo.projectStatus, projectStatus);
        setProjectStatusInitialVal({ label: _peojectInfo.projectStatus.name, value: _peojectInfo.projectStatus.name, color: _peojectInfo.projectStatus.color });
      }
          // setProjectStatusInitialVal({ label: _peojectInfo.projectStatus, value: _peojectInfo.projectStatus })
        
      if(_peojectInfo.projectStage !== ''){
          // setProjectStageInitialVal({ label: _peojectInfo.projectStage, value: _peojectInfo.projectStage })
          // const color = findColor(_peojectInfo.projectStage, projectStage);
          setProjectStageInitialVal({ label: _peojectInfo.projectStage.name, value: _peojectInfo.projectStage.name, color: _peojectInfo.projectStage.color });
      }
    }
  },[])

  const handleInviteTeamMembers = () => {
    let member = {
      email:'',
      role:'',

    }
  }
  
  const handleSelect = (option, name) => {
    console.log(option,'options...................')
    setSelectedOption({
      ...selectedOption,
      role:option
    });
    // setTeamMember({
    //   ...teamMember,
    //   role: option?.value
    // })

    if(name === 'role' && option?.value !== undefined){
      setTeamMember({
        ...teamMember,
        role: option?.value
      })

      setAddTeamError({
        ...addTeamError,
        roleError:''
      })
    }

    if(name === 'projectStatus'  && option?.value !== undefined){
      let val = {
        name : option?.value,
        color: option?.color
      }
      setProjectInfo({
        ...projectInfo,
        projectStatus: val
      })
    }

    if(name === 'projectStage'  && option?.value !== undefined){
      let val = {
        name : option?.value,
        color: option?.color
      }

      setProjectInfo({
        ...projectInfo,
        projectStage:val
      })

      setIsDropDownError({
        ...isDropDownError,
        projectStage : false
      })
    }

  };

  const handleTeamMemberEmail = (fieldName, e) => {
    setTeamMember({
      ...teamMember,
      email: e.target.value
    })
    if(validateEmail(e.target.value) === false){
      setAddTeamError({
        ...addTeamError,
        emailError:'Please enter valid email'
      })
    }else {
      setAddTeamError({
        ...addTeamError,
        emailError:''
      })
    }

  }
  const handleOnChange = (fieldName, e) => {
    if(fieldName === 'projectName' || fieldName === 'clientName'){
      setProjectInfo({
      ...projectInfo,
      [fieldName] : capitalizeFirstLetter(e.target.value)
      })
    }else{
      setProjectInfo({
      ...projectInfo,
      [fieldName] : e.target.value
      })
    }
    setErrors({
    ...errors,
    [fieldName] : ''
    })
    setRunTimeErrors({
    ...runTimeErrors,
    [fieldName] : ''
    })
    validateRunTimeError(fieldName, e.target.value)
  }

  const handleDropDownError = () => {
    let validate = true
    if(projectInfo.projectStage.name === ''){
      setIsDropDownError({
        ...isDropDownError,
        projectStage : true
      })
      validate = false
    }
    return validate
  }

  const isTeamMemberRole = () => {
      let validate = true
        if(teamMember.role === ''){
          setIsDropDownError({
            ...isDropDownError,
            role: true
          })
          validate = false
        }
        return validate
  }

  const isNewMemberValidate = (type) => {
    let validate = true
    let temp = {...addTeamError}
    if(type === 'add'){
      if(teamMember.email === ''){
          temp.emailError = 'Please enter member Email Id'
          validate = false
        }else if(teamMember.email !=='' && validateEmail(teamMember.email) === false){
          temp.emailError = 'Please enter valid Email Id'
          validate = false
        }else{
            // window.alert()
        }

        if(teamMember.role === ''){
          temp.roleError = 'Please select role'
          validate = false
        }

        setAddTeamError(temp)

        return validate
    }else{
      if(teamMember.role !== '' && teamMember.email  === ''){
        temp.emailError = 'Please enter member Email Id'
        validate = false
      }
      setAddTeamError(temp)
      return validate
    }
  }

  const handleAddNewTeamMember = () => {
    let validate = isTeamMemberRole()
    if(isNewMemberValidate('add')){
      let temp = teamMembers
      temp.push(teamMember)
      setTeamMembers(temp)
      setTeamMember({
        email: '',
        role: ''
      })
      setSelectedOption(null)
      setIsRoleAdded(true)
    }
  }

  const handleDeleteMember = (index) => {
      // Create a copy of the teamMembers array
      const updatedTeamMembers = [...teamMembers];
      // Remove the member at the specified index
      updatedTeamMembers.splice(index, 1);
      // Update the state with the new teamMembers array
      setTeamMembers(updatedTeamMembers);
  };

  const handleNext = () => {
    
    // "projectStatus": {"name": "Inquiry", "color": "#3498db", "position": 1, "isSelected": false},
    // "projectStage": {"name": "Inquiry", "color": "#3498db", "position": 1, "isSelected": false},
    // "leadSource": {"name": "Inquiry", "color": "#3498db", "position": 1, "isSelected": false},

    if(teamMember.email !== '' && teamMember.role !== ''){
      handleAddNewTeamMember()
    }
    let validate = handleDropDownError()
    let data = {...projectInfo}
    if(teamMembers.length > 0){
      data.members = [...teamMembers]
      setProjectInfo(data)
    }
    if(validateForm(projectInfo,'projectInfo') && validate && isNewMemberValidate('next')){
      let businessDetail = JSON.parse(localStorage.getItem('businessInfo'))
      const projectPhases = JSON.parse(localStorage.getItem('projectPhases'))
      // businessDetail['fileFolder'] = projectPhases.file_folder
      // businessDetail['library'] = projectPhases.library
      // businessDetail['clients'] = [{'clientName' : data.clientName}]
      // data['leadSource'] = {name: "Inquiry", color: ""}

      businessDetail['index'] = 2
      let _data = {...businessDetail}
      data['clients'] = [{'clientName' : data.clientName}]
      _data['projects'] = [{...data}]
      let saveUSerData = JSON.parse(localStorage.getItem('authUserData'))
      try {
        onboardingBusinessService(_data).then(res => {
          if(res.statusCode === 200){
            if(res.data.status === 1){
              localStorage.setItem('_formdata',JSON.stringify(_data))
              localStorage.setItem('peojectInfo', JSON.stringify(data))
              // saveUSerData.role = 
              nextStep()
            }

            if(res.data.status === 0){
              showToast(`${res.data.error.message}`, "error");
              // setIsLoading(false)
            }
          }

          if(res?.statusCode === 0){
            showToast(res?.errors, "error");
            // setIsLoading(false)
          }
        })
      } catch (error) {
        
      }
    }
  }
  return (
    <div>
      <div className='_op_heading_section_'>
          <h1 className='_op_heading_'>Tell us how you run your projects currently</h1>
          <p className='_op_heading_desc_'>We will get the project structure set up the way you like it!</p>
      </div>
      <div className='_seperator_'></div>
        <div className='_op_form_wrapper_'>
          <div className="row px-5">
              <div className='col-lg-6'>
                  <div className="_label_ ">Project Name<span className="_imp_">*</span></div>
                  <div className="position-relative">
                      <input className={(errors?.projectName || runTimeErrors?.projectName) ? 
                          '__op_input_error_' : 
                          '_op_input_'} 
                          type="text" 
                          value={projectInfo.projectName}
                          placeholder="Enter Project Name"
                          maxLength={50}
                          onChange={(e) => handleOnChange('projectName',e)}
                          />
                      <div className='_error_ _flex_error_'>{errors?.projectName || runTimeErrors?.projectName}</div>
                  </div>
              </div>
              <div className='col-lg-6'>
                  <div>
                    <div className="_label_">Project Status<span className="_optional_"> (optional)</span></div>
                    <CustomSelect 
                    options={projectStatus}
                    name='projectStatus'
                    placeholder='Select Project Status'
                    initialValue={projectStatusInitialVal}
                    onSelect={handleSelect} />
                  </div>
              </div>
          </div>


          <div className="row px-5 mt-4">
              <div className='col-lg-6'>
                  <div className="_label_ ">Client Name<span className="_imp_">*</span></div>
                  <div className="position-relative">
                      <input className={(errors?.clientName || runTimeErrors?.clientName) ? 
                          '__op_input_error_' : 
                          '_op_input_'} 
                          type="text" 
                          value={projectInfo.clientName}
                          placeholder="Enter Client Name"
                          maxLength={50}
                          onChange={(e) => handleOnChange('clientName',e)}
                          />
                      <div className='_error_ _flex_error_'>{errors?.clientName || runTimeErrors?.clientName}</div>
                  </div>
              </div>

              <div className='col-lg-6'>
                  <div className="position-relative">
                    <div className="_label_">Project Phase<span className="_imp_">*</span></div>
                    <CustomSelect 
                    // options={passedPhases ? passedPhases : projectStage} 
                    options={projectStage}
                    name='projectStage'
                    placeholder='Select Project Phase'
                    initialValue={projectStageInitialVal}
                    onSelect={handleSelect} />
                    {isDropDownError.projectStage ? <div className='_error_ _flex_error_'>Project phase is required</div> : null} 
                  </div>
              </div>
          </div>

          <div className='_invite_'>
            <h3>Let’s invite your project team members </h3>
          </div>

            {teamMembers.length > 0 &&  teamMembers.map((item, index) => 
          
              <div className="members-list _field_gap_" key={index}>
                  <div>
                      <div className="_label_ ">Email</div>
                      <input className='_op_input_' type="text" value={item.email} disabled/>
                  </div>

                  <div className='' style={{width:'40%'}}>
                        <div className="_label_">Role</div>
                            <div className='added-role-wrapper'>
                                <input className='_role_input_' type="text" value={item?.role} disabled/>
                                <div className='_pointer_' onClick={() => handleDeleteMember(index)}>
                                    <img src={DeleteIcon} alt='delete'/>
                                </div>
                            </div>
                  </div>
              </div>
            )}

            <div className="row px-5 mt-4">
            <div className='col-lg-6'>
                  <div className="_label_ ">Email</div>
                  <div className="position-relative">
                      <input className={addTeamError?.emailError ? 
                          '__op_input_error_' : 
                          '_op_input_'} 
                          type="text" 
                          value={teamMember.email}
                          placeholder="Enter Email ID"
                          maxLength={50}
                          onChange={(e) => handleTeamMemberEmail('email',e)}
                          />
                      <div className='_error_ _flex_error_'>{addTeamError?.emailError}</div>
                  </div>
              </div>

              <div className='col-lg-6'>
                  <div className="position-relative">
                    <div div className="_label_">Role</div>
                    <div className='added-role-wrapper'>
                        <CustomSelect
                        width='mini'
                        type='role'
                        options={role} 
                        name='role'
                        placeholder='Select Role'
                        _isroleAdded = {isRoleAdded}
                        refreshPlaceHolder={() => setIsRoleAdded(false)}
                        onSelect={handleSelect} />
                        <div className='_pointer_' >
                            <img src={AddIcon} alt='delete' onClick={handleAddNewTeamMember}/>
                        </div>
                    </div>
                   <div className='_error_ _flex_error_'>{addTeamError.roleError }</div> 
                  </div>
              </div>
          </div>
          
        </div>
      <div className='_seperator_'></div>
      <div className='_op_bottom_wrapper_'>
        <button className='_btn_transparent_' onClick={prevStep}><span className='_left_btn_icon_'><img src={LeftArrowIcon}/></span><span>Back</span></button>
        <button className='_btn_normal_' onClick={handleNext}><span>Next</span><span className='_right_btn_icon_'><img src={RightArrowIcon}/></span></button>
      </div>
    </div>
  )
}

export default ProjectInfo