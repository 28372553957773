import { format, parseISO } from "date-fns";
import React from "react";
import DatePicker from "react-datepicker";
import docIcon from "../../../assets/logos/doc-svgrepo-com.svg";
import pdfIcon from "../../../assets/logos/pdf-svgrepo-com.svg";
import rupeesIcon from "../../../assets/logos/Rupee-Symbol-Black.svg";
import CommonButton from "../../../components/common/CommonButton";
import { _contractStatus } from "../../../utils/constant";

const dateRender = (date) => {
  try {
    return format(parseISO(date?.replace("Z", "")), "MMMM dd, yyyy");
  } catch (error) {
    return date;
  }
};

const renderLinks = (url, index) => {
  const getFileExtension = (url) => {
    const lastDotIndex = url.lastIndexOf(".");
    if (lastDotIndex === -1) return null;
    return url.substring(lastDotIndex + 1).split(/[?#]/)[0];
  };
  const fileExtension = getFileExtension(url);

  let previewContent;

  if (
    fileExtension === "jpg" ||
    fileExtension === "jpeg" ||
    fileExtension === "png" ||
    fileExtension === "svg"
  ) {
    previewContent = <img className="thumb" src={url} alt="Preview" />;
  } else if (fileExtension === "pdf") {
    previewContent = <img className="thumb" src={pdfIcon} alt="PDF Icon" />;
  } else if (fileExtension === "doc" || fileExtension === "docx") {
    previewContent = <img className="thumb" src={docIcon} alt="DOC Icon" />;
  } else {
    previewContent = <div className="thumb">File</div>;
  }

  return (
    <li key={index}>
      <div className="img-wrap">{previewContent}</div>
    </li>
  );
};

const ViewExpense = ({ handleCancel, viewExpenseData }) => (
  <>
    <div className="contract_main">
      <div className="contract_left" style={{ width: "100%" }}>
        <div
          className="contract_left_template"
          style={{
            width: "100%",
            maxHeight: "75vh",
            overflowY: "auto",
          }}
        >
          <h2 className="dashboard_heading">
            View Expense
            <span
              style={{
                backgroundColor:
                  _contractStatus[viewExpenseData?.reviewStatus]?.color,
                marginLeft: "10px",
                padding: "6px",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#ffffff",
                fontWeight: 400,
                borderRadius: "4px",
                maxWidth: "120px",
              }}
            >
              {_contractStatus[viewExpenseData?.reviewStatus]?.value}
            </span>
          </h2>
          <ul className="modal_main_ul">
            <li className="modal_main_li" style={{ width: "43%" }}>
              <label className="font_14">Project</label>
              <div className="_editable_">
                {viewExpenseData && viewExpenseData.projectName && (
                  <input
                    type="text"
                    placeholder="Project"
                    value={viewExpenseData.projectName}
                    disabled
                  />
                )}
              </div>
            </li>
            <li className="modal_main_li" style={{ width: "43%" }}>
              <label className="font_14">Expense Date</label>
              {viewExpenseData && viewExpenseData.expenseDate && (
                <DatePicker
                  selected={dateRender(viewExpenseData?.expenseDate)}
                  placeholderText="MMMM DD, YYYY"
                  dateFormat="MMMM dd, yyyy"
                  disabled
                />
              )}
            </li>
          </ul>
          <ul className="modal_main_ul" style={{ marginTop: "0" }}>
            <li className="modal_main_li" style={{ width: "43%" }}>
              <label className="font_14">Expense Name</label>
              <div className="_editable_">
                {viewExpenseData && viewExpenseData.title && (
                  <input
                    type="text"
                    placeholder="Expense Name"
                    value={viewExpenseData.title}
                    disabled
                  />
                )}
              </div>
            </li>
            <li className="modal_main_li" style={{ width: "43%" }}>
              <label className="font_14">Amount</label>
              <div className="dealroom_detail_upper_left">
                {viewExpenseData && viewExpenseData.amount && (
                  <div className="dashboard_upper_input">
                    <input
                      type="text"
                      placeholder="Enter Amount"
                      style={{
                        fontSize: "14px",
                        outline: "none !important",
                        width: "100%",
                        padding: "15px 15px 15px 30px",
                        border: "1px solid #e7e8ea",
                        borderRadius: "8px",
                        color: "black",
                      }}
                      value={viewExpenseData.amount || "0"}
                      disabled
                    />
                    <img
                      src={rupeesIcon}
                      alt="rupeesIcon"
                      width={10}
                      height={10}
                      style={{ top: "21px" }}
                    />
                  </div>
                )}
              </div>
            </li>
          </ul>
          <ul className="modal_main_ul" style={{ marginTop: "0" }}>
            <li className="modal_main_li" style={{ width: "43%" }}>
              <label className="font_14">Category</label>
              <div className="_editable_">
                {viewExpenseData && viewExpenseData.category && (
                  <input
                    type="text"
                    placeholder="Category"
                    value={viewExpenseData.category}
                    disabled
                  />
                )}
              </div>
            </li>
            <li className="modal_main_li" style={{ width: "43%" }}>
              <label className="font_14">Subcategory Name</label>
              <div className="_editable_">
                {viewExpenseData && viewExpenseData.subCategory && (
                  <input
                    type="text"
                    placeholder="Sub Category"
                    value={viewExpenseData.subCategory}
                    disabled
                  />
                )}
              </div>
            </li>
          </ul>
          <ul className="modal_main_ul" style={{ marginTop: "0" }}>
            <li className="modal_main_li" style={{ width: "43%" }}>
              <label className="font_14">Receipt Image</label>
              <div className="_editable_">
                <div
                  className="upload-logo-wrapper"
                  style={{ width: "100%", justifyContent: "flex-start" }}
                >
                  <div className="uploaded-logo" style={{ width: "100%" }}>
                    {viewExpenseData &&
                    viewExpenseData.documents &&
                    viewExpenseData.documents.length === 0
                      ? ""
                      : viewExpenseData &&
                        viewExpenseData.documents &&
                        viewExpenseData.documents.length === 1
                      ? "1 file Uploaded"
                      : `${viewExpenseData.documents.length} files Uploaded`}
                  </div>
                </div>
              </div>
              {viewExpenseData &&
              viewExpenseData.documents &&
              viewExpenseData.documents.length > 0 ? (
                <ul
                  className="thumb-Images"
                  id="imgList"
                  style={{ marginTop: 10 }}
                >
                  {viewExpenseData.documents.map((file, index) =>
                    renderLinks(file, index)
                  )}
                </ul>
              ) : null}
            </li>
          </ul>
          <ul className="modal_main_ul" style={{ marginTop: 0 }}>
            <li className="modal_main_li" style={{ width: "100%" }}>
              <label className="font_14">Description (in 200 words)</label>
              <div className="_editable_">
                {viewExpenseData && viewExpenseData.description && (
                  <textarea
                    rows="4"
                    cols="50"
                    className="_text_area_"
                    placeholder="Write the details of the Expense"
                    value={viewExpenseData.description}
                    maxLength={200}
                    style={{ height: "135px" }}
                    disabled
                  />
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="contract_main_button">
      <CommonButton
        label="Back"
        fontSize="14px"
        fontWeight="700"
        className="dash_common_btn white_bg_btn"
        icon={false}
        onClick={handleCancel}
      />
    </div>
  </>
);

export default ViewExpense;
