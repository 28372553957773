import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import CommonButton from "../../../components/common/CommonButton";
import { getChangeRequestList } from "../../../services";
import { _contractStatus, contractStatus } from "../../../utils/constant";
import searchIcon from "./../../../assets/dashboard/Search.svg";
import changeIcon from "./../../../assets/dashboard/change_blue.svg";
import filterIcon from "./../../../assets/dashboard/filter_icon.svg";
import plusIcon from "./../../../assets/dashboard/plus.svg";
import ChangeRequestFilter from "./../../../modals/ChangeRequestFilter";
import AddChangeRequest from "./AddChangeRequest";
import threeDot from './../../../assets/logos/three-dot.svg'
import ActionModal from "../../../modals/ActionModal";

const formatDate = (comingDate) => {
  // Create a new Date object from the string
  const date = new Date(comingDate);

  // Define options for formatting the date
  const options = { day: "2-digit", month: "long", year: "numeric" };

  // Format the date
  const formattedDate = date.toLocaleDateString("en-GB", options);

  // Split the formatted date
  const [day, month, year] = formattedDate.split(" ");

  // Format the desired output
  const finalFormattedDate = `${month} ${day}, ${year}`;

  return finalFormattedDate;
};

const ChangeRequest = ({ activeTab }) => {
  const [showChangeRequestModal, setShowChangeRequestModal] = useState(false);
  const [showChangeRequestFilter, setShowChangeRequestFilter] = useState(false);
  const [addChangeRequest, setAddChangeRequest] = useState(false);
  const [notShowAgain, setNotShowAgain] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [filterStatus, setFilterStatus] = useState([
    ...contractStatus.map((item) => ({ ...item, isChecked: false })),
  ]);
  const [changeRequestList, setChangeRequestList] = useState([]);
  const [changeRequestListFilter, setChangeRequestListFilter] = useState([]);
  const [staticDetails, setStaticDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const debounceTimeout = useRef(null);

  const handleChangeRequestModalClose = () => setShowChangeRequestModal(false);
  const handleChangeRequestFilterClick = () => setShowChangeRequestFilter(true);
  const handleAddChangeRequestClick = () => setAddChangeRequest(true);
  const handleAddChangeRequestClose = () => setAddChangeRequest(false);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false)
  const [dealroomId, setDealRoomId] = useState('')
  const [actionTitle, setActionTitle] = useState('')
  const [currentPdf, setCurrentPdf] = useState('')
  const handleNotShowAgain = (e) => {
    localStorage.setItem("showChangeRequestModal", e.target.checked);
    setNotShowAgain(e.target.checked);
  };
  const handleSearchTitle = (e) => {
    const value = e.target.value;
    setSearchTitle(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      applyFilters(value, filterStatus);
    }, 500);
  };

  const applyFilters = (searchQuery, filters) => {
    const checkedFilters = filters
      .filter((filter) => filter.isChecked)
      .map((filter) => filter.id);

    let filteredList = changeRequestList;

    if (checkedFilters.length > 0) {
      filteredList = filteredList.filter((item) =>
        checkedFilters.includes(item.status)
      );
    }

    if (searchQuery) {
      filteredList = filteredList.filter((item) =>
        item.documentTitle.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setChangeRequestListFilter(filteredList || []);
  };

  useEffect(() => {
    applyFilters(searchTitle, filterStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  useEffect(() => {
    if (activeTab === "Change Requests") {
      const showChangeRequestModal =
        localStorage.getItem("showChangeRequestModal") || false;
      setAddChangeRequest(false);
      if (showChangeRequestModal) {
        setShowChangeRequestModal(false);
      } else {
        setShowChangeRequestModal(true);
      }
      const projectDetail = localStorage.getItem("projectDetail")
        ? JSON.parse(localStorage.getItem("projectDetail"))
        : null;
      const authUserData = localStorage.getItem("authUserData")
        ? JSON.parse(localStorage.getItem("authUserData"))
        : null;
      setStaticDetails(projectDetail);
      setUserDetails(authUserData);
      fetchChangeRequestList();
    }
  }, [activeTab]);

  const fetchChangeRequestList = async (id) => {
    const projectDetail = localStorage.getItem("projectDetail")
      ? JSON.parse(localStorage.getItem("projectDetail"))
      : null;
    try {
      const response = await getChangeRequestList(projectDetail?.id);
      setChangeRequestList(response.data?.responseData?.data);
      setChangeRequestListFilter(response.data?.responseData?.data);
    } catch (error) {
      console.error("Error fetching change request list:", error);
    }
  };

  if (addChangeRequest) {
    return (
      <AddChangeRequest
        handleCancel={handleAddChangeRequestClose}
        staticDetails={staticDetails}
        userDetails={userDetails}
        refreshChangeRqstList={fetchChangeRequestList}
      />
    );
  }



  const actionModalHandler = item => {
    if(item.id && item.documentTitle && item?.pdfRecord?.rawpdfFile){
      setIsActionModalOpen(true);
      setDealRoomId(item.id)
      setActionTitle(item.documentTitle)
      setCurrentPdf(item.pdfRecord.rawpdfFile)
    }
  }


  const actionHandler = (id, type) => {
    // console.log(type, 'type-----')
    // if(type === 2){
    //   try {
    //     deleteDealRoomService(id).then(res => {
    //       if(res.data.status === 1){
    //         getSigners()
    //       }
    //     })
    //   } catch (error) {
        
    //   }
    // }
    // if(type === 1){
    //   let data = {
    //     dealroomId:id,
    //     status:5
    //   }
    //   try {
    //     updateDealRoomService(data).then(res => {
    //       if(res.data.status === 1){
    //         getSigners()
    //       }
    //     })
    //   } catch (error) {
        
    //   }
    // }
  }

  const handleCloseActionModal = () => {
    setDealRoomId('')
    setActionTitle('')
    setIsActionModalOpen(false)
  }

  return (
    <>
      <div className="dealroom_main_div">
        <div className="dealroom_detail_upper _flex_row_spacebtw_">
          <div className="dealroom_detail_upper_left _flex_gap_30">
            <div className="dashboard_upper_input ">
              <input
                type="text"
                placeholder="Search"
                value={searchTitle}
                onChange={handleSearchTitle}
              />
              <img src={searchIcon} alt="searchIcon" />
            </div>
            <CommonButton
              label="Filter"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn white_bg_btn"
              icon={filterIcon}
              onClick={handleChangeRequestFilterClick}
            />
            {showChangeRequestFilter && (
              <ChangeRequestFilter
                handleFilterClose={() => setShowChangeRequestFilter(false)}
                filterBy={filterStatus}
                setFilterBy={setFilterStatus}
              />
            )}
          </div>
          <div className="dealroom_detail_upper_right _flex_gap_30">
            <CommonButton
              label="Change Request"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn"
              icon={plusIcon}
              onClick={handleAddChangeRequestClick}
            />
          </div>
        </div>
        <h2 className="dashboard_heading">Change Request</h2>
        <div className="table-scrolling" style={{ marginTop: "40px" }}>
          <table
            className="project_table dealroom_table"
            style={{ marginTop: 0 }}
          >
            <thead style={{ position: "sticky", top: -1, zIndex: 1 }}>
              <tr>
                <th>S.No.</th>
                <th>Created</th>
                <th>Title</th>
                <th>Status</th>
                <th>Sent By</th>
                <th>Requested By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {changeRequestListFilter?.length > 0 ? (
                changeRequestListFilter.map((changeRequest, i) => {
                  const statusObject = contractStatus.find(
                    (item) => item.id === changeRequest.status
                  );
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{formatDate(new Date(changeRequest.created_at).toISOString().split("T")[0])}</td>
                      <td className="dealroom_table_title"><span onClick={() => window.open(`/view-template?createRequestId=${changeRequest.id}`, "_blank")}>{changeRequest?.documentTitle}</span></td>
                      
                      <td>
                        {console.log(changeRequest, 'changeRequestchangeRequest')}
                        <p className="phases" style={{backgroundColor: _contractStatus[`${changeRequest.status}`].color}}>{_contractStatus[`${changeRequest.status}`].value}</p>
                      </td>
                   
               
                      <td style={{textAlign: 'start'}}>
                        <div style={{display:'flex', justifyContent:'center',flexDirection:'column'}}>
                          {changeRequest.signers.map((signer, idx) => (
                            <div key={idx}>
                              <span style={{color : _contractStatus[signer.status]?.color}}>{_contractStatus[signer.status]?.value}</span> : {signer.name}
                            </div>
                          ))}
                        </div>
                      </td>
                      <td>{userDetails.firstName} {userDetails.lastName}</td>
                      <td style={{cursor:'pointer'}}><span onClick={() => actionModalHandler(changeRequest)}><img src={threeDot} alt="three dot"/></span></td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6} style={{ textAlign: "center" }}>
                    No Change Request
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        show={showChangeRequestModal}
        onHide={handleChangeRequestModalClose}
        className="dealroom_intital_modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={changeIcon} alt="changeIcon" />
          <h3>Change Request</h3>
          <p className="font_16">
            We understand change is inevitable. Document and Secure approvals
            for any changes requested by you or your client.
          </p>
          <div
            className="dealroom_intital_modal_div"
            style={{ justifyContent: "center" }}
          >
            <CommonButton
              label="Create New Change Request"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn"
              icon={false}
              width={"52%"}
              onClick={handleAddChangeRequestClick}
            />
          </div>
          <h6 className="font_14">
            <input
              type="checkbox"
              value={notShowAgain}
              onClick={handleNotShowAgain}
            />
            Don’t show this again
          </h6>
        </Modal.Body>
      </Modal>


      {isActionModalOpen && <ActionModal
          isOpen={isActionModalOpen}
          title={actionTitle}
          isCRView={true}
          onClose={handleCloseActionModal}
          onSave={actionHandler}
          dealRoomID={dealroomId}
          pdf={currentPdf}
      />}




    </>
  );
};

export default ChangeRequest;
