import axios from "axios";
import ApiInstance from "./interceptor";
import api from "../config/api";
import settings from "../config/settings";

export const userLoginService = params => ApiInstance.post(`${api.AUTH_LOGIN}`, params);
export const authEmailService = params => ApiInstance.post(`${api.AUTH_EMAIL}`, params);
export const otpService = params => ApiInstance.post(`${api.SEND_OTP}`, params);
export const otpVerificationService = params => ApiInstance.post(`${api.VERIFY_OTP}?referToken=${params?.referToken ? params?.referToken : null}`, params);
// export const otpVerificationService = params => ApiInstance.post(`${api.VERIFY_OTP}?referToken=${referToken}`, params);
export const resetPassService = params => ApiInstance.patch(`${api.RESET_PASS}`, params);
export const onboardingBusinessService = params => ApiInstance.put(`${api.ONBOARDING_BUSINESS}`, params);
export const getBusinessProjectsService = params => ApiInstance.get(`${api.BUSINESS_PROJECTS}?businessId=${params}`);
export const getRecentActivitiesService = query => ApiInstance.get(`${api.RECENT_ACTIVITY}` + query);
export const addProjectService = params => ApiInstance.post(`${api.ADD_PROJECT}`,params);
export const getAllProjectsService = params => ApiInstance.get(`${api.ALL_PROJECTS}`);
export const getProjectByBusinessService = params => ApiInstance.get(`${api.PROJECT_BY_BUSSINESS}?businessId=${params}`);
export const updateProjectService = params => ApiInstance.put(`${api.UPDATE_PROJECT}?projectId=${params.id}`, params);
export const getAllClientsService = params => ApiInstance.get(`${api.GET_ALL_CLIENTS}`);
export const getFavProjectService = params => ApiInstance.get(`${api.FAV_PROJECTS}?businessId=${params}`);
export const addPhaseService = params => ApiInstance.post(`${api.ADD_PHASE}`,params);
export const getPhaseService = params => ApiInstance.get(`${api.GET_PHASE}?businessId=${params}`);
export const updatePhaseService = params => ApiInstance.post(`${api.UPDATE_PHASE}`,params);

export const joinWaitListService = params => ApiInstance.post(`${api.JOIN_WAITLIST}`, params);

export const getStatusService = params => ApiInstance.get(`${api.GET_STATUS}?businessId=${params}`);
export const addStatusService = params => ApiInstance.put(`${api.ADD_STATUS}`,params);

export const getLeadSourceService = params => ApiInstance.get(`${api.GET_LEAD_SOURCE}?businessId=${params}`);
export const addLeadSourceService = params => ApiInstance.put(`${api.ADD_LEAD_SOURCE}`,params);

export const globalSearchService = params => ApiInstance.get(`${api.BUSINESS_SEARCH}?query=${params}`);
export const getClientbyProjectIdService = params => ApiInstance.get(`${api.GET_CLIENT_BY_PROJECTS}?projectId=${params}`);
export const getClientbyBusinessIdService = params => ApiInstance.get(`${api.GET_CLIENT_BY_BUSINESS_ID}?businessId=${params}`);

export const getMembersByBusinessIdService = params => ApiInstance.get(`${api.GET_MEMBERS_BY_BUSINESS}?businessId=${params}`);

export const updateClientService = (projectId, clientId, payLoad) => ApiInstance.put(`${api.UPDATE_CLIENT}?projectId=${projectId}&clientId=${clientId}`, payLoad);

export const addCRServive = params => ApiInstance.post(`${api.CR_ADD}`, params);

export const addSignersService = params => ApiInstance.post(`${api.ADD_SIGNERS}`, params);
export const getSignersServices = params => ApiInstance.get(`${api.GET_SIGNERS}?projectId=${params}`);
export const updateDealRoomService = params => ApiInstance.put(`${api.UPDATE_DEALROOM}?dealroomId=${params.dealroomId}`, params);
export const deleteDealRoomService = params => ApiInstance.delete(`${api.DELETE_DEALROOM}/${params}`);

export const retriveSignersServices = params => ApiInstance.get(`${api.RETRIEVE_SIGNER}/${params}`);
export const retriveSignersCRServices = params => ApiInstance.get(`${api.RETRIEVE_SIGNER_CR}/${params}`);
export const retrivePdfServices = params => ApiInstance.get(`${api.RETRIEVE_PDF}/${params}`);


export const getProjectTemplate = () => ApiInstance.get(`${api.GET_PROJECT_TEMPLATES}?projectId=`);
export const getChangeRequestList = params => ApiInstance.get(`${api.CR_LIST}?projectId=${params}`);

export const getProjectManagementList = params => ApiInstance.get(`${api.PM_LIST}?projectId=${params}`);
export const postProjectManagement = params => ApiInstance.post(`${api.PM_ADD}`, params);
export const updateProjectManagement = (id, params) => ApiInstance.put(`${api.PM_UPDATE}?id=${id}`, params);
export const deleteProjectManagement = params => ApiInstance.delete(`${api.PM_DELETE}/${params}`);

export const getExpensesList = params => ApiInstance.get(`${api.EXP_LIST}?projectId=${params}`);
export const getExpenseCategory = () => ApiInstance.get(`${api.EXP_GET_CATEGORY}`);
export const postCreateExpense = (params, token) => {
  axios.defaults.headers.common["accept"] = "application/json";
  axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
  axios.defaults.headers.common["token"] = `${token}`;
  axios.defaults.headers.common["Authorization"] = `${settings.api.AUTH}`;
  return axios.post(api.EXP_CREATE, params);
};
export const updateExpense = (id, params, token) => {
  axios.defaults.headers.common["accept"] = "application/json";
  axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
  axios.defaults.headers.common["token"] = `${token}`;
  axios.defaults.headers.common["Authorization"] = `${settings.api.AUTH}`;
  return axios.put(`${api.EXP_UPDATE}?expenseId=${id}`, params);
};
export const deleteExpenses = params => ApiInstance.delete(`${api.EXP_DELETE}/${params}`);
export const remindExpenses = params => ApiInstance.post(`${api.EXP_REMIND}`, params);

export const postHtmlTemplate = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.POST_HTML_TEMPLATES, params)
}

export const postUploadDoc = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.POST_UPLOAD_DOC, params)
}

export const sendToPDFService = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.SEND_TO_PDF, params)
}

export const sendToPDFForCrService = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.SEND_TO_PDF_FOR_CR, params)
}

export const updatePDFService = (id, params) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    // axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(`${api.UPDATE_PDF}?sendToPdfId=${id}`, params)
}


export const updatePDFServiceForCr = (id, params) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    // axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(`${api.UPDATE_PDF_FOR_CR}?createRequestId=${id}`, params)
}

export const _updateDealRoomService = (token, id, params) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(`${api.UPDATE_DEALROOM}?dealroomId=${id}`, params)
}

export const _updateDealRoomServiceCr = (token, id, params) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(`${api.UPDATE_DEALROOM_TO_CR}?createRequestId=${id}`, params);
}

// export const updateDealRoomService = params => ApiInstance.put(`${api.UPDATE_DEALROOM}?dealroomId=${params.dealroomId}`, params);


export const crUploadPdf = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.CR_UPLOAD_PDF, params)
}


// export const convertHtmltoPdfService = params => ApiInstance.post(`${api.HTML_TO_PDF}`,params);


// export const convertHtmltoPdfService = params => ApiInstance.post(`${api.HTML_TO_PDF}`,params);


export const _getPhase = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_PHASE}?businessId=${params}`)
}

export const _getStatus = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_STATUS}?businessId=${params}`)
}

export const _getSource = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_LEAD_SOURCE}?businessId=${params}`)
}

export const _addPhase = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(`${api.ADD_PHASE}`,params)
}

export const _addStatus = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(`${api.ADD_STATUS}`,params)
}

export const _addSource = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(`${api.ADD_LEAD_SOURCE}`,params)
}



export const uploadLogoService = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.BUSINESS_LOGO, params)
}

export const convertHtmltoPdfService = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.HTML_TO_PDF, params)
}


export const uploadToS3Service = (params) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    return axios.post(api.UPLOAD_TO_S3, params)
}


// export const adminResetPasswordService = (params, token) => {
//     console.log('cheking params in reset password : ',params)
//     axios.defaults.headers.common['accessToken'] = `${token}`;
//     axios.defaults.headers.common['authorization'] = `${settings.api.AUTH}`;
//     return axios.post(api.ADMIN_RESET_PASSWORD, params)
// }


export const getInvoiceList = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_INVOICE_LIST}?projectId=${params}`)
}

export const getInvoiceOverview = (projectid,businessID, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_INVOICE_OVERVIEW}?businessId=${businessID}&projectId=${projectid}`)
}


export const createInvoice = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(`${api.CREATE_INVOICE}`,params)
}


export const sendInvoiceToPDF = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(`${api.INVOICE_TO_PDF}`,params)
}

export const deleteInvoiceService = params => ApiInstance.delete(`${api.DELETE_INVOICE}/${params}`);
export const updateInvoiceService = params => ApiInstance.put(`${api.UPDATE_INVOICE}?invoiceId=${params.invoiceId}`, params);





