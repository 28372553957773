import React, { useState } from 'react'

import plusicon from "../assets/dashboard/pluscircle.svg";
import plus from "../assets/dashboard/plus.svg";
import filter from "../assets/dashboard/filter.svg";
import AddNewProject from "../modals/AddNewProject";
import CommonButton from './common/CommonButton';
import ClientDetails from '../modals/ClientDetails';
import CustomizePipeline from '../modals/CustomizePipeline';
import { useNavigate } from 'react-router-dom';

const PipeLine = ({ _pipelineData , refreshPipline, phase, id, businessId}) => {
  console.log(_pipelineData,'_pipelineData')
  console.log(refreshPipline,'refreshPipline')
  console.log(phase,'phase')
  console.log(id,'id')
  console.log(businessId,'businessId')
  
    const [activeIndex, setActiveIndex] = useState(0);
    const [showProjectForm, setShowProjectForm] = useState(false);
    const [isClientDetailsModalOpen, setClientDetailsModalOpen] = useState(false);
    const [showCustomizePipeline, setShowCustomizePipeline] = useState(false);
    const [items, setItems] = useState(
      phase && phase.map((item, index) => ({
          id: (index + 1).toString(),
          name: item.name,
          color: item.color,
          checked: true
      })) || []
    );
    const navigate = useNavigate()

    const handleShow = () => setShowProjectForm(true);
    const handleHide = () => setShowProjectForm(false);
    const handleCustomizePipelineClick = () => {
      setShowCustomizePipeline(true);
    };
  
    const handleCustomizePiplelineSubmit = (customizeState) => {
      console.log('inside pipeline',customizeState)
      setItems(customizeState)
      setShowCustomizePipeline(false);
    };
    const handleCustomizeClose =()=>{
      setShowCustomizePipeline(false);
    }

    // const renderPhases = () => {
    //   const allOccurrenceData = _pipelineData.find(phaseData => phaseData.name === "All");
    //   const allOccurrence = allOccurrenceData ? allOccurrenceData.occurrence.toString().padStart(2, "0") : "00";
    
    //   const phasesArray = new Array(phase.length + 1); // Create an array with length equal to the number of phases plus 1 for "All"
    //   phasesArray[0] = { name: "All", occurrence: allOccurrence };
    
    //   phase.forEach((item) => {
    //     phasesArray[item.position] = _pipelineData.find(phaseData => phaseData.name === item.name) || { name: item.name, occurrence: "00" };
    //   });
    
    //   return phasesArray.map((phase, index) => (
    //     <li className="arrow_scroll_li" key={index}>
    //       <h5>{phase.occurrence.toString().padStart(2, '0')}</h5>
    //       <p>{phase.name}</p>
    //     </li>
    //   ));
    // };

    // const renderPhases = () => {
    //   const allOccurrenceData = _pipelineData.find(
    //     (phaseData) => phaseData.name === "All"
    //   );
    //   const allOccurrence = allOccurrenceData
    //     ? allOccurrenceData.occurrence.toString().padStart(2, "0")
    //     : "00";
  
    //   const phasesArray = new Array(phase.length + 1);
    //   phasesArray[0] = { name: "All", occurrence: allOccurrence, checked: true }; // "All" phase
  
    //   phase.forEach((item, index) => {
    //     const phaseData =
    //       _pipelineData.find((phaseData) => phaseData.name === item.name) || {
    //         name: item.name,
    //         occurrence: "00",
    //       };
    //     phasesArray[index + 1] = { ...phaseData, id: index + 1, checked: items[index].checked };
    //   });
  
    //   return phasesArray
    //     .filter((phase) => phase && phase.checked) // Filter out unchecked phases and null/undefined phases
    //     .map((phase, index) => (
    //       <li className="arrow_scroll_li" key={index}>
    //         <h5>{phase.occurrence.toString().padStart(2, "0")}</h5>
    //         <p>{phase.name}</p>
    //       </li>
    //     ));
    //   };

    const handleItemClick = (index, _currebStageName) => {
      console.log(index,_currebStageName,'inside handleItemClick')
      setActiveIndex(index)
      navigate('/projects',{state : {_index : index, _currenStageName_ : _currebStageName}})
    };

    const renderPhases = () => {
      const allOccurrenceData = _pipelineData.find(
          (phaseData) => phaseData.name === "All Projects"
      );
      const allOccurrence = allOccurrenceData
          ? allOccurrenceData.occurrence.toString().padStart(2, "0")
          : "00";
      if(phase){

        const phasesArray = new Array(phase.length + 1);
        phasesArray[0] = { name: "All Projects", occurrence: allOccurrence, checked: true, color:'' };
  
        phase.forEach((item, index) => {
            const phaseData =
                _pipelineData.find((phaseData) => phaseData.name === item.name) || {
                    name: item.name,
                    occurrence: "00",
                };
            const isChecked = items[index] ? items[index].checked : false;
            const color = item.color
            phasesArray[index + 1] = { ...phaseData, id: index + 1, checked: isChecked, color };
        });
        console.log(phasesArray, 'phasesArray phasesArray phasesArray')
        return phasesArray
            .filter((phase) => phase && phase.checked)
            .map((phase, index) => (
                // <li className="arrow_scroll_li shadow-lg" key={index}>
                <li className={`arrow_scroll_li ${index === activeIndex ? 'active_scroll_li' : ''}`} key={index} onClick={() => handleItemClick(index, phase.name)}>
                    <h5>{phase.occurrence.toString().padStart(2, "0")}</h5>
                    <p style={{color: phase?.color}}>{phase.name}</p>
                </li>
            ));
      }
    };
  return (
    <>
        <div className="dashboard_sub">
          <div className="dashboard_sub_left heading_symbol">
            <h3 className="dashboard_heading">Pipeline</h3>
            <img src={plusicon} className="plusicon" />
          </div>
          <div className="dashboard_sub_right">
            <CommonButton
              label="Customize"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn white_bg_btn"
              icon={filter}
              onClick={handleCustomizePipelineClick}
            />
            {showCustomizePipeline && (
                <CustomizePipeline 
                handleCustomizeClose={handleCustomizeClose} 
                handleCustomizePiplelineSubmit={handleCustomizePiplelineSubmit}
                phase={phase}
                id={id}
                businessId={businessId}
                initialState={items}/>
                
              )}
            <CommonButton
              label="Add New Project"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn"
              icon={plus}
              onClick={handleShow}
            />

            {showProjectForm && 
            <AddNewProject 
            onHide={() => setShowProjectForm(false)}
            isNext={isClientDetailsModalOpen}
            openClientDetailModal={() => setClientDetailsModalOpen(true)}
            isOpen={showProjectForm}/>}

            {isClientDetailsModalOpen && 
            <ClientDetails
            openProject={() => setShowProjectForm(true)} 
            onHide={() => setClientDetailsModalOpen(false)} 
            _refreshPipeLine={() => refreshPipline()}
            isOpen={showProjectForm}/>}
          </div>
        </div>

        <ul className="arrow_scroll_ul">

          {/* {
            phase.length > 0 ?
            phase.map((item, index) => (
              <li className="arrow_scroll_li">
                <h5>{item.occurrence.toString().padStart(2, '0')}</h5>
                <p>{item.name}</p>
              </li>
            )) : ''
          } */}
          {renderPhases()}

          
        </ul>
    </>
  )
}

export default PipeLine
