import React, { useEffect, useState, useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { Box } from './Box';
import { Document, Page, pdfjs } from 'react-pdf';
import { ItemTypes } from './ItemTypes';
import { PDFDocument, rgb } from 'pdf-lib';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { hexToRgbValues } from '../../../utils/helper';

console.log(pdfjs.version, 'pdfjs.version')

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = {
  width: '100%',
  maxHeight: '100vh',
  position: 'relative',
  overflow: 'auto',
};

const getPoint = (pos, pageNumber, type, domSize) => {
  if(type == 'Y'){
    let b = (domSize.h * pageNumber) - (pos + 30)
    let a = domSize.h;
    let c = domSize.ch;
    return (b * c) / a;
  }else{
    return (pos * domSize.cw) / domSize.w;
  }
};

export const Container = ({
  pdfUrl,
  clients,
  setClients,
  showUpdatePdf = false,
  previewHandler,
}) => {
  const [boxes, setBoxes] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [numPages, setNumPages] = useState(null);

  // useEffect(() => {
  //   if (pdfUrl) {
  //     localStorage.setItem('_boxes', JSON.stringify(boxes));
  //     handlePreviewClick();
  //   }
  // }, [pdfUrl]);


  useEffect(() => {
    if (showUpdatePdf) {
      localStorage.setItem('_boxes', JSON.stringify(boxes));
      handlePreviewClick();
    }
  }, [showUpdatePdf]);

  useEffect(() => {
    const fetchPdfWorker = async () => {
      pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
    };

    fetchPdfWorker();
    
    const savedBoxes = JSON.parse(localStorage.getItem('_boxes')) || '';
    if (savedBoxes) {
      setTimeout(() => setBoxes(savedBoxes), 100);
    }
  }, []);

  useEffect(() => {
    const initialBoxes = {};
    const cmMainBox = document.getElementById('cm_main_box');
    const scrollPosition = cmMainBox.scrollTop;

    clients.forEach((client) => {
      const boxId = `${client.id}_${client.signer}`;
      initialBoxes[boxId] = {
        top: boxes[boxId]?.top || scrollPosition + 50,
        left: boxes[boxId]?.left || 50,
        title: client.title,
        width: boxes[boxId]?.width || 120,
        height: boxes[boxId]?.height || 30,
        pageNumber: boxes[boxId]?.pageNumber || 1,
        signer: client.signer,
        emailId: client.emailId,
        bgColor: client.bgColor,
        assigntoName: client.name,
        fieldType: client.fieldType,
        type: client.type,
        name: client.id,
        value: client.title,
      };
    });

    setBoxes(initialBoxes);
  }, [clients]);

  const moveBox = useCallback((id, left, top, pageNumber) => {
    setBoxes((prevBoxes) => ({
      ...prevBoxes,
      [id]: { ...prevBoxes[id], left, top, pageNumber },
    }));
  }, []);

  const resizeBox = (id, width, height) => {
    setBoxes((prevBoxes) => ({
      ...prevBoxes,
      [id]: { ...prevBoxes[id], width, height },
    }));
  };

  const deleteBox = (id) => {
    setBoxes((prevBoxes) => {
      const newBoxes = { ...prevBoxes };
      delete newBoxes[id];
      return newBoxes;
    });

    setClients((prevClients) => prevClients.filter((client) => `${client.id}_${client.signer}` !== id));
  };

  const [, drop] = useDrop(() => ({
    accept: ItemTypes.BOX,
    drop(item, monitor) {
      const delta = monitor.getDifferenceFromInitialOffset();
      if (!delta) return;
  
      const left = Math.round(item.left + delta.x);
      const top = Math.round(item.top + delta.y);
      const boundingRect = monitor.getSourceClientOffset();
  
      const pageNumber = Array.from(document.querySelectorAll('.react-pdf__Page'))
        .findIndex(page => {
          const rect = page.getBoundingClientRect();
          return (
            boundingRect.x >= rect.left &&
            boundingRect.x <= rect.right &&
            boundingRect.y >= rect.top &&
            boundingRect.y <= rect.bottom
          );
        }) + 1 || 1;
  
      moveBox(item.id, left, top, pageNumber);
    },
  }), [moveBox]);
  

  const handleDragStart = () => setIsDragging(true);
  const handleDragEnd = () => setIsDragging(false);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const handlePreviewClick = async () => {
    try {
      const existingPdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const pages = pdfDoc.getPages();

      const pageHeights = pages.map(page => page.getHeight());
      const cumulativeHeights = pageHeights.reduce((acc, height) => {
        const lastHeight = acc.length > 0 ? acc[acc.length - 1] : 0;
        acc.push(lastHeight + height);
        return acc;
      }, []);

      const schema = {};
      const magicField = [];

      Object.keys(boxes).forEach((key) => {
        const { left, top, title, width, height, pageNumber, emailId, signer, bgColor, assigntoName, fieldType, type, name, value } = boxes[key];

        if (pageNumber > 0 && pageNumber <= pages.length) {
          const page = pages[pageNumber - 1];
          const pageHeight = page.getHeight();
          const pageWidth = page.getWidth();
          const adjustedY = pageHeight - (top - (cumulativeHeights[pageNumber - 2] || 0)) - height;
          const [r, g, b] = hexToRgbValues(bgColor);

          let innerPageH =  document.querySelector(`.page_id_${pageNumber}`);
          let domPageSize = {
            w: innerPageH.clientWidth,
            h: innerPageH.clientHeight,
            cw: page.getWidth(),
            ch: page.getHeight()
          }

          page.drawRectangle({
            x: getPoint(left, pageNumber, 'X', domPageSize),
            y: getPoint(top, pageNumber, 'Y', domPageSize),
            width,
            height,
            color: rgb(r, g, b),
            opacity: 0.75,
          });

          page.drawText(title, {
            x: getPoint(left, pageNumber, 'X', domPageSize) + 5,
            y: getPoint(top, pageNumber, 'Y', domPageSize) + 15,
            size: 12,
            color: rgb(0, 0, 0),
          });

          const xPercent = (left / pageWidth) * 100;
          const yPercent = (adjustedY / pageHeight) * 100;

          schema[signer] = schema[signer] || {};
          schema[signer][title] = {
            x: left,
            y: adjustedY,
            cm_x: left,
            cm_y: top,
            domPageSize,
            width,
            height,
            pageHeight,
            pageNumber,
            color: rgb(r, g, b),
            borderColor: rgb(r, g, b),
            opacity: 0.75,
            title,
            emailId,
          };

          magicField.push({
            name,
            value,
            type,
            fieldType,
            x: left,
            y: adjustedY,
            cm_x: left,
            cm_y: top,
            domPageSize,
            width: 150,
            height: 50,
            isSign: false,
            pageHeight,
            pageWidth,
            xPercent,
            yPercent,
            pageNumber,
            assignTo: { emailId, name: assigntoName, status: 6 },
          });
        }
      });

      const pdfBytes = await pdfDoc.save();
      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
      const pdfWithBoxesUrl = URL.createObjectURL(pdfBlob);

      let finalSchema = JSON.parse(localStorage.getItem('crSchema'));
      finalSchema = { ...finalSchema, pdfUrl: pdfWithBoxesUrl, magicField: [...magicField] };
      localStorage.setItem('crSchema', JSON.stringify(finalSchema));
      previewHandler(pdfWithBoxesUrl);
    } catch (error) {
      console.error('Error generating preview:', error);
    }
  };

  return (
    <div id="cm_main_box" style={styles} ref={drop}></div>
  );
};
