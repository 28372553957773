import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import ArrowBottomIcon from "../../assets/logos/arrow-bottom.svg";
import { useSelector } from "react-redux";
import { availableColors, projectStatus } from "../../utils/constant";

const CustomSelect = ({
  options,
  onSelect,
  name,
  initialValue,
  placeholder,
  _isroleAdded,
  refreshPlaceHolder,
  width = null,
  isUpdate = false,
  className,
  type,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [customRole, setCustomRole] = useState(""); // State for custom role input
  const [isOther, setIsOther] = useState(false);
  const selectRef = useRef(null);
  const inputRef = useRef(null);
  const optionsRef = useRef(null);
  const [currentInputColor, setCurrentInputColor] = useState("#000");
  const status = useSelector((state) => state?.status?.status?.status);
  useEffect(() => {
    if (initialValue !== null) {
      setSelectedOption(initialValue);
      if (isUpdate === true) {
        // onSelect(initialValue, name);
        setCurrentInputColor(initialValue?.color);
      } else {
        onSelect(initialValue, name);
        setCurrentInputColor(
          initialValue?.color ? initialValue?.color : "#000"
        );
      }
    }
  }, [initialValue]);
  

  useEffect(() => {
    if (_isroleAdded) {
      setSelectedOption(null);
      refreshPlaceHolder();
    }
  }, [_isroleAdded]);

  useEffect(() => {
    if (isOther && inputRef.current) {
      optionsRef.current.scrollTop = optionsRef.current.scrollHeight; // Scroll to bottom
      inputRef.current.focus(); // Focus the input
    }
  }, [isOther]);

  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectOption = (option) => {
    if (disabled) return;

    if (option.value !== "Other") {
      setIsOther(false);
      setSelectedOption(option);
      setIsOpen(false);
      onSelect(option, name);
      setCurrentInputColor(option.color);
    } else {
      setIsOther(true);
      setCurrentInputColor("#000");
    }
  };

  const handleFocus = () => {
    if (!disabled) setIsFocused(true);
  };

  const handleBlur = () => {
    if (!disabled) setIsFocused(false);
  };

  const isUniqueOption = (value) => {
    return !options.some(
      (option) => option.value.toLowerCase() === value.toLowerCase()
    );
  };

  const handleCustomRoleChange = (e) => {
    const value = e.target.value.replace(/^\w/, (c) => c.toUpperCase());
    setCustomRole(value);
    if (isUpdate) {
    } else if (isUniqueOption(value)) {
      onSelect({ label: value, value: value }, name); // Pass custom role back to parent
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = e.target.value.trim();
      if (type === "status") {
        let usedColors = [];
        let fromList = projectStatus.map((item) => item.color);
        let fromRedux = [];
        if (status) {
          fromRedux = status.map((item) => item.color);
        }
        usedColors = [...fromRedux, ...fromList];
        let newColor = getRandomColor(usedColors);
        if (value !== "" && isUniqueOption(value)) {
          let option = { label: value, value: value, color: newColor };
          setCurrentInputColor(newColor);
          setSelectedOption(option);
          setIsOpen(false);
          setCustomRole("");
          setIsOther(false);
          onSelect(option, name);
        }
      } else {
        if (value !== "" && isUniqueOption(value)) {
          let option = { label: value, value: value };
          setSelectedOption(option);
          setIsOpen(false);
          setCustomRole("");
          setIsOther(false);
          onSelect(option, name);
        }
      }
    }
  };

  const handleFocusInput = () => {
    setIsOpen(true);
  };

  const handleInputClick = (event) => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  // Function to get a random color that's not in the existing status array
  const getRandomColor = (existingColors) => {
    const unusedColors = availableColors.filter(
      (color) => !existingColors.includes(color)
    );
    if (unusedColors.length > 0) {
      const randomIndex = Math.floor(Math.random() * unusedColors.length);
      return unusedColors[randomIndex];
    } else {
      // Fallback color if all available colors are used
      return "#000";
    }
  };
  return (
    <div className={`_dropdown_wrapper_ ${className}`} ref={containerRef}>
      <div
        ref={selectRef}
        className={`${width === "mini" ? "_dropdown-mini_" : "_dropdown_"} ${
          isFocused ? "focused" : ""
        }`}
      >
        <input
          type="text"
          value={selectedOption ? selectedOption.label : ""}
          placeholder={placeholder}
          className={
            selectedOption?.label
              ? "selected-option important-color"
              : "_drop-down-placeholder_"
          }
          onClick={handleInputClick}
          onFocus={handleFocusInput}
          onBlur={handleBlur}
          disabled={disabled}
          style={{ "--dynamic-color": currentInputColor, border: 0 }}
        />
      </div>

      {isOpen && (
        <div className="options shadow-sm" ref={optionsRef}>
          {options.map((option, i) => (
            <div
              key={option.value}
              className={`${i > 0 ? "option mt-2" : "option"} ${
                isOther && option.value === "Other" ? "highlighted-option" : ""
              }`}
              onClick={() => handleSelectOption(option)}
              style={{ color: `${option.color}` }}
            >
              {option.label}
            </div>
          ))}

          {isOther && (
            <input
              type="text"
              className="_dropdown_input_ mt-2"
              placeholder="Specify your option"
              value={customRole}
              onChange={handleCustomRoleChange}
              onKeyDown={handleKeyDown}
              ref={inputRef}
              maxLength={30}
              disabled={disabled}
            />
          )}
        </div>
      )}

      <img
        src={ArrowBottomIcon}
        alt="arrow down"
        className={`_dropdown_icon_ ${disabled ? "disabled" : ""}`}
        onClick={() => {
          if (!disabled) setIsOpen(!isOpen);
        }}
      />
    </div>
  );
};

export default memo(CustomSelect);
