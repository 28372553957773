import { React, memo, useCallback, useEffect, useState } from "react";
import { json, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CommonButton from "../../components/common/CommonButton";
import CustomSelect from "../../components/common/CustomSelect";
import GlobalSearch from "../../components/common/GlobalSearch";
import QuickActions from "../../components/QuickActions";
import ProjectInfo from "../onboarding/ProjectInfo";
import ProjectPhases from "../onboarding/ProjectPhases";
import ClientDetails from "../../modals/ClientDetails";
import { addLeadSourceService, addStatusService, getBusinessProjectsService, getInvoiceOverview, getProjectTemplate, getRecentActivitiesService, updateProjectService } from "../../services";
import { projectOverViewTabs, projectStage, projectStatus } from "../../utils/constant";
import { formatDate, formatDate2 } from "../../utils/helper";
import { persistor } from "../../store";
import { logout } from "../../features/auth/authSlice";
import userimg from "./../../assets/dashboard/user_img.svg";
import favouritestar from "./../../assets/dashboard/favourite_star.svg";
import plusicon from "./../../assets/dashboard/pluscircle.svg";
import yellowstar from "./../../assets/dashboard/star.svg";
import PenIcon from "../../assets/logos/pen.svg";
import { addStatus } from "../../features/status/statusSlice";
import { addProjects } from "../../features/projects/projectsSlice";
import Dealroom from "./dealroom/index";
import { addLeadSource } from "../../features/leadSource/leadSourceSlice";
import ChangeRequest from "./changeRequest";
import ProjectManagement from "./projectManagement";
import Expenses from "./expenses";
import Invoices from "./invoices";

// Get today's date in the format YYYY-MM-DD
const today = new Date().toISOString().split('T')[0];

const _projectStatus_ = [
  { label: 'On-track', value: 'On-track',color:'#2ecc71' },
  { label: 'Delayed', value: 'Delayed',color:'#e74c3c' },
  { label: 'On-hold', value: 'On-hold' ,color:'#f39c12'},
  { label: 'Canceled', value: 'Canceled',color:'#7f8c8d' },
  { label: 'Complete', value: 'Complete' ,color:'#3498db'},
  { label: 'At-Risk', value: 'At-Risk',color:'#e67e22' },
  { label: 'Not Started', value: 'Not Started',color:'#95a5a6' },
  { label: 'Other', value: 'Other' }
]
const leadSource = [
    { label: 'Website', value: 'Website', color:'' },
    { label: 'Facebook', value: 'Facebook', color:'' },
    { label: 'Instagram', value: 'Instagram', color: '' },
    { label: 'LinkedIn', value: 'LinkedIn', color: '' },
    { label: 'Twitter', value: 'Twitter', color: '' },
    { label: 'Referral', value: 'Referral', color: '' },
    { label: 'Email Marketing', value: 'Email Marketing', color: '' },
    { label: 'Online Advertising', value: 'Online Advertising', color: '' },
    { label: 'Google Search', value: 'Google Search', color: '' },
    { label: 'Ads', value: 'Ads', color: '' },
    { label: 'Influencer', value: 'Influencer', color: '' },
    { label: 'Other', value: 'Other', color:'' }
]

const _leadSource_ = [
  { label: 'FaceBook', value: 'FaceBook' },
  { label: 'Youtube', value: 'Youtube' },
  { label: 'Linkdin', value: 'Linkdin' },
  { label: 'Twitter', value: 'Twitter' },
]

const ProjectDetails = () => {
  const [_formatDate,_setFormatDate] = useState('')
  const [isLeadSourceadded,setIsLeadSourceAdded] = useState(false)
  const [selectedDate, setSelectedDate] = useState('');
  const [isRoleAdded, setIsRoleAdded] = useState(false)
  const [isClientDetailsModalOpen, setClientDetailsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [projectDetail, setProjectDetail] = useState({});
  const [tags, setTags] = useState([]);
  const [tagName, setTagName] = useState("");
  const [tagColor, setTagColor] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [tagError, setTagError] = useState('');
  const [colorError, setColorError] = useState('');
  const [invoiceOverview, setInvoiceOverview] = useState({})
  const [invoiceOverviewShow, setInvoiceOverviewShow] = useState({})

  const location = useLocation();
  const { id } = location.state || {}
  const [projectStatusInitialVal, setProjectStatusInitialVal] = useState(null)
  const [projectStageInitialVal, setProjectStageInitialVal] = useState(null)
  const [leadSourceInitialVal, setLeadSourceInitialVal] = useState(null)
  const [_projectStatus, _setProjectStatus] = useState({
    name: '',
    color: '',
  })
  const [_projectStage, _setProjectStage] = useState({
    name: '',
    color: '',
  })
  const [_leadSource, _setLeadSource] = useState({
    name: '',
    color: '',
  })
  const [activity, setActivity] = useState([])
  const [_superVisor, _setSuperVisor] = useState(null)
  const [invoiceShow, setInvoiceShow] = useState(null);
  const [preFilledTags, setPreFilledTags] = useState([])
  const [newLead, setNewLead] = useState([])

  const [isStarred, setIsStarred] = useState(false);
  const navigate = useNavigate()

  const phases = useSelector(state => state?.phases?.phases?.phase);
  const status = useSelector(state => state?.status?.status?.status);
  const reduxLeadSource = useSelector(state => state?.leadSource?.leadSource?.leadSource);
  const _id = useSelector(state => state?.status?.status?.id)
  const _businessId = useSelector(state => state?.status?.status?.businessId)
  const projects = useSelector((state) => state?.projects?.projects);

  const [activeTab, setActiveTab] = useState('Overview');



  const handleTabClick = (tab) => {
    localStorage.removeItem('isCR');
    setActiveTab(tab);
  };
  

  const [isdropDownError, setIsDropDownError] = useState({
    industry: false,
    role: false,
  });

  const [nameInitials, setNameInitials] = useState('');
  const [memberList, setMemberList] = useState([])
  const dispatch = useDispatch()

  useEffect(() =>{
    dynmaicprojectStage()
  },[phases])
  
// Name Intital
   useEffect(() => {
    let _userData = JSON.parse(localStorage.getItem('authUserData'))
    let referrer = _userData?.businessDetail?.referrer
    let _members = _userData?.businessDetail?.projects[0]?.members || []
    let role = _userData.role
    if(role === "Owner" || role === 'Accountant' || role === "Admin"){
      setInvoiceShow(true)
    }else{
      setInvoiceShow(false)
    }
    const userData = localStorage.getItem('authUserData');  
    if (userData) {
      const parsedData = JSON.parse(userData);
      const { firstName, lastName } = parsedData;
      if (firstName && lastName) {
        const firstInitial = firstName?.charAt(0).toUpperCase();
        const lastInitial = lastName?.charAt(0).toUpperCase();
        setNameInitials(`${firstInitial}${lastInitial}`);
      }
    }

    const updatedLeadSource =  JSON.parse(localStorage.getItem('leadSource')) || null

    
    if(_members.length > 0){
      setMemberList(_members)
    }
  }, []);

  useEffect(() => {
    if(activeTab === "Overview"){
      getRecentActivities()
      getTemplates()
      localStorage.removeItem('filterCheks')
      localStorage.removeItem('ProjectDetails')
      let _projectDetail = JSON.parse(localStorage.getItem('projectDetail'))
      let _members = _projectDetail?.members || []
      let isSuperVisor = localStorage.getItem('isSuperVisor')
      if(isSuperVisor === false || isSuperVisor === true){
        _setSuperVisor(isSuperVisor)
      }
      if(_projectDetail){
          setProjectDetail(_projectDetail)
          if(_projectDetail.projectDueDate){
            const formattedDate = _projectDetail.projectDueDate.split('T')[0];
            setSelectedDate(formattedDate);
          }
          if(_projectDetail.projectStatus !== ''){
            
            setProjectStatusInitialVal({ label: _projectDetail.projectStatus.name, value: _projectDetail.projectStatus.name, color: _projectDetail.projectStatus.color })
          }
          if(_projectDetail.phases !== ''){
            setProjectStageInitialVal({ label: _projectDetail.projectStage.name, value: _projectDetail.projectStage.name, color: _projectDetail.projectStage.color})
          }
          if(_projectDetail.leadSource !== '' && _projectDetail.leadSource !== null){
            setLeadSourceInitialVal({ label: _projectDetail.leadSource.name, value: _projectDetail.leadSource.name, color: '' })
          }else{
            let source = JSON.parse(localStorage.getItem('leadSource'))
            let recentSource = source[0].value
            setLeadSourceInitialVal({ label: recentSource, value: recentSource, color: '' })
          }
          if(_projectDetail.favorite === 1){
            setIsStarred(true)
          }
          if(_projectDetail.clients[0]?.clientTag?.length > 0){
                setPreFilledTags((_projectDetail.clients[0]?.clientTag))
          }
  
          if(_members.length > 0){
            setMemberList(_members)
          }
      }
    }
  }, [activeTab]);


  useEffect(() => {
        if(_projectStage.name !== ''){
            let temp = {...projectDetail}
            temp.favorite = isStarred === true ? 1 : 0
            temp.projectStage.name = _projectStage.name
            temp.projectStage.color = _projectStage.color
            // temp.projectStatus = _projectStatus
            // temp.leadSource = _leadSource
            updateProjectService(temp).then(res => {
              // setIsLoading(true)
              if(res.statusCode === 200){
                if(res.data.status === 1){
                  // setIsLoading(false)
                  let temp = res.data.responseData.projectData
                  temp['id'] = id
                  localStorage.setItem('projectDetail',JSON.stringify(temp))
                  setProjectDetail(temp)
                  getBusinessProjects()
                }else{
                  // setIsLoading(false)
                }
              }
            })
        }
  },[_projectStage])

  useEffect(() => {
    if(_formatDate !== ''){
        let temp = {...projectDetail}
        temp.favorite = isStarred === true ? 1 : 0
        temp.projectDueDate = _formatDate
        updateProjectService(temp).then(res => {
          // setIsLoading(true)
          if(res.statusCode === 200){
            if(res.data.status === 1){
              // setIsLoading(false)
              let temp = res.data.responseData.projectData
              temp['id'] = id
              localStorage.setItem('projectDetail',JSON.stringify(temp))
              setProjectDetail(temp)
              getBusinessProjects()
            }else{
              // setIsLoading(false)
            }
          }
        })
    }
},[_formatDate])

  useEffect(() => {
    let _projectDetail = JSON.parse(localStorage.getItem('projectDetail'))
    let businessId = _projectDetail?.businessId
    if( _projectStatus.name !== ''){
        let temp = {...projectDetail}
        temp.favorite = isStarred === true ? 1 : 0
        // temp.projectStage = _projectStage
        temp.projectStatus.name = _projectStatus.name
        temp.projectStatus.color = _projectStatus.color
        // temp.leadSource = _leadSource
        updateProjectService(temp).then(res => {
          // setIsLoading(true)
          if(res.statusCode === 200){
            if(res.data.status === 1){
              // setIsLoading(false)
              let temp = res.data.responseData.projectData
              temp['id'] = id
              localStorage.setItem('projectDetail',JSON.stringify(temp))
              setProjectDetail(temp)
              getBusinessProjects()
            }else{
              // setIsLoading(false)
            }
          }
        })

        if(status){
          if(isUniqueProjectStatus(_projectStatus.name.trim())){
            let _temp = [...status]
            const newPosition = _temp.length + 1;
        
            // Create the new item
            const _newItem = {
              name: _projectStatus.name.trim(),
              color: _projectStatus.color,
              position: newPosition,
              isSelected: false
            };
            
            // Add the new item to the end of the status array
            // _temp.unshift(_newItem);
            _temp.push(_newItem);
            
            let data = {
              id : _id,
              businessId: businessId,
              status : _temp
            }
            try {
              addStatusService(data).then(res => {
                if(res.statusCode === 200){
                  if(res.data.status === 1){
                    dispatch(addStatus(res.data.responseData))
                  }
                }
              })
            } catch (error) {
              
            }
          }
        }else{
          if(isUniqueProjectStatus(_projectStatus.name.trim())){
            let data = {
              id : _id,
              businessId: businessId,
              status : []
            }
            const _newItem = {
              name: _projectStatus.name.trim(),
              color: _projectStatus.color,
              position: 1,
              isSelected: false
            };
            data.status.push(_newItem)
            try {
              addStatusService(data).then(res => {
                if(res.statusCode === 200){
                  if(res.data.status === 1){
                    dispatch(addStatus(res.data.responseData))
                  }
                }
              })
            } catch (error) {
              
            }
          }
        }
    }
},[_projectStatus])
  useEffect(() => {
  let _projectDetail = JSON.parse(localStorage.getItem('projectDetail'))
  let businessId = _projectDetail?.businessId
  if(_leadSource.name !== '' ){
      let temp = {...projectDetail}
      temp.favorite = isStarred === true ? 1 : 0
      // temp.projectStage = _projectStage
      // temp.projectStatus = _projectStatus
      temp.leadSource.name = _leadSource.name
      temp.leadSource.color = _leadSource.color
      updateProjectService(temp).then(res => {
        // setIsLoading(true)
        if(res.statusCode === 200){
          if(res.data.status === 1){
            // setIsLoading(false)
            let temp = res.data.responseData.projectData
            temp['id'] = id
            localStorage.setItem('projectDetail',JSON.stringify(temp))

            // Convert _leadSource to lowercase
            const lowerCaseLeadSource = _leadSource.name.toLowerCase();

            // Check if lowerCaseLeadSource already exists in newLead
            const exists = reduxLeadSource.some(item => 
                item.name.toLowerCase() === lowerCaseLeadSource
            );

            // If it doesn't exist, proceed to add it to newLead
            if (!exists) {

              let _temp = [...reduxLeadSource]
              const newPosition = _temp.length + 1;
          
              // Create the new item
              const _newItem = {
                name: _leadSource.name.trim(),
                color: '',
                position: newPosition,
                isSelected: false
              };
              
              // Add the new item to the end of the status array
              _temp.push(_newItem);
              
              let data = {
                businessId: businessId,
                leadSource : _temp
              }
              try {
                addLeadSourceService(data).then(res => {
                  if(res.statusCode === 200){
                    if(res.data.status === 1){
                      dispatch(addLeadSource(res.data.responseData))
                    }
                  }
                })
              } catch (error) {
                
              }
              // localStorage.setItem('leadSource',JSON.stringify(newLeadSource))
            }else {
              // localStorage.setItem('leadSource',JSON.stringify(newLead))
            }
            setProjectDetail(temp)
            getBusinessProjects()
          }else{
            // setIsLoading(false)
          }
        }
      })
  }
},[_leadSource])

  useEffect(() => {
        updateproject()
  },[isStarred])

  useEffect(() => {
    if(projectDetail && tags.length > 0){
      let temp = {...projectDetail}
      temp.favorite = isStarred === true ? 1 : 0
      if(temp.clients && temp.clients[0].clientTag){
        temp.clients[0].clientTag = tags
      }
      updateProjectService(temp).then(res => {
        // setIsLoading(true)
        if(res.statusCode === 200){
          if(res.data.status === 1){
            // setIsLoading(false)
            let temp = res.data.responseData.projectData
            temp['id'] = id
            localStorage.setItem('projectDetail',JSON.stringify(temp))
            setProjectDetail(temp)
            getBusinessProjects()
          }else{
            // setIsLoading(false)
          }
        }
      })
    }
  },[tags])

  const getTemplates = () => {
    let ProjectDetails = JSON.parse(localStorage.getItem('projectDetail'))
    let projectID = ProjectDetails?.id || null
    if(projectID){
      getProjectTemplate(projectID).then(res => {
        console.log(projectID, res, 'res inside getProjecttemplates')
      })
    }
  }

  const getBusinessProjects = () => {
    let userData = JSON.parse(localStorage.getItem('authUserData'))
    let businessID = userData?.businessDetail?.businessId || null
    // console.log(businessID,'businessIDbusinessIDbusinessIDbusinessID')
    if(businessID) {
          try {
          getBusinessProjectsService(businessID).then(res => {
          console.log(res,'res inside PROJECTS')
          if(res.statusCode === 200){
          if(res.data.status === 1){
          dispatch(addProjects(res.data.responseData.projectData))
          // const pipeLineData = getProjectStagesWithTotal(res.data.responseData.projectData)
          // localStorage.setItem('pipeLineData', JSON.stringify(pipeLineData))
          // setPipeLine(pipeLineData)
          }}})
          } catch (error) { }
    }
}

  const dynmaicprojectStage = () => {
      if(phases && phases.length > 0){

        const _projectStage_ = phases.map(item => ({ label: item.name, value: item.name, color: item.color }));
        return _projectStage_
      }else{
        return projectStage
      }
  }

  const dynmaicprojectStaus = () => {
    if(status && status.length > 0){
      const otherStatus = [{label: "Other", value: "Other", color: ''}]
      const dynamicProjectStatus = status.map(item => ({ label: item.name, value: item.name, color: item.color }));
      return [...dynamicProjectStatus, ...otherStatus]
    }else{
      return _projectStatus_
    }
  }

  const dynmaicLeadSource = () => {
    if(reduxLeadSource && reduxLeadSource.length > 0){
      const otherStatus = [{label: "Other", value: "Other", color: ''}]
      const dynamicSource = reduxLeadSource.map(item => ({ label: item.name, value: item.name, color: item.color }));
      return [...dynamicSource, ...otherStatus]
    }else{
      return leadSource
    }
  }

  const isUniqueProjectStatus = (newStatus) => {
    let list = dynmaicprojectStaus()
    return !list.some(status => status?.label.toLowerCase() === newStatus.toLowerCase());
  };

  const updateproject = () => {
    if(projectDetail){
      let temp = {...projectDetail}
      temp.favorite = isStarred === true ? 1 : 0
      updateProjectService(temp).then(res => {
        // setIsLoading(true)
        if(res.statusCode === 200){
          if(res.data.status === 1){
            // setIsLoading(false)
            let temp = res.data.responseData.projectData
            temp['id'] = id
            localStorage.setItem('projectDetail',JSON.stringify(temp))
            setProjectDetail(temp)
            getBusinessProjects()
          }else{
            // setIsLoading(false)
          }
        }
      })
    }
  }

  const getRecentActivities = () => {
    try {
      let _projectDetail = JSON.parse(localStorage.getItem('projectDetail'))
      let projectId = _projectDetail?.id || null
      let query = `?projectId=${projectId}`

      getRecentActivitiesService(query).then(res => {
          if(res.statusCode === 200){
          if(res.data.status === 1){
          setActivity(res.data.responseData.activity || [])
          }}})
    } catch (error) {}
}

  const handleshow = () => setClientDetailsModalOpen(true);

  const handleSelect = useCallback((option, name) => {
    if (option?.value === undefined) return; // Exit if value is undefined

    if (name === 'projectStatus' && option?.value !== undefined) {
      let val = {
        name: option?.value,
        color: option?.color
      }
      _setProjectStatus(val)
    }

    if (name === 'projectStage' && option?.value !== undefined) {
      let val = {
        name: option?.value,
        color: option?.color
      }
      _setProjectStage(val)     
    }

    if (name === 'leadSource' && option?.value !== undefined) {
      let val = {
        name: option?.value,
        color: option?.color
      }
      _setLeadSource(val)
    }
  }, [projectStatus, projectStage, leadSource, _setProjectStatus, _setProjectStage, _setLeadSource]);


  const addTag = (e) => {
    e.preventDefault();
    let hasError = false;

    if (!tagName) {
      setTagError('Please enter a tag name.');
      hasError = true;
    } else {
      setTagError('');
    }

    if (!selectedColor) {
      setColorError('Please select a color.');
      hasError = true;
    } else {
      setColorError('');
    }

    if (!hasError) {
      let arrayTags = [...preFilledTags];
      arrayTags.push({ name: tagName, color: selectedColor });
      setPreFilledTags(arrayTags);
      setTags(arrayTags);
      setTagName("");
      setSelectedColor("");
      document.getElementById("addTagForm1").style.display = "none";
    }
  };

  const handleColorSelect = (color) => {
    setTagColor(color);
    setSelectedColor(color);
    setColorError('');

  };

  const handleStarClick = () => {
    setIsStarred(!isStarred);
    // updateproject()
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    persistor.purge() // Clear persisted state
    navigate("/signin");
  };

  const getInitials = (text) => {
    return text.split(' ').map(word => word.charAt(0)).join(', ');
  };

  const handleTagNameChange = (e) => {
    const value = e.target.value.replace(/^\w/, (c) => c.toUpperCase());
    setTagName(value);
    if (value === "") {
      setTagError('Please enter a tag name.');
    } else {
      setTagError('');
    }
  };

  // Function to get a random color that's not in the existing status array
  const getRandomColor = (existingColors, availableColors) => {
    const unusedColors = availableColors.filter(color => !existingColors.includes(color));
    if (unusedColors.length > 0) {
      const randomIndex = Math.floor(Math.random() * unusedColors.length);
      return unusedColors[randomIndex];
    } else {
      // Fallback color if all available colors are used
      return '#000';
    }
  };

  const handleDateChange = (e) => {
    const inputDate = new Date(e.target.value);
    const formattedDate = inputDate.toISOString();
    _setFormatDate(formattedDate)
    setSelectedDate(e.target.value);
  };



  useEffect(() => {
    const userData  = JSON.parse(localStorage.getItem('authUserData'));
    const projectData  = JSON.parse(localStorage.getItem('projectDetail'));
    const businessId = userData?.businessDetail?.businessId || ''
    const projectId = projectData?.id || ''
    const token = localStorage.getItem("token");
    if(businessId !== ''){
      getInvoiceOverview(projectId, businessId, token).then(res => {
        if(res.data.status === 1){
          setInvoiceOverview(res.data.responseData.invoiceOverview)
        }
      })
    }
  },[])




  return (
    <div>
      <div className="dashboard_upper projectdetails_dash_upper">
        <div className="projectdetails_dash_left">
          <img src={isStarred ? yellowstar : favouritestar} className="fav_star" onClick={handleStarClick} alt="Star Toggle"/>
          <h3>{projectDetail?.projectName}</h3>
        </div>
        <div className="projectdetails_dash_left">
          
          <GlobalSearch />
          <div className="logout_main_div">
            <div className="name_intials_dash_upper">{nameInitials}</div>
            <div className="logout_div">
              <p onClick={handleLogout}>Logout</p>
            </div>
          </div>
          
        </div>
      </div>
      <ul className="projectdetails_navtab_ul">
        {projectOverViewTabs.map((tab) => (
          <li
            key={tab}
            className={`projectdetails_navtab_li ${activeTab === tab ? 'active' : ''}`}
            onClick={() => handleTabClick(tab)}
          >
            <p className="font_16">{tab}</p>
          </li>
        ))}
      </ul>
      <div className="projectdetails_dash_lower main_details_div">
        {/* Dashboard Details */}
        <div className={`dashboard_detail ${activeTab === 'Overview' ? 'dashboard_detail_active' : ''}`}>
          <div className="dashboard_detail_left">
            <div className={`projectdetails_clientdiv ${isLoading ? 'loading' : ''}`}>
              <ul className="modal_main_ul">
                <li className="modal_main_li">
                  <label className="font_14">Client</label>
                  <div className="_editable_">
                    <input type="text" placeholder="Client Name" value={projectDetail?.clients?.length > 0 ? projectDetail?.clients[0]?.clientName : ''}/>
                    <img src={PenIcon} alt="pen icon" width='20' height='20' className="_pointer_" onClick={handleshow}/>
                  </div>
                </li>
                <li className="modal_main_li">
                  <label className="font_14">Overall Project Status</label>
                  <CustomSelect
                    options={dynmaicprojectStaus()}
                    name="projectStatus"
                    placeholder="Status"
                    initialValue={projectStatusInitialVal}
                    onSelect={handleSelect}
                    isUpdate={true}
                    type='status'
                    _isroleAdded = {isRoleAdded}
                    refreshPlaceHolder={() => setIsRoleAdded(false)}
                  />
                  {isdropDownError.industry ? (
                    <div className="_error_ _flex_error_">
                      Industry is required
                    </div>
                  ) : null}
                </li>
                <li className="modal_main_li">
                  <label className="font_14">Lead Source</label>
                  <CustomSelect
                    options={dynmaicLeadSource()}
                    name="leadSource"
                    placeholder="Lead Source"
                    initialValue={leadSourceInitialVal}
                    onSelect={handleSelect}
                    isUpdate={true}
                    _isroleAdded = {isLeadSourceadded}
                    refreshPlaceHolder={() => setIsLeadSourceAdded(false)}
                  />
                  {isdropDownError.industry ? (
                    <div className="_error_ _flex_error_">
                      Industry is required
                    </div>
                  ) : null}
                </li>
                <li className="modal_main_li">
                  <label className="font_14">Project Phase</label>
                  <CustomSelect
                    options={dynmaicprojectStage()}
                    name="projectStage"
                    placeholder="Design"
                    initialValue={projectStageInitialVal}
                    onSelect={handleSelect}
                    isUpdate={true}
                  />
                  {isdropDownError.industry ? (
                    <div className="_error_ _flex_error_">
                      Industry is required
                    </div>
                  ) : null}
                </li>
                <li className="modal_main_li">
                  <label className="font_14">
                    Client Tags <span>( Not visible to the client )</span>
                  </label>
                  <div className="client_tag_div">
                    {preFilledTags.map((tag, index) => (
                      <span key={index} style={{ backgroundColor: tag.color }}>
                        {tag.name}
                      </span>
                    ))}
                    <img src={plusicon} alt="Add" onClick={() => (document.getElementById("addTagForm1").style.display = "block")}/>
                  </div>
                  <form id="addTagForm1" className="modal_main_li" style={{ display: "none" }} onSubmit={addTag}>
                    {/* <input type="text" placeholder="Name Tag" value={tagName} onChange={(e) => setTagName(e.target.value.replace(/^\w/, (c) => c.toUpperCase()))} maxLength={20}/> */}
                    <input type="text" placeholder="Name Tag" value={tagName} onChange={handleTagNameChange} maxLength={20} />
                    {tagError && <div className="error-message _error_">{tagError}</div>}
                    <div className="select_color_div">
                      <label className="font_14 select_color_label">Select Color :</label>
                      <div className="color-selector">
                        {["#EF4444","#F97316","#FACC15","#4ADE80","#2DD4BF","#3B82F6","#6366F1","#EC4899","#F43F5E","#D946EF","#8B5CF6","#0EA5E9","#10B981","#84CC16",].map((color) => (
                        <div className="modal_selector_div" key={color}>
                          <button type="button" style={{ backgroundColor: color }} className={selectedColor === color ? "active" : ""} onClick={() => handleColorSelect(color)} />
                        </div>))}
                      </div>
                    </div>
                    {colorError && <div className="error-message _error_">{colorError}</div>}
                    <button type="submit" className="create_modal_btn">Create</button>
                  </form>
                </li>
                <li className="modal_main_li ">
                  <label className="font_14">Project Due Date</label>
                  <div style={{position: "relative"}}>
                    <p className="form-control p-2"><div className="p-2">{selectedDate ? formatDate2(selectedDate) : <span className="cm_placeholder">Select Date</span>}</div></p>
                    <input 
                      style={{
                        position: 'absolute',
                        width: '48px',
                        height: '56px',
                        right: '1px',
                        top: '1px',
                        cursor: 'pointer',
                        zIndex: '99',
                        border: '0'
                      }}
                      type="date" 
                      placeholder="Date" 
                      value={selectedDate}
                      onChange={handleDateChange}
                      min={today} />
                  </div>
                  
                </li>
              </ul>
            </div>
            {invoiceShow ?
              <div className="dashboard_detail_left_main">
              <div className="dash_task">
                <div className="dash_task_upper">
                  <div className="heading_symbol">
                    <h3 className="dashboard_heading">Tasks</h3>
                    {/* <img src={plusicon} className="plusicon" /> */}
                  </div>
                  {/* <p className="dash_task_view">View All</p> */}
                </div>
                {/* <ul className="dash_task_ul">
                  <li className="dash_task_li">
                    <div className="dash_task_input_div">
                      <input type="checkbox" />
                    </div>
                    <div className="dash_task_li_div">
                      <h4 className="font_16">Lodha Aquarius</h4>
                      <h5 className="font_14">
                        Follow up on progress of designer
                      </h5>
                      <p className="font_12">Due on 13 Apr, By Rahul Singh</p>
                    </div>
                  </li>
                  <li className="dash_task_li">
                    <div className="dash_task_input_div">
                      <input type="checkbox" />
                    </div>
                    <div className="dash_task_li_div">
                      <h4 className="font_16">DLF Constructions</h4>
                      <h5 className="font_14">
                        Follow up on progress of designer
                      </h5>
                      <p className="font_12">Due on 13 Apr, By Rahul Singh</p>
                    </div>
                  </li>
                  <li className="dash_task_li">
                    <div className="dash_task_input_div">
                      <input type="checkbox" />
                    </div>
                    <div className="dash_task_li_div">
                      <h4 className="font_16">Metaverse Portal</h4>
                      <h5 className="font_14">
                        Follow up on progress of designer
                      </h5>
                      <p className="font_12">Due on 13 Apr, By Rahul Singh</p>
                    </div>
                  </li>
                </ul> */}
                <div className="no_task_div">
                  <p>No Task Yet</p>
                </div>
              </div>
              <div className="dash_invoice">
                <div className="heading_symbol">
                  <h3 className="dashboard_heading">Invoices</h3>
                </div>


                <ul className="dash_invoice_ul">
                  <li className="dash_invoice_li">
                    <h4 className="font_18">Total</h4>
                    <h5 className="font_16">₹ {invoiceOverviewShow.total ? invoiceOverview.totalAmount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'XXXXXXXXX'} </h5>
                    <p
                      className="font_14"
                      style={{cursor: 'pointer'}}
                      onClick={() => setInvoiceOverviewShow({...invoiceOverviewShow, total: !invoiceOverviewShow.total})}>
                        {invoiceOverviewShow.total ? 'Hide' : 'Show'}
                      </p>
                  </li>


                  <li className="dash_invoice_li">
                    <h4 className="font_18">Received</h4>
                    <h5 className="font_16">₹ {invoiceOverviewShow.received ? invoiceOverview.receivedAmount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'XXXXXXXXX'} </h5>
                    <p className="font_14">In Last 30 Days</p>
                    <p
                    style={{cursor: 'pointer'}}
                      className="font_14"
                      onClick={() => setInvoiceOverviewShow({...invoiceOverviewShow, received: !invoiceOverviewShow.received})}>
                        {invoiceOverviewShow.received ? 'Hide' : 'Show'}
                      </p>
                  </li>


                  <li className="dash_invoice_li">
                    <h4 className="font_18">Outstanding</h4>
                    <h5 className="font_16">₹ {invoiceOverviewShow.outstanding ? invoiceOverview.outstandingAmount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'XXXXXXXXX'} </h5>
                    <p
                    style={{cursor: 'pointer'}}
                      className="font_14"
                      onClick={() => setInvoiceOverviewShow({...invoiceOverviewShow, outstanding: !invoiceOverviewShow.outstanding})}>
                        {invoiceOverviewShow.outstanding ? 'Hide' : 'Show'}
                      </p>
                  </li>


                  <li className="dash_invoice_li">
                    <h4 className="font_18">Overdue</h4>
                    <h5 className="font_16">₹ {invoiceOverviewShow.overdue ? invoiceOverview?.overdue?.amount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'XXXXXXXXX'} </h5>
                    <p className="font_14">Over 30 Days</p>
                    <p
                    style={{cursor: 'pointer'}}
                      className="font_14"
                      onClick={() => setInvoiceOverviewShow({...invoiceOverviewShow, overdue: !invoiceOverviewShow.overdue})}>
                        {invoiceOverviewShow.overdue ? 'Hide' : 'Show'}
                      </p>
                  </li>
                </ul>
              </div>
            </div> : 
            <div className="dash_task_main">
                <div className="dash_task_upper">
                  <div className="heading_symbol">
                    <h3 className="dashboard_heading">Tasks</h3>
                    {/* <img src={plusicon} className="plusicon" /> */}
                  </div>
                  <p className="dash_task_view">View All</p>
                </div>
                <ul className="dash_task_main_ul full_task_div">
                  {/* <li className="dash_task_main_li">
                    <input type="checkbox" />
                    <h4 className="font_16">Follow up on progress of designer</h4>
                    <p className="font_14">Due on Apr 15, By Rahul Singh</p>
                  </li>
                  <li className="dash_task_main_li">
                    <input type="checkbox" />
                    <h4 className="font_16">Follow up on progress of designer</h4>
                    <p className="font_14">Due on Apr 15, By Rahul Singh</p>
                  </li>
                  <li className="dash_task_main_li">
                    <input type="checkbox" />
                    <h4 className="font_16">Follow up on progress of designer</h4>
                    <p className="font_14">Due on Apr 15, By Rahul Singh</p>
                  </li>
                  <li className="dash_task_main_li">
                    <input type="checkbox" />
                    <h4 className="font_16">Follow up on progress of designer</h4>
                    <p className="font_14">Due on Apr 15, By Rahul Singh</p>
                  </li> */}
                  <div className="no_task_div">
                    <p>No Task Yet</p>
                  </div>
                </ul>
              </div>
            }

            <div className="dash_recent">
              <div className="heading_symbol">
                <h3 className="dashboard_heading">Recent Activity</h3>
                {/* <img src={plusicon} className="plusicon" /> */}
              </div>
              {activity.length > 0 ? (
                activity.map((item, index) => (
                  <ul className="dash_recent_ul" key={index}>
                    <li className="dash_recent_li" key={index}>
                      <figure className="dash_recent_li_figure">
                        <div className="dash_recent_li_figure_div">
                          {getInitials(item.model)}
                        </div>
                      </figure>
                      <figcaption className="dash_recent_li_figcap">
                        {/* <h3 className="font_16">
                          {item.model},{" "}
                          <span className="font_weight">{item.action} </span>
                          <span className="font_date">
                            {" "}
                            {formatDate(item.timestamp)}
                          </span>
                        </h3> */}
                        <p className="dash_recent_li_para font_16">
                          {item.description}
                        </p>
                      </figcaption>
                    </li>
                  </ul>
                ))
              ) : (
                <div>No data Found</div>
              )}
            </div>
          </div>

          <div className="dashboard_detail_right">
            <QuickActions refreshPipline={() => console.log('')}/>
            <div className="dash_fav">
              <div className="dash_task_upper">
                <div className="heading_symbol">
                  <h3 className="dashboard_heading">Team</h3>
                </div>
                {/* <p className="dash_task_view">View All</p> */}
              </div>
              <ul className="dash_fav_ul">
              {memberList.length > 0 ? (
                memberList.map((member, index) => (
                  <li key={index} className="dash_fav_li">
                    <h4 className="dash_recent_li_figure_div">{member?.firstName ? member?.firstName?.charAt(0).toUpperCase() : member?.email?.charAt(0).toUpperCase()}</h4>
                    <div className="dash_fav_li_div">
                      <h4 className="font_16">{(member?.firstName || member?.lastName) ? `${member?.firstName} ${member?.lastName}`  : member.email}</h4>
                      <h5 className="font_14">{member.role}</h5>
                    </div>
                  </li>
                ))
              ) : (
                <div className="no_task_div">
                  <p>No Team Yet</p>
                </div>
              )}
              </ul>
            </div>
            <div className="dash_task dash_notes">
              <div className="dash_task_upper">
                <div className="heading_symbol">
                  <h3 className="dashboard_heading">My Notes </h3>
                  {/* <img src={plusicon} className="plusicon" /> */}
                </div>
              </div>
              <p className="projectdetails_notes_heading font_14">
                ( Only visible to you )
              </p>
              <input
                type="text"
                placeholder="Notes...."
                className="projectdetails_notes_input"
              />
              <ul className="dash_task_ul">
                <div className="no_task_div">
                  <p>No Notes Yet</p>
                </div>
                {/* <li className="dash_task_li">
                  <div className="dash_task_li_div">
                    <h4 className="font_14">
                      Client likes beige tones for their designs
                    </h4>
                    <h5 className="font_12">12 May, 2024, 7:15AM</h5>
                  </div>
                </li>
                <li className="dash_task_li">
                  <div className="dash_task_li_div">
                    <h4 className="font_14">
                      Client likes beige tones for their designs
                    </h4>
                    <h5 className="font_12">12 May, 2024, 7:15AM</h5>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        {/* Dealroom */}
        <div className={`dealroom_detail ${activeTab === 'Dealroom' ? 'active' : ''}`}>
            <Dealroom activeTab={activeTab}/>
        </div>

        <div className={`changeresquest ${activeTab === 'Change Requests' ? 'active' : ''}`}>
          <ChangeRequest activeTab={activeTab}/>
        </div>
        <div className={`projectdeatails ${activeTab === 'Project Management' ? 'active' : ''}`} style={{maxHeight:"650px", overflowY:"auto"}}>
          <ProjectManagement activeTab={activeTab}/>
        </div>
        <div className={`invoices ${activeTab === 'Invoices' ? 'active' : ''}`}>
          <Invoices activeTab={activeTab}/>
        </div>
        <div className={`expenses ${activeTab === 'Expenses' ? 'active' : ''}`}>
          <Expenses activeTab={activeTab}/>
        </div>
        <div className={`files ${activeTab === 'Files' ? 'active' : ''}`}>Files Content</div>
        <div className={`notes_tasks ${activeTab === 'Notes & Tasks' ? 'active' : ''}`}>Notes & Tasks Content</div>
        <div className={`project_team ${activeTab === 'Project Team' ? 'active' : ''}`}>Project Team Content</div>

      </div>
      {isClientDetailsModalOpen && 
      <ClientDetails
      id={id}
      type='update'
      openProject={() => console.log('')} 
      updateProjectList={getBusinessProjects}
      onHide={() => setClientDetailsModalOpen(false)} 
      _refreshPipeLine={(data) => setProjectDetail(data)}
      isOpen={setClientDetailsModalOpen}
      />}
    </div>
  );
};

export default memo(ProjectDetails);
