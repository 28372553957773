import { format, startOfDay } from "date-fns";
import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from "../../../assets/logos/calendar.svg";

const formatDate = (comingDate) => {
  const date = new Date(comingDate);
  const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss") + "Z";
  return formattedDate;
};

const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <span>{value || "MMMM dd, yyyy"}</span>
    <span
      style={{ marginLeft: 10, cursor: "pointer" }}
      onClick={onClick}
      ref={ref}
    >
      <img src={calendarIcon} alt="calendar" />
    </span>
  </div>
));

const TableDateComponent = ({ date, onChange }) => {
  const today = startOfDay(new Date()); // Get today's date at the start of the day

  const handleDateChange = (newDate) => {
    const event = {
      target: {
        value: formatDate(newDate),
      },
    };
    onChange(event); // Call onChange instead of onDateChange
  };

  const clearDate = () => {
    const event = {
      target: {
        value: "",
      },
    };
    onChange(event); // Call onChange instead of onDateChange
  };

  return (
    <DatePicker
      selected={date}
      onChange={handleDateChange}
      customInput={<CustomDateInput />}
      dateFormat={"MMMM dd, yyyy"}
      todayButton="Today"
      minDate={today} // Disable dates before today
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            className="clear-button"
          >
            {"<"}
          </button>
          <span style={{ margin: "0 20px", fontWeight: "bold" }}>
            {format(date, "MMMM yyyy")}
          </span>
          <button
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            className="clear-button"
          >
            {">"}
          </button>
          <button onClick={clearDate} className="clear-button">
            Clear
          </button>
        </div>
      )}
    />
  );
};

export default TableDateComponent;
