import { format, parseISO } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import deleteIcon from "../../../assets/logos/del.svg";
import CommonButton from "../../../components/common/CommonButton";
import CustomTooltip from "../../../components/common/CustomTooltip";
import Loader from "../../../components/common/Loader";
import { addProjects } from "../../../features/projects/projectsSlice";
import DeleteModal from "../../../modals/DeleteModal";
import {
  deleteProjectManagement,
  getBusinessProjectsService,
  getProjectManagementList,
  updateProjectService,
} from "../../../services";
import { projectStage, projectStatus } from "../../../utils/constant";
import useToast from "../../../utils/customHooks/useToast";
import searchIcon from "./../../../assets/dashboard/Search.svg";
import plusIcon from "./../../../assets/dashboard/plus.svg";
import projectIcon from "./../../../assets/dashboard/project_blue.svg";
import AddProjectPlan from "./AddProjectPlan";
// import DealRoomFilter from "./../../../modals/DealroomFilter";
// import filterIcon from "./../../../assets/dashboard/filter_icon.svg";
// import { contractStatus } from "../../../utils/constant";

const styles = {
  control: (base) => ({
    ...base,
    width: "85%",
    height: "54px",
    fontSize: "14px",
    justifyContent: "center",
    border: "1px solid #e7e8ea",
    borderRadius: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#e7e8ea",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.data.color,
  }),
  menu: (base) => ({
    ...base,
    width: "85%",
    borderRadius: 5,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    maxHeight: 3 * 40,
    overflowY: "auto",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: "white",
    color: state.data.color,
    height: 40,
    display: "flex",
    alignItems: "center",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: "transform 0.2s ease-in-out",
    transform: state.selectProps.menuIsOpen
      ? "rotate(-180deg)"
      : "rotate(0deg)",
  }),
};

const ProjectManagement = ({ activeTab }) => {
  const location = useLocation();
  const { id } = location.state || {};
  const [showProjectManagementModal, setShowProjectManagementModal] =
    useState(false);
  // const [showProjectManagementFilter, setShowProjectManagementFilter] =
  //   useState(false);
  const [addProjectPlan, setAddProjectPlan] = useState(false);
  const [notShowAgain, setNotShowAgain] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [searchTitle, setSearchTitle] = useState("");
  // const [filterStatus, setFilterStatus] = useState([
  //   ...contractStatus.map((item) => ({ ...item, isChecked: false })),
  // ]);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [selectedStage, setSelectedStage] = useState({});
  const [projectDueDate, setProjectDueDate] = useState(null);
  const [projectManagementList, setProjectManagementList] = useState([]);
  const [projectManagementListFilter, setProjectManagementListFilter] =
    useState([]);
  const [staticDetails, setStaticDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [projectRowData, setProjectRowData] = useState({});
  const dispatch = useDispatch();
  const debounceTimeout = useRef(null);
  const showToast = useToast();

  const handleProjectManagementModalClose = () =>
    setShowProjectManagementModal(false);
  // const handleProjectManagementFilterClick = () =>
  //   setShowProjectManagementFilter(true);
  const handleAddProjectPlanClick = () => {
    setAddProjectPlan(true);
    setShowProjectManagementModal(false);
  };
  const handleAddProjectPlanClose = () => {
    setAddProjectPlan(false);
    if (isEditing) {
      setIsEditing(false);
      setProjectRowData({});
    }
    fetchProjectManagementList(staticDetails?.id);
  };
  const handleOpenDeleteModal = (id) => {
    setDeleteId(id);
    setOpenDelete(true);
  };
  const handleCloseDeleteModal = () => setOpenDelete(false);
  const handleNotShowAgain = (e) => {
    localStorage.setItem("showProjectManagementModal", e.target.checked);
    setNotShowAgain(e.target.checked);
  };
  const handleSearchTitle = (e) => {
    const value = e.target.value;
    setSearchTitle(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      // applyFilters(value, filterStatus);
      applyFilters(value);
    }, 500);
  };
  const handleChange = async (value, key) => {
    switch (key) {
      case "status":
        setSelectedStatus(value);
        await handleUpdateProjectDetails(value, key);
        break;
      case "stage":
        setSelectedStage(value);
        await handleUpdateProjectDetails(value, key);
        break;
      case "dueDate":
        const date = new Date(value);
        const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss") + "Z";
        setProjectDueDate(formattedDate);
        await handleUpdateProjectDetails(formattedDate, key);
        break;
      default:
        break;
    }
  };

  const applyFilters = (searchQuery, filters) => {
    // const checkedFilters = filters
    //   .filter((filter) => filter.isChecked)
    //   .map((filter) => filter.id);

    let filteredList = projectManagementList;

    // if (checkedFilters.length > 0) {
    //   filteredList = filteredList.filter((item) =>
    //     checkedFilters.includes(item.status)
    //   );
    // }

    if (searchQuery) {
      filteredList = filteredList.filter((item) =>
        item.planName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setProjectManagementListFilter(filteredList || []);
  };

  // useEffect(() => {
  //   applyFilters(searchTitle, filterStatus);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterStatus]);

  useEffect(() => {
    if (activeTab === "Project Management") {
      const showProjectManagementModal =
        localStorage.getItem("showProjectManagementModal") || false;
      setAddProjectPlan(false);
      if (showProjectManagementModal) {
        setShowProjectManagementModal(false);
      } else {
        setShowProjectManagementModal(true);
      }
      const projectDetail = localStorage.getItem("projectDetail")
        ? JSON.parse(localStorage.getItem("projectDetail"))
        : null;
      const authUserData = localStorage.getItem("authUserData")
        ? JSON.parse(localStorage.getItem("authUserData"))
        : null;
      setStaticDetails(projectDetail);
      setUserDetails(authUserData);

      const statusObj = {
        label: projectDetail?.projectStatus?.name,
        value: projectDetail?.projectStatus?.name,
        color: projectDetail?.projectStatus?.color,
      };
      setSelectedStatus(statusObj);
      const stageObj = {
        label: projectDetail?.projectStage?.name,
        value: projectDetail?.projectStage?.name,
        color: projectDetail?.projectStage?.color,
      };
      setSelectedStage(stageObj);

      if(projectDetail?.projectDueDate){
        const projectDate = format(
          parseISO(projectDetail?.projectDueDate?.replace("Z", "")),
          "MMMM dd, yyyy"
        );
        setProjectDueDate(projectDate);
      }
      
      fetchProjectManagementList(projectDetail?.id);
    }
  }, [activeTab]);

  const fetchProjectManagementList = async (id) => {
    setIsLoading(true);
    setProjectManagementList([]);
    setProjectManagementListFilter([]);
    try {
      const response = await getProjectManagementList(id);
      setProjectManagementList(response.data?.responseData?.data);
      setProjectManagementListFilter(response.data?.responseData?.data);
    } catch (error) {
      console.error("Error fetching project management list:", error);
    }
    setIsLoading(false);
  };

  const editProjectManagementList = (row) => {
    setAddProjectPlan(true);
    setIsEditing(true);
    setProjectRowData(row);
  };

  const deleteProjectPlan = async (id) => {
    const response = await deleteProjectManagement(id);
    showToast(response.data?.responseData?.message, "success");
    setOpenDelete(false);
    fetchProjectManagementList(staticDetails?.id);
  };

  const handleUpdateProjectDetails = async (value, key) => {
    const tempDetails = { ...staticDetails };
    switch (key) {
      case "status":
        tempDetails.projectStatus.name = value.label;
        tempDetails.projectStatus.color = value.color;
        const updateStatus = await updateProjectService(tempDetails);
        const projectStatusData =
          updateStatus.data?.responseData?.projectData || {};
        projectStatusData["id"] = id;
        localStorage.setItem(
          "projectDetail",
          JSON.stringify(projectStatusData)
        );
        setStaticDetails(projectStatusData);
        const statusId = userDetails?.businessDetail?.businessId;
        const statusResponse = await getBusinessProjectsService(statusId);
        const statusData = statusResponse.data?.responseData?.projectData;
        dispatch(addProjects(statusData));
        showToast("Overall Status Updated!", "success");
        break;
      case "stage":
        tempDetails.projectStage.name = value.label;
        tempDetails.projectStage.color = value.color;
        const updateStage = await updateProjectService(tempDetails);
        const projectStageData =
          updateStage.data?.responseData?.projectData || {};
        projectStageData["id"] = id;
        localStorage.setItem("projectDetail", JSON.stringify(projectStageData));
        setStaticDetails(projectStageData);
        const stageId = userDetails?.businessDetail?.businessId;
        const stageResponse = await getBusinessProjectsService(stageId);
        const stageData = stageResponse.data?.responseData?.projectData;
        dispatch(addProjects(stageData));
        showToast("Project Phase Updated!", "success");
        break;
      case "dueDate":
        tempDetails.projectDueDate = value;
        const updateDueDate = await updateProjectService(tempDetails);
        const projectDueDateData =
          updateDueDate.data?.responseData?.projectData || {};
        projectDueDateData["id"] = id;
        localStorage.setItem(
          "projectDetail",
          JSON.stringify(projectDueDateData)
        );
        setStaticDetails(projectDueDateData);
        const dueDateId = userDetails?.businessDetail?.businessId;
        const dueDateResponse = await getBusinessProjectsService(dueDateId);
        const dueDateData = dueDateResponse.data?.responseData?.projectData;
        dispatch(addProjects(dueDateData));
        showToast("Project Due Date Updated!", "success");
        break;
      default:
        break;
    }
  };

  if (addProjectPlan) {
    return (
      <AddProjectPlan
        handleCancel={handleAddProjectPlanClose}
        staticDetails={staticDetails}
        userDetails={userDetails}
        isEditing={isEditing}
        projectRowData={projectRowData}
      />
    );
  }

  return (
    <>
      <div className="dealroom_main_div">
        <div className="dealroom_detail_upper_left _flex_gap_30">
          {staticDetails && Object.keys(staticDetails).length > 0 && (
            <ul
              className="modal_main_ul"
              style={{
                display: "flex",
                width: "100vw",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 0,
              }}
            >
              <li className="modal_main_li" style={{ width: "100%" }}>
                <label className="font_14">Overall Project Status</label>
                <Select
                  name="Status"
                  value={selectedStatus}
                  onChange={(newValue) => handleChange(newValue, "status")}
                  styles={styles}
                  isSearchable={false}
                  options={projectStatus}
                />
              </li>
              <li className="modal_main_li" style={{ width: "100%" }}>
                <label className="font_14">Project Phase</label>
                <Select
                  name="Stage"
                  value={selectedStage}
                  onChange={(newValue) => handleChange(newValue, "stage")}
                  styles={styles}
                  isSearchable={false}
                  options={projectStage}
                />
              </li>
              <li className="modal_main_li" style={{ width: "100%" }}>
                <label className="font_14">Project Due Date</label>
                <DatePicker
                  selected={projectDueDate}
                  onChange={(newDate) => handleChange(newDate, "dueDate")}
                  placeholderText="MMMM DD, YYYY"
                  dateFormat="MMMM dd, yyyy"
                  wrapperClassName="datePickerWrapper"
                />
              </li>
              <li
                className="modal_main_li"
                style={{ width: "100%", marginTop: "30px" }}
              >
                <CommonButton
                  label="Project Plan"
                  fontSize="14px"
                  fontWeight="700"
                  className="dash_common_btn"
                  icon={plusIcon}
                  onClick={handleAddProjectPlanClick}
                  width={"60%"}
                />
              </li>
            </ul>
          )}
        </div>
        <div
          className="dealroom_detail_upper_left _flex_gap_30"
          style={{ marginTop: "40px" }}
        >
          <h2 className="dashboard_heading">Project Plan</h2>
          <div className="dashboard_upper_input ">
            <input
              type="text"
              placeholder="Search"
              value={searchTitle}
              onChange={handleSearchTitle}
            />
            <img src={searchIcon} alt="searchIcon" />
          </div>
          {/* <CommonButton
            label="Filter"
            fontSize="14px"
            fontWeight="700"
            className="dash_common_btn white_bg_btn"
            icon={filterIcon}
            onClick={handleProjectManagementFilterClick}
          />
          {showProjectManagementFilter && (
            <DealRoomFilter
              handleFilterClose={() => setShowProjectManagementFilter(false)}
              // filterBy={filterStatus}
              // setFilterBy={setFilterStatus}
            />
          )} */}
        </div>
        <div
          style={{ maxHeight: "450px", overflowY: "auto", marginTop: "54px" }}
        >
          <table
            className="project_table dealroom_table"
            style={{ marginTop: 0 }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Last Updated</th>
                <th>Title</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={4}
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    <Loader type="2" />
                  </td>
                </tr>
              ) : projectManagementListFilter?.length > 0 ? (
                projectManagementListFilter.map((changeRequest, i) => {
                  return (
                    <tr
                      style={{ cursor: "pointer" }}
                      onClick={() => editProjectManagementList(changeRequest)}
                    >
                      <td
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "40px",
                        }}
                      >
                        <span style={{ margin: "0 56px" }}>
                          <CustomTooltip text="Delete">
                            <img
                              src={deleteIcon}
                              alt="delete plan"
                              width={15}
                              height={15}
                              style={{ marginLeft: 10, cursor: "pointer" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenDeleteModal(changeRequest?.id);
                              }}
                            />
                          </CustomTooltip>
                        </span>
                        <span style={{ marginTop: 3 }}>L-{i + 1}</span>
                      </td>
                      <td>
                        {format(
                          parseISO(changeRequest?.updated_at),
                          "MMMM dd, yyyy"
                        )}
                      </td>
                      <td className="dealroom_table_title">
                        {changeRequest?.planName}
                      </td>
                      <td>{changeRequest?.createBy || "N/A"}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4} style={{ textAlign: "center" }}>
                    No Project Plans
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        show={showProjectManagementModal}
        onHide={handleProjectManagementModalClose}
        className="dealroom_intital_modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={projectIcon} alt="projectIcon" />
          <h3>Project Management</h3>
          <p className="font_16">
            Track all your project management tasks in one place. Don’t worry,
            you can customize what timelines your clients see and how much
            buffer to add to your timelines.
          </p>
          <div
            className="dealroom_intital_modal_div"
            style={{ justifyContent: "center" }}
          >
            <CommonButton
              label="Create New Project Plan"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn"
              icon={false}
              width={"52%"}
              onClick={handleAddProjectPlanClick}
            />
          </div>
          <h6 className="font_14">
            <input
              type="checkbox"
              value={notShowAgain}
              onClick={handleNotShowAgain}
            />
            Don’t show this again
          </h6>
        </Modal.Body>
      </Modal>

      {openDelete && (
        <DeleteModal
          title={"Are you sure you want to delete project plan?"}
          isOpen={openDelete}
          deleteId={deleteId}
          onClose={handleCloseDeleteModal}
          onSure={deleteProjectPlan}
        />
      )}
    </>
  );
};

export default ProjectManagement;
