import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CommonButton from "../../../components/common/CommonButton";
import useToast from "../../../utils/customHooks/useToast";
import ChangeRequestPreview from "./ChangeRequestPreview";
import { capitalizeFirstLetter } from "../../../utils/helper";

const spanStyles = {
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#282828",
};

const AddChangeRequest = ({ handleCancel, staticDetails, userDetails, refreshChangeRqstList }) => {
  const [changeRequestPreview, setChangeRequestPreview] = useState(false);
  const [formData, setFormData] = useState({});
  const [documentTitle, setDocumentTitle] = useState("");
  const [category, setCategory] = useState({
    Scope: false,
    Cost: false,
    "Req. & Deliverables": false,
    "Testing/Quality": false,
    "Corrective Action": false,
    "Defect Repair": false,
    Schedule: false,
    Resources: false,
    Other: false,
  });
  const [otherText, setOtherText] = useState("");
  const [description, setDescription] = useState("");
  const [reason, setReason] = useState("");
  const [impact, setImpact] = useState("");
  const [impactTitle, setImpactTitle] = useState("");
  const [companyField, setCompanyField] = useState({
    signName: "",
    name: "",
  });
  const [clientField, setClientField] = useState({
    name: "",
    signName: "",
  });
  const showToast = useToast();

  const handlePreview = () => {
    if (!documentTitle) {
      showToast("Title is Required!", "error");
      return;
    }
    if (!Object.values(category).some((c) => c)) {
      showToast("Select At-least One Category!", "error");
      return;
    }
    if (!description) {
      showToast("Enter Change Request!", "error");
      return;
    }
    if (!reason) {
      showToast("Enter Change Reasons!", "error");
      return;
    }
    if (!impact) {
      showToast("Enter Impact Change!", "error");
      return;
    }
    if (!clientField.name) {
      showToast("Enter Company Owner Name!", "error");
      return;
    }
    if (!companyField.companyName) {
      showToast("Enter Company Name!", "error");
      return;
    }
    if (!clientField.signName) {
      showToast("Enter Client Name!", "error");
      return;
    }
    handleChange();
    setChangeRequestPreview(true);
  };
  const handleBack = () => {
    localStorage.removeItem('pdfUrl')
    setChangeRequestPreview(false);
    handleCancel()
  }

  const handleChange = (e, key) => {
    if (e && key) {
      let value;
      if (category.hasOwnProperty(key)) {
        value = e.target.checked;
      } else {
        value = e.target.value ? capitalizeFirstLetter(e.target.value) : '';
      }
      switch (key) {
        case "documentTitle":
          setDocumentTitle(value);
          break;
        case "Scope":
          setCategory({ ...category, Scope: value });
          break;
        case "Cost":
          setCategory({ ...category, Cost: value });
          break;
        case "Req. & Deliverables":
          setCategory({ ...category, "Req. & Deliverables": value });
          break;
        case "Testing/Quality":
          setCategory({ ...category, "Testing/Quality": value });
          break;
        case "Corrective Action":
          setCategory({ ...category, "Corrective Action": value });
          break;
        case "Defect Repair":
          setCategory({ ...category, "Defect Repair": value });
          break;
        case "Schedule":
          setCategory({ ...category, Schedule: value });
          break;
        case "Resources":
          setCategory({ ...category, Resources: value });
          break;
        case "Other":
          setCategory({ ...category, Other: value });
          if (!value) setOtherText("");
          break;
        case "otherText":
          setOtherText(value);
          break;
        case "description":
          setDescription(value);
          break;
        case "reason":
          setReason(value);
          break;
        case "impact":
          setImpact(value);
          break;
        case "impactTitle":
          setImpactTitle(value);
          break;
        case "companyName":
          setCompanyField({ ...companyField, companyName: value });
          break;
        case "companySignature":
          setCompanyField({ ...companyField, signName: value });
          break;
        case "clientName":
          setClientField({ ...clientField, name: value });
          break;
        case "clientSignature":
          setClientField({ ...clientField, signName: value });
          break;
        default:
          break;
      }
    } else {
      const data = {
        documentTitle,
        documentMessage: "",
        magicField: [],
        overview: {
          projectName: staticDetails?.projectName,
          projectStatus: staticDetails?.projectStatus,
          effectiveDate: staticDetails?.projectDueDate,
          requestor: staticDetails?.clients[0]?.clientName,
        },
        category: Object.entries(category).map(([name, value]) => ({
          name,
          value,
        })),
        otherText,
        description,
        reason,
        impact,
        impactTitle,
        companyField,
        clientField,
        projectId: staticDetails?.id,
        logo: userDetails?.businessDetail?.logo,
      };
      setFormData(data);
    }
  };


  useEffect(() => {

    const projectDetail = JSON.parse(localStorage.getItem("projectDetail")) || {};
    const authUserData = JSON.parse(localStorage.getItem("authUserData")) || {};

    setClientField({
      ...clientField,
      name: `${authUserData.firstName} ${authUserData.lastName}`,
      signName: `${projectDetail?.clients[0]?.clientName || ''}`
    })

    setCompanyField({
      ...companyField,
      companyName: authUserData?.businessDetail?.businessName || ''
    })

  }, [])

  if (changeRequestPreview) {
    return (
      <ChangeRequestPreview
        formData={formData}
        handleCancel={handleBack}
        refreshChangeRqstList={refreshChangeRqstList} />
    );
  }





  return (
    <>
      <div className="contract_main">
        <div className="contract_left" style={{ width: "100%" }}>
          <div
            className="contract_left_template"
            style={{ height: "650px", overflowY: "scroll" }}
          >
            <ul className="modal_main_ul" style={{ marginTop: "0" }}>
              <li className="modal_main_li">
                <label className="font_14">Title</label>
                <div className="_editable_">
                  <input
                    type="text"
                    placeholder="Title"
                    value={documentTitle}
                    onChange={(e) => handleChange(e, "documentTitle")}
                  />
                </div>
              </li>
            </ul>
            <ul className="modal_main_ul" style={{ marginTop: "0" }}>
              <li className="modal_main_li">
                <label className="font_14">Project</label>
                <div className="_editable_">
                  <input
                    type="text"
                    placeholder="Project"
                    value={staticDetails?.projectName}
                    disabled
                  />
                </div>
              </li>
              <li className="modal_main_li">
                <label className="font_14">Status</label>
                <div className="_editable_">
                  <input
                    type="text"
                    placeholder="Status"
                    value={staticDetails?.projectStatus?.name}
                    style={{ color: staticDetails?.projectStatus?.color }}
                    disabled
                  />
                </div>
              </li>
            </ul>
            <ul
              className="modal_main_ul"
              style={{ marginTop: "0", marginBottom: "22px" }}
            >
              <li className="modal_main_li">
                <label className="font_14">
                  Change Requestor ( Person/Company that requested the change )
                </label>
                <div className="_editable_">
                  <input
                    type="text"
                    placeholder="Change Requestor"
                    value={staticDetails?.clients[0]?.clientName}
                    disabled
                  />
                </div>
              </li>
              <li className="modal_main_li">
                <label className="font_14">Effective Date</label>
                <input
                  type="text"
                  placeholder="Date"
                  value={new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' }).format(new Date())}
                  disabled
                />
              </li>
            </ul>
            <h2 className="dashboard_heading">Change Category</h2>
            <div
              className="contract_left_template"
              style={{ marginTop: "22px" }}
            >
              <Row style={{ marginBottom: "22px" }}>
                <Col sm={3}>
                  <span style={spanStyles}>
                    <input
                      type="checkbox"
                      style={{ marginRight: "17px" }}
                      checked={category.Scope}
                      onChange={(e) => handleChange(e, "Scope")}
                    />
                    Scope
                  </span>
                </Col>
                <Col sm={3}>
                  <span style={spanStyles}>
                    <input
                      type="checkbox"
                      style={{ marginRight: "17px" }}
                      checked={category.Cost}
                      onChange={(e) => handleChange(e, "Cost")}
                    />
                    Cost
                  </span>
                </Col>
                <Col sm={6}>
                  <span style={spanStyles}>
                    <input
                      type="checkbox"
                      style={{ marginRight: "17px" }}
                      checked={category["Req. & Deliverables"]}
                      onChange={(e) => handleChange(e, "Req. & Deliverables")}
                    />
                    Requirements/Deliverables
                  </span>
                </Col>
              </Row>
              <Row style={{ marginBottom: "22px" }}>
                <Col sm={3}>
                  <span style={spanStyles}>
                    <input
                      type="checkbox"
                      style={{ marginRight: "17px" }}
                      checked={category["Testing/Quality"]}
                      onChange={(e) => handleChange(e, "Testing/Quality")}
                    />
                    Testing/Quality
                  </span>
                </Col>
                <Col sm={3}>
                  <span style={spanStyles}>
                    <input
                      type="checkbox"
                      style={{ marginRight: "17px" }}
                      checked={category["Corrective Action"]}
                      onChange={(e) => handleChange(e, "Corrective Action")}
                    />
                    Corrective Action
                  </span>
                </Col>
                <Col sm={6}>
                  <span style={spanStyles}>
                    <input
                      type="checkbox"
                      style={{ marginRight: "17px" }}
                      checked={category["Defect Repair"]}
                      onChange={(e) => handleChange(e, "Defect Repair")}
                    />
                    Defect Repair
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <span style={spanStyles}>
                    <input
                      type="checkbox"
                      style={{ marginRight: "17px" }}
                      checked={category.Schedule}
                      onChange={(e) => handleChange(e, "Schedule")}
                    />
                    Schedule
                  </span>
                </Col>
                <Col sm={3}>
                  <span style={spanStyles}>
                    <input
                      type="checkbox"
                      style={{ marginRight: "17px" }}
                      checked={category.Resources}
                      onChange={(e) => handleChange(e, "Resources")}
                    />
                    Resources
                  </span>
                </Col>
                <Col sm={6}>
                  <ul
                    className="modal_main_ul"
                    style={{ marginTop: "0", justifyContent: "flex-start" }}
                  >
                    <li style={{ marginRight: "10px" }}>
                      <div className="_editable_" style={{ gap: "0" }}>
                        <input
                          type="checkbox"
                          style={{ marginRight: "17px" }}
                          checked={category.Other}
                          onChange={(e) => handleChange(e, "Other")}
                        />
                        Other
                      </div>
                    </li>
                    <li className="modal_main_li">
                      <div className="_editable_">
                        <input
                          type="text"
                          placeholder="If other, describe"
                          value={otherText}
                          disabled={!category.Other}
                          onChange={(e) => handleChange(e, "otherText")}
                        />
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
            <ul className="modal_main_ul">
              <li className="modal_main_li" style={{ width: "100%" }}>
                <label className="font_14">
                  Describe the change being requested ?
                </label>
                <div className="_editable_">
                  <textarea
                    rows="10"
                    cols="500"
                    className="_text_area_"
                    placeholder="Write the details of the change request"
                    value={description}
                    maxLength={200}
                    style={{ height: "150px" }}
                    onChange={(e) => handleChange(e, "description")}
                  ></textarea>
                </div>
              </li>
            </ul>
            <ul className="modal_main_ul">
              <li className="modal_main_li" style={{ width: "100%" }}>
                <label className="font_14">
                  Describe the reason for the change ?
                </label>
                <div className="_editable_">
                  <textarea
                    rows="10"
                    cols="500"
                    className="_text_area_"
                    placeholder="Write the reason for the change"
                    value={reason}
                    maxLength={200}
                    style={{ height: "150px" }}
                    onChange={(e) => handleChange(e, "reason")}
                    // onKeyDown={handleKeyDown}
                  ></textarea>
                </div>
              </li>
            </ul>
            <ul className="modal_main_ul">
              <li className="modal_main_li" style={{ width: "100%" }}>
                <label className="font_14">
                  What will be the impact of change ?
                </label>
                <div className="_editable_">
                  <textarea
                    rows="10"
                    cols="500"
                    className="_text_area_"
                    placeholder={`Scope impact :\n\nCost impact :\n\nSchedule impact :`}
                    value={impact}
                    maxLength={200}
                    style={{ height: "150px" }}
                    onChange={(e) => handleChange(e, "impact")}
                    // onKeyDown={handleKeyDown}
                  />
                </div>
              </li>
            </ul>
            <ul className="modal_main_ul">
              <li className="modal_main_li" style={{ width: "100%" }}>
                <label className="font_14">
                  IN WITNESS WHEREOFF, the parties here to have executed this
                  change request as of the effective date.
                </label>
                <div className="_editable_" style={{ width: "30%" }}>
                  <input
                    disabled
                    type="text"
                    placeholder="Signature"
                    value={impactTitle}
                    // onChange={(e) => handleChange(e, "impactTitle")}
                  />
                </div>
              </li>
            </ul>
            <ul className="modal_main_ul">
              <li className="modal_main_li">
                <label className="font_14">
                  Name ( Person from the company that will sign the change
                  request )
                </label>
                <div className="_editable_">
                  <input
                    type="text"
                    placeholder="Company owner name"
                    value={clientField.name}
                    onChange={(e) => handleChange(e, "clientName")}
                  />
                </div>
              </li>
            </ul>
            <ul className="modal_main_ul">
              <li className="modal_main_li">
                <label className="font_14">Company Name</label>
                <div className="_editable_">
                  <input
                    type="text"
                    placeholder="Pre-populated with company name which can be edited"
                    value={companyField.companyName}
                    onChange={(e) => handleChange(e, "companyName")}
                  />
                </div>
              </li>
            </ul>
            <ul className="modal_main_ul" style={{ marginTop: "0" }}>
              <li className="modal_main_li" style={{ width: "30%" }}>
                <div className="_editable_">
                  <input
                    type="text"
                    disabled
                    placeholder="Signature"
                    value={companyField.signName}
                  />
                </div>
              </li>
            </ul>
            <ul className="modal_main_ul">
              <li className="modal_main_li">
                <label className="font_14">
                  Client Name ( Requester Name )
                </label>
                <div className="_editable_">
                  <input
                    type="text"
                    placeholder="Requester name"
                    value={clientField.signName}
                    onChange={(e) => handleChange(e, "clientSignature")}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="contract_main_button">
        <CommonButton
          label="Cancel"
          fontSize="14px"
          fontWeight="700"
          className="dash_common_btn white_bg_btn"
          icon={false}
          onClick={handleCancel}
        />
        <CommonButton
          label="Preview"
          fontSize="14px"
          fontWeight="700"
          className="dash_common_btn"
          icon={false}
          onClick={handlePreview}
        />
      </div>
    </>
  );
};

export default AddChangeRequest;
