import React, { useState } from "react";
import filtercircle from "./../assets/dashboard/filter_cross.svg";
import { Form } from 'react-bootstrap';

export default function Customize({ handleCustomizeClose, handleCustomizeSubmit, initialState }) {
  const [projectManagerChecked, setProjectManagerChecked] = useState(initialState.projectManagerChecked);
  const [statusChecked, setStatusChecked] = useState(initialState.statusChecked);
  const [clientChecked, setClientChecked] = useState(initialState.clientChecked);
  const [dueDateChecked, setDueDateChecked] = useState(initialState.dueDateChecked);
  const [phaseChecked, setPhaseChecked] = useState(initialState.phaseChecked);
  const [projectNameChecked, setProjectNameChecked] = useState(initialState.projectNameChecked);
  const [sendTemplateChecked, setSendTemplateChecked] = useState(initialState.sendTemplateChecked);

  const handleSubmit = () => {
    handleCustomizeSubmit({
      projectManagerChecked,
      statusChecked,
      clientChecked,
      dueDateChecked,
      phaseChecked,
      projectNameChecked,
      sendTemplateChecked,
    });
  };

  return (
    <div className="project_filter_main project_customize">
      <div className="project_filter_upper">
        <h4 className="filter_popup_heading">Customize</h4>
        <img src={filtercircle} onClick={handleCustomizeClose} />
      </div>
      <div className="project_filter_accor">
        <ul className="customize_pop_ul">
          <li className="customize_pop_li">
            <p className="font_14">Project Manager</p>
            <Form.Switch
              checked={projectManagerChecked}
              onChange={() => setProjectManagerChecked(!projectManagerChecked)}
            />
          </li>
          <li className="customize_pop_li">
            <p className="font_14">Status</p>
            <Form.Switch
              checked={statusChecked}
              onChange={() => setStatusChecked(!statusChecked)}
            />
          </li>
          <li className="customize_pop_li">
            <p className="font_14">Client</p>
            <Form.Switch
              checked={clientChecked}
              onChange={() => setClientChecked(!clientChecked)}
            />
          </li>
          <li className="customize_pop_li">
            <p className="font_14">Due Date</p>
            <Form.Switch
              checked={dueDateChecked}
              onChange={() => setDueDateChecked(!dueDateChecked)}
            />
          </li>
          <li className="customize_pop_li">
            <p className="font_14">Phase</p>
            <Form.Switch
              checked={phaseChecked}
              onChange={() => setPhaseChecked(!phaseChecked)}
            />
          </li>
          <li className="customize_pop_li">
            <p className="font_14">Project Name</p>
            <Form.Switch
              checked={projectNameChecked}
              onChange={() => setProjectNameChecked(!projectNameChecked)}
            />
          </li>
          <li className="customize_pop_li">
            <p className="font_14">Send Template</p>
            <Form.Switch
              checked={sendTemplateChecked}
              onChange={() => setSendTemplateChecked(!sendTemplateChecked)}
            />
          </li>
        </ul>
      </div>
      <div className="modal_btn_div project_filter_btn">
        <button className="_btn_transparent_ modal_div_button" onClick={handleCustomizeClose}>
          <span>Cancel</span>
        </button>
        <button className="_btn_normal_ modal_div_button" onClick={handleSubmit}>
          <span>Submit</span>
        </button>
      </div>
    </div>
  );
}
